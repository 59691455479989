import React from "react";
import { useSelector } from "react-redux";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";

import { BREAKPOINTS, COLORS } from "../../settings";
import { translator, langGetPostItems } from "../../helpers/lang";
import { useNewsComponent } from "../../hooks/use-news-component";
import { Translator } from "../Translator";
import { IconArrow } from "../Icons/IconArrow";

import icoBlockHeadingLinkArrow from "../../images/block_heading_link_arrow.svg";

export const NewsBlock = () => {
  const dataBase = useSelector(state => state.base);
  const langCurrent = dataBase.lang.current;
  const itemsAll = useNewsComponent();

  const items = langGetPostItems(itemsAll, "news", langCurrent);

  return (
    <StyledNewsBlock className="news block-container">
      <div className="block-title">
        <h2 className="h2">
          <Translator
            langCode={langCurrent}
            translations={{
              ru: "Новости",
              en: "News",
              kz: "Жаңалықтар",
            }}
          />
        </h2>
        <Link
          to={translator(langCurrent, {
            ru: "/media/",
            en: "/en/media-en/",
            kz: "/kz/media-kz/",
          })}
        >
          <Translator
            langCode={langCurrent}
            translations={{
              ru: "Новости",
              en: "News",
              kz: "Жаңалықтар",
            }}
          />
        </Link>
      </div>

      <div className="new_columns">
        {items && (
          <div className="news_big-card">
            <Img
              className="news_image"
              fluid={
                items[0]?.node?.featured_media?.localFile?.childImageSharp
                  ?.fluid
              }
            />
            <Link
              className="news_title"
              to={items[0].node.path.replace("/wp/", "/")}
              dangerouslySetInnerHTML={{ __html: items[0].node.title }}
            />
            <div className="news_info">
              <span className="news_date">{items[0].node.date}</span>
            </div>
          </div>
        )}

        {items && (
          <ul className="news_list">
            {items.map((item, i) => {
              return i !== 0 ? (
                <li key={item.node.id} className="news_list-item">
                  <Link
                    className="news_title"
                    to={item.node.path.replace("/wp/", "/")}
                    dangerouslySetInnerHTML={{ __html: item.node.title }}
                  />
                  <div className="news_info">
                    <span className="news_date">{item.node.date}</span>
                  </div>
                </li>
              ) : null;
            })}
          </ul>
        )}
      </div>

      <Link
        to={translator(langCurrent, {
          ru: "/media/",
          en: "/en/media-en/",
          kz: "/kz/media-kz/",
        })}
        className="news_link button button__secondary button__with-arrow"
      >
        <span>
          <Translator
            langCode={langCurrent}
            translations={{
              ru: "Новости",
              en: "News",
              kz: "Жаңалықтар",
            }}
          />
          <IconArrow size={20} />
        </span>
      </Link>
    </StyledNewsBlock>
  );
};

const StyledNewsBlock = styled.div`
  .new_columns {
    margin: 50px 0 0;

    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      display: flex;
    }
  }

  .block-title {
    display: flex;
    padding: 0 0 15px;
    border-bottom: 1px solid ${COLORS.lines};
    margin: 0 0 40px;

    justify-content: space-between;
    align-items: baseline;

    a {
      display: none;
      color: ${COLORS.text};
      font-size: 16px;
      line-height: 25px;

      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        display: inline-block;
      }

      &::after {
        content: "";
        vertical-align: middle;

        display: inline-block;
        background: url(${icoBlockHeadingLinkArrow}) center/contain no-repeat;
        width: 20px;
        height: 20px;
        margin: 0 0 0 5px;
      }
    }
  }

  .news_big-card {
    padding: 0 0 20px;
    border-bottom: 1px solid ${COLORS.lines};

    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      padding: 0;
      border-bottom: none;
      flex-basis: 50%;
    }
  }

  .news_list {
    list-style: none;

    padding: 0;
    margin: 20px 0 0;

    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      margin: 0 0 0 74px;
      flex-basis: 50%;
    }
  }

  .news_list-item {
    padding: 0 0 20px;
    border-bottom: 1px solid ${COLORS.lines};

    &:last-child {
      padding: 0;
      border-bottom: none;

      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        padding: 0 0 20px;
        border-bottom: 1px solid ${COLORS.lines};
      }
    }

    + .news_list-item {
      margin: 20px 0 0;
    }
  }

  .news_image {
    max-height: 290px;
    border-radius: 7px;
    margin: 0 0 30px;
  }

  .news_title {
    color: ${COLORS.text};
    font-size: 18px;
    line-height: 25px;

    transition: color 0.15s ease-out;

    @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
      font-size: 25px;
      line-height: 35px;
    }

    &:hover,
    &:focus {
      color: ${COLORS.text_accent};
      text-decoration: none;
    }
  }

  .news_info {
    margin: 10px 0 0;
    font-size: 16px;
    line-height: 25px;
  }

  .news_date {
    color: ${COLORS.text_secondary};
  }

  .news_link {
    margin: 30px 0 0;
    width: 100%;

    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      display: none;
    }
  }
`;

export const query = graphql`
  fragment NewsBlock on WordPressAcf_news_block {
    id
  }
`;
