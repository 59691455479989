import { useStaticQuery, graphql } from "gatsby";

export const useProjects = () => {
  const { ru, en, kz } = useStaticQuery(
    graphql`
      query Projects {
        ru: allWordpressPage(filter: { wordpress_parent: { eq: 2735 } }) {
          nodes {
            id
            wordpress_id
            slug
            path
            acf {
              project_name
              short_intro
              project_intro_extended
              is_show_link_invest
              project_websites {
                project_website {
                  url
                  title
                }
                country_flag {
                  localFile {
                    publicURL
                  }
                  alt_text
                }
              }
              project_advantages {
                data
                description
              }
              project_advantages_cards {
                data
                description
              }
              project_intro
              project_illustration {
                localFile {
                  childImageSharp {
                    fluid(
                      jpegProgressive: true
                      jpegQuality: 100
                      pngQuality: 100
                      quality: 100
                    ) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              project_illustration_mobile {
                localFile {
                  childImageSharp {
                    fluid(
                      jpegProgressive: true
                      jpegQuality: 100
                      pngQuality: 100
                      quality: 100
                    ) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }

        en: allWordpressPage(filter: { wordpress_parent: { eq: 2737 } }) {
          nodes {
            id
            wordpress_id
            slug
            path
            acf {
              project_name
              short_intro
              project_intro_extended
              is_show_link_invest
              project_websites {
                project_website {
                  url
                  title
                }
                country_flag {
                  localFile {
                    publicURL
                  }
                  alt_text
                }
              }
              project_advantages {
                data
                description
              }
              project_advantages_cards {
                data
                description
              }
              project_intro
              project_illustration {
                localFile {
                  childImageSharp {
                    fluid(
                      jpegProgressive: true
                      jpegQuality: 100
                      pngQuality: 100
                      quality: 100
                    ) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              project_illustration_mobile {
                localFile {
                  childImageSharp {
                    fluid(
                      jpegProgressive: true
                      jpegQuality: 100
                      pngQuality: 100
                      quality: 100
                    ) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }

        kz: allWordpressPage(filter: { wordpress_parent: { eq: 2739 } }) {
          nodes {
            id
            wordpress_id
            slug
            path
            acf {
              project_name
              short_intro
              project_intro_extended
              is_show_link_invest
              project_websites {
                project_website {
                  url
                  title
                }
                country_flag {
                  localFile {
                    publicURL
                  }
                  alt_text
                }
              }
              project_advantages {
                data
                description
              }
              project_advantages_cards {
                data
                description
              }
              project_intro
              project_illustration {
                localFile {
                  childImageSharp {
                    fluid(
                      jpegProgressive: true
                      jpegQuality: 100
                      pngQuality: 100
                      quality: 100
                    ) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              project_illustration_mobile {
                localFile {
                  childImageSharp {
                    fluid(
                      jpegProgressive: true
                      jpegQuality: 100
                      pngQuality: 100
                      quality: 100
                    ) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const projectsRuItems = ru.nodes;
  const projectsEnItems = en.nodes;
  const projectsKzItems = kz.nodes;

  return { projectsRuItems, projectsEnItems, projectsKzItems };
};
