import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import { ActivityItem } from "./ActivityItem";

export const ProgressActivityBlock = ({ data }) => {
  const progress_activity_list = data ? data.progress_activity_list : null;

  return (
    <>
      {progress_activity_list && (
        <StyledProgressActivityBlock className="block-container">
          {progress_activity_list.map((item, index) => (
            <ActivityItem key={index} item={item} />
          ))}
        </StyledProgressActivityBlock>
      )}
    </>
  );
};

const StyledProgressActivityBlock = styled.div``;

export const query = graphql`
  fragment ProgressActivityBlock on WordPressAcf_progress_activity_block {
    id
    progress_activity_list {
      pac_content
      pac_subtitle
      pac_title

      pac_image {
        localFile {
          publicURL
        }
      }

      pac_image_moby {
        localFile {
          publicURL
        }
      }

      is_blue_titles_list
      blue_titles_list {
        bt_subtitle
        bt_title
      }

      is_ecology_horizontal_list
      ecology_horizontal_list {
        eh_title
        eh_image {
          localFile {
            publicURL
          }
        }
      }

      is_charity_list
      charity_list {
        cl_image {
          localFile {
            publicURL
          }
        }
        cl_title
      }
    }
  }
`;
