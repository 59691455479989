import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { BREAKPOINTS } from "../../../settings";

export const SliderAdvantages = ({ children }) => {
  const settings = {
    arrows: false,
    focusOnSelect: true,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return <StyledSlider {...settings}>{children}</StyledSlider>;
};

const StyledSlider = styled(Slider)`
  flex-grow: 1;
  width: 90%;

  .slick-list,
  .slick-track {
    height: 100%;
  }

  .slick-list {
    overflow: visible;
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    height: auto;
    padding: 0 20px 0 0;

    @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
      padding: 0 35px 0 0;
    }

    > div {
      height: 100%;
    }
  }
`;
