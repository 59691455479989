import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { BREAKPOINTS, COLORS } from "../../../../settings";
import { updateBondsCalcCurrencyVal } from "../../../../store/actions/calc_bonds";
import { MegaSlider } from "../../../Slider";
import { prettify, prettifyLegend } from "../../../../helpers/base";

export const BondsCalculatorSlider = props => {
  const { name, label, value, min, max, step } = props;
  const dispatch = useDispatch();
  const dataBase = useSelector(state => state.base);
  const dataCalcBonds = useSelector(state => state.calcBonds);

  const langCurrent = dataBase.lang.current;
  const currencyName =
    dataCalcBonds.products[dataCalcBonds.currentProduct][
      dataCalcBonds.currentActiveBond
    ].sign;

  const handleChange = value => {
    if (window.ym) {
      window.ym(57564463, "reachGoal", "bond_calc_interact");
    }
    if (window.dataLayer) {
      window.dataLayer.push({ event: "bond_calc_interact" });
    }

    updateBondsCalcCurrencyVal(dispatch, value);
  };

  return (
    <StyledBondsCalculatorSlider>
      <div className="content">
        <div className="input">
          <span className="input_label">{label}</span>
          <span
            className="input_content"
            dangerouslySetInnerHTML={{
              __html: `${prettify(value)} ${currencyName}`,
            }}
          />
        </div>
        <MegaSlider
          min={min}
          max={max}
          step={step}
          value={value}
          handleChange={handleChange}
        />
      </div>
      <div className="legends">
        <span
          dangerouslySetInnerHTML={{
            __html: `${prettifyLegend(min, langCurrent)} ${currencyName}`,
          }}
        />
        <span
          dangerouslySetInnerHTML={{
            __html: `${prettifyLegend(max, langCurrent)} ${currencyName}`,
          }}
        />
      </div>
    </StyledBondsCalculatorSlider>
  );
};

const StyledBondsCalculatorSlider = styled.div`
  margin: 25px 0;
  @media (min-width: ${BREAKPOINTS.tablet}px) {
    margin: 0;
  }
  .content {
    .input {
      margin: 0 0 18px;
      &_label {
        display: inherit;
        color: #0f1a20;
        font-size: 20px;
        line-height: 25px;
        font-weight: 700;
        @media (min-width: ${BREAKPOINTS.tablet}px) {
          line-height: 30px;
        }
        @media (min-width: ${BREAKPOINTS.desktop}px) {
          font-size: 25px;
          line-height: 35px;
        }
      }
      &_content {
        display: inherit;
        margin: 5px 0 0;
        color: ${COLORS.text_accent};
        font-size: 30px;
        line-height: 40px;
        font-weight: 700;
        @media (min-width: ${BREAKPOINTS.tablet}px) {
          font-size: 40px;
          line-height: 55px;
        }
        @media (min-width: ${BREAKPOINTS.desktop}px) {
          font-size: 50px;
          line-height: 65px;
        }
      }
    }
  }
  .legends {
    margin: 14px 0 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    span {
      color: ${COLORS.text};
      font-size: 14px;
      line-height: 14px;
    }
  }
`;
