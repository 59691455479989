import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { BREAKPOINTS, COLORS } from "../../../settings";
import { prettify } from "../../../helpers/base";
import { translator } from "../../../helpers/lang";

const getDaysRemaining = bondDate => {
  const startDate = new Date();
  const endDate = new Date(bondDate);

  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);

  const milliseconds = 1000;
  const seconds = 60;
  const minutes = 60;
  const hours = 24;
  const oneDay = milliseconds * seconds * minutes * hours;

  const diffInTime = endDate.getTime() - startDate.getTime();
  const diffInDays = diffInTime / oneDay;

  return diffInDays;
};

const calculateFullIncome = dataCalcBonds => {
  const currentRate =
    dataCalcBonds.products[dataCalcBonds.currentProduct][
      dataCalcBonds.currentActiveBond
    ].rate;
  const currentVal = dataCalcBonds.currentVal;
  const currentName =
    dataCalcBonds.products[dataCalcBonds.currentProduct][
      dataCalcBonds.currentActiveBond
    ].sign;

  let fullIncome = 0;
  const daysPerYear = 365;
  const coefficient = 100;

  const daysRemaining = getDaysRemaining(dataCalcBonds.products[dataCalcBonds.currentProduct][dataCalcBonds.currentActiveBond].date);
  const dailyRate = currentRate / daysPerYear / coefficient;

  fullIncome = currentVal * dailyRate * daysRemaining;
  fullIncome = Math.round(fullIncome);
  fullIncome = fullIncome < 0 ? 0 : fullIncome;

  return `${prettify(fullIncome)} ${currentName}`;
};

const calculateRate = dataCalcBonds => {
  const currentRate =
    dataCalcBonds.products[dataCalcBonds.currentProduct][
      dataCalcBonds.currentActiveBond
    ].rate;

  return `${currentRate}%`;
};

const calculateCost = dataCalcBonds => {
  const currentCost =
    dataCalcBonds.products[dataCalcBonds.currentProduct][
      dataCalcBonds.currentActiveBond
    ].cost;
  const currentName =
    dataCalcBonds.products[dataCalcBonds.currentProduct][
      dataCalcBonds.currentActiveBond
    ].sign;

  return `${prettify(currentCost)} ${currentName}`;
};

const calculateCount = dataCalcBonds => {
  const currentCost =
    dataCalcBonds.products[dataCalcBonds.currentProduct][
      dataCalcBonds.currentActiveBond
    ].cost;
  const currentVal = dataCalcBonds.currentVal;

  return `${Math.floor(currentVal / currentCost)}`;
};

export const BondsCalculatorInfo = () => {
  const dataBase = useSelector(state => state.base);
  const dataCalcBonds = useSelector(state => state.calcBonds);

  const langCurrent = dataBase.lang.current;

  return (
    <StyledBondsCalculatorInfo className="calc-info">
      <div className="calc-info-items">
        <div className="calc-info-item">
          <div className="calc-info-item_value">
            {calculateFullIncome(dataCalcBonds)}
          </div>
          <div className="calc-info-item_description">
            {translator(langCurrent, {
              ru: "доходность за оставшийся период",
              en: "profitability for the remaining period",
            })}
          </div>
        </div>
        <div className="calc-info-item">
          <div className="calc-info-item_value">
            {calculateRate(dataCalcBonds)}
          </div>
          <div className="calc-info-item_description">
            {translator(langCurrent, {
              ru: "купон",
              en: "the coupon",
            })}
          </div>
        </div>
        <div className="calc-info-item">
          <div className="calc-info-item_value">
            {calculateCost(dataCalcBonds)}
          </div>
          <div className="calc-info-item_description">
            {translator(langCurrent, {
              ru: "стоимость",
              en: "cost",
            })}
          </div>
        </div>
        <div className="calc-info-item">
          <div className="calc-info-item_value">
            {calculateCount(dataCalcBonds)}
          </div>
          <div className="calc-info-item_description">
            {translator(langCurrent, {
              ru: "количество",
              en: "quantity",
            })}
          </div>
        </div>
      </div>
    </StyledBondsCalculatorInfo>
  );
};

const StyledBondsCalculatorInfo = styled.div`
  .calc-info-items {
    border-top: 1px solid ${COLORS.lines};
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, min(50%, 212px));
    margin: 30px 0 0;
    padding: 30px 0 0;

    @media (min-width: ${BREAKPOINTS.tablet}px) {
      border: none;
    }
  }

  .calc-info-item_value {
    color: #0f1a20;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    @media (min-width: ${BREAKPOINTS.desktop}px) {
      font-size: 25px;
      line-height: 35px;
    }
  }

  .calc-info-item_description {
    margin: 5px 0 0;
    color: ${COLORS.text_secondary};
    font-size: 16px;
    line-height: 25px;
  }

  .calc-info-checkbox {
    margin: 25px 0 0;

    label {
      position: relative;
      font-size: 16px;
      line-height: 25px;

      input {
        display: none;
      }

      .rate-rise_text {
        display: inline-block;
        padding: 0 0 0 44px;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          width: 24px;
          height: 24px;
          background: #ffffff;
          border: 2px solid ${COLORS.text_accent};
          border-radius: 4px;
          transform: translateY(-50%);
          transition: background 0.5s ease;
        }
      }

      &:hover,
      &:focus {
        .rate-rise_text::before {
          background: hsl(206, 100%, 75%);
        }
      }

      input:checked + .rate-rise_text::before {
        background: ${COLORS.text_accent};
      }
    }
  }
`;
