import React from "react";
import { useSelector } from "react-redux";
import Img from "gatsby-image/withIEPolyfill";
import styled from "styled-components";
import { Link } from "gatsby";
import { BREAKPOINTS, COLORS, LAYOUT } from "../../../settings";
import { Translator } from "../../Translator";
import { IconArrow } from "../../Icons/IconArrow";

import icoLinkArrow from "../../../images/link_arrow.svg";

export const CardProjects = ({ item }) => {
  const dataBase = useSelector(state => state.base);
  const langCurrent = dataBase.lang.current;

  return (
    <StyledProjectCard>
      <StyledProjectPanel className={item.slug}>
        <h3 className="project_title">{item.acf.project_name}</h3>
        {item.acf.project_websites && (
          <ul className="project_websites">
            {item.acf.project_websites.map((website, index) => (
              <li key={index}>
                <Link
                  to={website.project_website.url}
                  className="project_website"
                  target="_blank"
                  dangerouslySetInnerHTML={{
                    __html: website.project_website.title,
                  }}
                />
              </li>
            ))}
          </ul>
        )}
        {item.acf.project_illustration_mobile &&
          item.acf.project_illustration_mobile.localFile && (
            <Img
              className="project_image-mobile"
              fluid={
                item.acf.project_illustration_mobile.localFile.childImageSharp
                  .fluid
              }
            />
          )}
        <p
          className="project_intro"
          dangerouslySetInnerHTML={{ __html: item.acf.project_intro }}
        />
        {item.acf.project_advantages_cards && (
          <ul className="project_info">
            {item.acf.project_advantages_cards.map((item, i) => (
              <li key={`adv_${i}`} className="project_info-column">
                <span
                  className="project_info-data number"
                  dangerouslySetInnerHTML={{ __html: item.data }}
                />
                <span
                  className="project_info-description"
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
              </li>
            ))}
          </ul>
        )}

        <Link
          to={item.path.replace(/^\/wp/g, '')}
          className="project_link button button__secondary button__with-arrow"
        >
          <span>
            <Translator
              langCode={langCurrent}
              translations={{
                ru: "Подробнее",
                en: "More",
                kz: "Көбірек",
              }}
            />
            <IconArrow size={20} />
          </span>
        </Link>
      </StyledProjectPanel>
      <Img
        className="project_image"
        fluid={
          item.acf.project_illustration &&
          item.acf.project_illustration.localFile &&
          item.acf.project_illustration.localFile.childImageSharp.fluid
        }
        objectFit="contain"
        objectPosition="50% 50%"
        alt=""
      />
    </StyledProjectCard>
  );
};

const StyledProjectCard = styled.div`
  height: 100%;

  .project_image {
    display: none;

    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      position: absolute !important;
      top: -40px;
      right: 0;
      display: block;
      width: 320px;
    }

    @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
      top: -190px;
      right: -90px;
      width: 360px;
      height: 585px;
    }

    @media (min-width: ${BREAKPOINTS.desktop_lg + "px"}) {
      top: -170px;
      right: -70px;
      width: 460px;
    }
  }

  .project_image-mobile {
    max-width: 280px;
    width: 100%;
    height: 220px;
    margin: 20px 0 -20px;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      display: none;
    }

    &::before {
      content: "";
      z-index: 3;

      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(
        to top,
        #ffffff 10%,
        rgba(255, 255, 255, 0) 36%
      );
      height: 200px;
    }

    img {
      object-fit: contain !important;
      object-position: center bottom !important;
    }
  }
`;

const StyledProjectPanel = styled.div`
  display: flex;
  height: 100%;

  flex-direction: column;
  align-items: flex-start;

  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
    min-height: 390px;
    width: calc(100% - 340px);
    padding: 0 0 60px 0;
  }

  @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
    display: block;
    min-height: auto;
    width: calc(75% - 290px - ${LAYOUT.gap * 2 + "px"});
    padding: 0;
    margin: 0 0 0 calc(100% / 4 + ${LAYOUT.gap_desktop + "px"});
  }

  @media (min-width: ${BREAKPOINTS.desktop_lg + "px"}) {
    width: calc(75% - 390px - ${LAYOUT.gap * 2 + "px"});
  }

  .project_title {
    margin: 0;
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;

    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      font-size: 35px;
      line-height: 45px;
    }

    @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
      font-size: 50px;
      line-height: 65px;
    }
  }

  .project_websites {
    list-style: none;

    min-height: 40px;
    margin: 5px 0 0;
    padding: 0;
    font-size: 15px;
    line-height: 20px;

    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      min-height: auto;
      font-size: 16px;
      line-height: 25px;
    }

    .project_website {
      &::after {
        content: "";

        display: inline-block;
        background: url(${icoLinkArrow}) center/contain no-repeat;
        width: 10px;
        height: 10px;
        margin: 0 0 0 10px;
      }
    }
  }

  .project_intro {
    max-width: 100%;
    margin: 20px 0 auto;
    font-size: 16px;
    line-height: 25px;

    @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
      margin: 25px 0 auto;
    }
  }

  .project_info {
    list-style: none;

    display: flex;
    padding: 0;
    margin: 20px 0 0;

    @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
      margin: 25px 0 0;
    }

    .project_info-column {
      flex-basis: 30%;
      flex-grow: 1;
      flex-shrink: 1;

      + .project_info-column {
        flex-grow: 2;
        flex-shrink: 0;
        margin: 0 0 0 15px;

        @media (min-width: ${BREAKPOINTS.tablet}px) {
          margin: 0 0 0 50px;
        }
      }
    }

    .project_info-data {
      display: block;
      white-space: nowrap;
    }

    .project_info-description {
      display: block;
      margin: 5px 0 0;
      color: ${COLORS.text_secondary};
      font-size: 15px;
      line-height: 20px;

      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        font-size: 16px;
        line-height: 25px;
      }
    }
  }

  .project_link {
    margin: 30px 0 0;

    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      display: none;
    }
  }
`;
