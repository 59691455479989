import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { useNewsList } from "../../hooks/use-news-list";
import { translator } from "../../helpers/lang";

import { BREAKPOINTS, SETTINGS } from "../../settings";

export const ReadAlsoNewsBlock = ({ data }) => {
  const dataBase = useSelector(state => state.base);
  const langCurrent = dataBase.lang.current;

  const news_to_show = 4;
  const [newsToShow, setNewsToShow] = useState(news_to_show);

  const category_slug = translator(langCurrent, {
    ru: "news",
    en: "news_en",
    kz: "news-kz",
  });

  const news_list = useNewsList(langCurrent);
  const filtered_press_relize_list = news_list.filter(
    item => item.categories[0].slug === category_slug
  );

  function getSortedNews(filtered_press_relize_list, newsToShow = 4) {
    if (!filtered_press_relize_list) {
      return false;
    }

    let returned_list = [];

    returned_list["is_show_more"] =
      filtered_press_relize_list.length > newsToShow;

    returned_list["news_list"] = filtered_press_relize_list.slice(
      0,
      newsToShow
    );

    return returned_list;
  }
  const sorted_news = getSortedNews(filtered_press_relize_list, newsToShow);

  return (
    <>
      {data && (
        <StyledReadAlsoNewsBlock className="block-container">
          <div className="block_title">
            <h2
              dangerouslySetInnerHTML={{
                __html: data.ra_title,
              }}
            />
          </div>

          {sorted_news ? (
            <div className="news_list">
              {sorted_news.news_list &&
                sorted_news.news_list.map((news, index) => (
                  <div key={index} className="news_wrapper">
                    <div className="news_info">
                      <div
                        className="date"
                        dangerouslySetInnerHTML={{
                          __html: news.date,
                        }}
                      />
                    </div>
                    <div
                      className={`title_wrapper ${
                        (news.acf && news.acf.nsc_image) ||
                        (news.featured_media &&
                          news.featured_media.localFile.publicURL)
                          ? "has_image"
                          : ""
                      }`}
                    >
                      <Link
                        to={news.path.replace("/wp/", "/")}
                        className="title"
                        dangerouslySetInnerHTML={{ __html: news.title }}
                      />

                      {((news.acf &&
                        news.acf.nsc_image &&
                        news.acf.nsc_image.source_url.replace(
                          SETTINGS.URL_MASTER,
                          ""
                        )) ||
                        (news.featured_media &&
                          news.featured_media.localFile.publicURL)) && (
                        <div
                          className="image"
                          style={{
                            backgroundImage: `url(
                            ${
                              (news.acf &&
                                news.acf.nsc_image &&
                                news.acf.nsc_image.source_url.replace(
                                  SETTINGS.URL_MASTER,
                                  ""
                                )) ||
                              (news.featured_media &&
                                news.featured_media.localFile.publicURL)
                            }
                            )`,
                          }}
                        />
                      )}
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <div
              className="empty_wrapper"
              dangerouslySetInnerHTML={{
                __html: translator(langCurrent, {
                  ru: "В данной категории новостей нет.",
                  en: "There is no news in this category.",
                  kz: "Бұл категорияда жаңалық жоқ",
                }),
              }}
            />
          )}

          {sorted_news["is_show_more"] ? (
            <div
              className="button"
              dangerouslySetInnerHTML={{
                __html: translator(langCurrent, {
                  ru: "Загрузить еще",
                  en: "Load more",
                  kz: "Көбірек жүктеңіз",
                }),
              }}
              onClick={() => setNewsToShow(newsToShow + news_to_show)}
            />
          ) : null}
        </StyledReadAlsoNewsBlock>
      )}
    </>
  );
};

const StyledReadAlsoNewsBlock = styled.div`
  .block_title {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #b6bec0;
    padding-bottom: 15px;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 0;
    }
    h2 {
      font-weight: bold;
      font-size: 25px;
      line-height: 30px;
      color: #040404;
      margin-bottom: 0;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        font-size: 30px;
        line-height: 45px;
      }
    }
  }

  .news_list {
    width: 100%;
    height: auto;

    .news_wrapper {
      width: 100%;
      padding: 20px 0;
      border-bottom: 1px solid #898989;
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      flex-wrap: wrap;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        flex-direction: row;
      }

      .news_info {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          width: 25%;
          flex-direction: column;
        }
        .category {
          width: auto;
          height: auto;
          font-size: 15px;
          line-height: 20px;
          color: #040404;
          @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
            width: 100%;
            font-size: 20px;
            line-height: 35px;
          }
        }
        .date {
          font-size: 15px;
          line-height: 20px;
          color: #898989;
          @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
            font-size: 16px;
            line-height: 25px;
          }
        }
      }

      .title_wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          width: 70%;
        }
        &.has_image {
          flex-direction: column-reverse;
          @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
            flex-direction: row;
          }
          .title {
            width: 100%;
            @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
              width: calc(100% - 215px);
            }
          }
        }

        .title {
          font-size: 18px;
          line-height: 25px;
          color: #040404;
          width: 100%;
          margin-bottom: 5px;
          @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
            font-size: 25px;
            line-height: 35px;
            margin-bottom: 0;
          }
          &:hover {
            color: #0083e5;
            text-decoration: none;
          }
        }

        .image {
          width: 100%;
          height: 200px;
          border-radius: 7px;
          margin: 0 0 15px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
            width: 180px;
            height: 115px;
            margin: 0 0 0 auto;
          }
        }
      }
    }
  }

  .button {
    background-color: transparent;
    color: #0083e5;
    cursor: pointer;
    margin: 40px auto 0;
    display: block;
    width: fit-content;
    max-width: 300px;
    &:hover {
      background-color: #0083e5;
      color: #fff;
    }
  }
`;

export const query = graphql`
  fragment ReadAlsoNewsBlock on WordPressAcf_read_also_news_block {
    ra_title
  }
`;
