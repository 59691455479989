import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import { BREAKPOINTS } from "../../settings";

export const ContactsCitiesListBlock = ({ data }) => {
  return (
    <>
      {data && data.ccl_cities_list && (
        <StyledContactsCitiesListBlock className="block-container">
          <div className="border" />
          <div className="cities_contacts_list">
            {data.ccl_cities_list.map((city, index) => (
              <div key={index} className="city_info">
                <div
                  className="image_wrapper"
                  style={{
                    backgroundImage: `url(${
                      city.image && city.image.localFile.publicURL
                    })`,
                  }}
                />

                <div
                  className="city"
                  dangerouslySetInnerHTML={{
                    __html: city.title,
                  }}
                />

                <div
                  className="subtitle"
                  dangerouslySetInnerHTML={{
                    __html: city.subtitle,
                  }}
                />
              </div>
            ))}
          </div>
        </StyledContactsCitiesListBlock>
      )}
    </>
  );
};

const StyledContactsCitiesListBlock = styled.div`
  padding-top: 0;

  .border {
    width: 100%;
    height: 1px;
    background-color: #b6bec0;
    margin-bottom: 40px;
    margin-top: 15px;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      margin-bottom: 55px;
    }
  }

  .cities_contacts_list {
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      margin: 0 -10px;
    }
    .city_info {
      width: 100%;
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0;
      }
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        width: calc(100% / 3);
        padding: 0 10px;
      }
      .image_wrapper {
        width: 100%;
        height: 200px;
        border-radius: 7px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        margin-bottom: 10px;

        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          margin-bottom: 30px;
        }
      }
      .city {
        width: 100%;
        height: auto;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        color: #040404;
        margin-bottom: 5px;
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          font-size: 30px;
          line-height: 45px;
        }
      }
      .subtitle {
        font-size: 15px;
        line-height: 20px;
        color: #898989;
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          font-size: 16px;
          line-height: 25px;
        }
      }
    }
  }
`;

export const query = graphql`
  fragment ContactsCitiesListBlock on WordPressAcf_contacts_cities_list_block {
    ccl_cities_list {
      subtitle
      title
      image {
        localFile {
          publicURL
        }
      }
    }
  }
`;
