import React from "react";

import { ProductsExtendedItemInfo } from "../ProductsExtendedItemInfo";

export const ProductsExtendedRegionsContent = ({ data }) => {
  const { regions, activeTab } = data;
  const activeRegion = regions.find(item => item.region === activeTab);

  return <ProductsExtendedItemInfo info={activeRegion.info} />;
};
