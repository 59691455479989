import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import { BREAKPOINTS } from "../../settings";

const handleClick = (pageId, type) => {
  if ([1043, 26, 484].includes(pageId)) {
    if (["Фотобанк", "Photobank", "Фотобанк"].includes(type)) {
      if (window.ym) {
        window.ym(57564463, "reachGoal", "press_downloadfoto_click");
      }
      if (window.dataLayer) {
        window.dataLayer.push({ event: "press_downloadfoto_click" });
      }
    }

    if (
      ["Фирменный стиль", "Corporate identity", "Фирмалық стиль"].includes(type)
    ) {
      if (window.ym) {
        window.ym(57564463, "reachGoal", "press_downloadpdf_click");
      }
      if (window.dataLayer) {
        window.dataLayer.push({ event: "press_downloadpdf_click" });
      }
    }
  }
};

export const MediaFilesListBlock = ({ data, id }) => {
  const file_list = data.mfl_files_list ? data.mfl_files_list : null;

  function getFilename(path) {
    return path.replace(/^.*[\\\/]/, "");
  }

  const download_svg = `<svg xmlns="http://www.w3.org/2000/svg" 
style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
viewBox="0 0 29015 29121">
  <path class="fil0" d="M10040 13080l11576 11789 -12876 22c-278,2 -562,57 -823,163 -259,107 -498,261 -694,455 -196,193 -355,425 -468,685 -108,250 -172,531 -178,816 -5,280 43,547 135,790 103,273 259,514 450,710 188,192 429,352 709,461 244,99 512,150 780,149l17997 -33c134,-3 272,-15 399,-41 142,-27 270,-67 386,-115l24 -11c125,-53 246,-116 357,-188 123,-82 235,-172 326,-262l109 -115 115 -109c90,-91 180,-203 262,-326 72,-111 135,-232 188,-357l11 -24c48,-116 88,-244 115,-386 26,-127 38,-265 41,-399l33 -17997c1,-268 -50,-536 -149,-780 -109,-280 -269,-521 -461,-709 -196,-191 -437,-347 -710,-450 -243,-92 -510,-140 -790,-135 -285,6 -566,70 -816,178 -260,113 -492,272 -685,468 -194,196 -348,435 -455,694 -106,261 -161,545 -163,823l-22 12876 -1051 -1032c-42,-55 -89,-107 -139,-157l-20041 -20041c-654,-655 -1723,-655 -2378,0l-384 384 -278 278c-655,654 -655,1724 0,2378l9548 9548z"/>
</svg>
`;

  return (
    <>
      {file_list && (
        <StyledMediaFilesListBlock className="block-container custom_container">
          <div className="file_list">
            {file_list.map((file, index) => (
              <div
                key={index}
                className={`file_item ${file.mfl_font_color.value}`}
              >
                <div
                  className="bg_block"
                  style={{
                    backgroundImage: `url(${file.mfl_background.localFile.publicURL})`,
                  }}
                />

                <div
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: file.mfl_title,
                  }}
                />

                <div
                  className="desc"
                  dangerouslySetInnerHTML={{
                    __html: file.mfl_description,
                  }}
                />

                <a
                  className={`btn ${file.mfl_font_color.value}`}
                  href={file.mfl_file && file.mfl_file.localFile.publicURL}
                  target="_blank"
                  download={getFilename(file.mfl_file.localFile.publicURL)}
                  onClick={() => handleClick(id, file.mfl_title)}
                >
                  <span
                    className="btn_title"
                    dangerouslySetInnerHTML={{
                      __html: file.mfl_button_title,
                    }}
                  />
                  <span
                    className="svg"
                    dangerouslySetInnerHTML={{
                      __html: download_svg,
                    }}
                  />
                </a>
              </div>
            ))}
          </div>
        </StyledMediaFilesListBlock>
      )}
    </>
  );
};

const StyledMediaFilesListBlock = styled.div`
  width: 100%;
  height: auto;
  &.custom_container {
    padding-bottom: 40px;
  }
  .file_list {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    .file_item {
      width: 100%;
      height: auto;
      padding: 30px 20px 100px;
      border-radius: 7px;
      color: #fff;
      position: relative;
      margin-bottom: 20px;
      overflow: hidden;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        width: 48%;
        margin-bottom: 40px;
        padding: 40px 40px 140px;
      }
      &:hover {
        .bg_block {
          width: 120%;
          height: 120%;
        }
      }
      &.black {
        color: #000;
      }
      .bg_block {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        transition: all 0.6s ease-in-out;
      }
      .title {
        position: relative;
        font-weight: bold;
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 10px;
        z-index: 2;
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          font-size: 25px;
          line-height: 35px;
        }
      }
      .desc {
        position: relative;
        font-size: 15px;
        line-height: 20px;
        z-index: 2;
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          font-size: 20px;
          line-height: 30px;
        }
      }
      .btn {
        height: 45px;
        position: absolute;
        left: 20px;
        bottom: 30px;
        border-radius: 25px;
        border: 1px solid #fff;
        padding: 0 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: #fff;
        z-index: 2;
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          left: 40px;
          bottom: 40px;
        }
        &:hover {
          opacity: 0.7;
        }
        &.black {
          color: #000;
          border-color: #000;
          .svg {
            svg {
              fill: #000;
            }
          }
        }
        .btn_title {
          font-size: 15px;
          line-height: 20px;
          @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
            font-size: 16px;
            line-height: 25px;
          }
        }
        .svg {
          margin-left: 10px;
          svg {
            width: 11px;
            height: 11px;
            fill: #fff;
          }
        }
      }
    }
  }

  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
  }
`;

export const query = graphql`
  fragment MediaFilesListBlock on WordPressAcf_media_files_list_block {
    mfl_files_list {
      mfl_title
      mfl_description
      mfl_button_title
      mfl_file {
        localFile {
          publicURL
        }
      }
      mfl_background {
        localFile {
          publicURL
        }
        alt_text
      }
      mfl_font_color {
        value
      }
    }
  }
`;
