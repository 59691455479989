import React, { useState } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { BondsCirculationItem } from "./BondsCirculationItem";
import { BREAKPOINTS } from "../../settings";

export const BondsCirculationBlock = ({ data, id }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const openTab = e => setActiveIndex(+e.target.dataset.index);

  const active_list =
    data && data.bonds_circulation_outer_list[activeIndex]
      ? data.bonds_circulation_outer_list[activeIndex]
      : null;
  const active_inner_list =
    active_list && active_list.bonds_circulation_inner_list
      ? active_list.bonds_circulation_inner_list
      : null;

  return (
    <>
      {data && (
        <StyledBondsCirculationBlock className="block-container">
          <div className="title_line">
            <h2
              dangerouslySetInnerHTML={{
                __html: data.main_title,
              }}
            />
            {/*<div className="tab_line">
              {data.bonds_circulation_outer_list.map((item, index) => (
                <button
                  key={index}
                  className={`tab ${index === activeIndex ? "active" : ""}`}
                  onClick={openTab}
                  data-index={index}
                  dangerouslySetInnerHTML={{
                    __html: item.tab_title,
                  }}
                />
              ))}
            </div>*/}
          </div>

          {active_inner_list && (
            <div className="items_list">
              {active_inner_list.map((list, index) => (
                <BondsCirculationItem
                  key={"item_" + index}
                  list={list}
                  pageId={id}
                />
              ))}
            </div>
          )}
        </StyledBondsCirculationBlock>
      )}
    </>
  );
};

const StyledBondsCirculationBlock = styled.div`
  .title_line {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 16px;
    margin-bottom: 0;
    border-bottom: 1px solid #b6bec0;
    h2 {
      font-weight: bold;
      font-size: 25px;
      line-height: 30px;
      color: #0f1a20;
      margin-right: auto;
      margin-bottom: 15px;
      width: 100%;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        width: fit-content;
        margin-bottom: 0;
      }
      @media (min-width: ${BREAKPOINTS.desktop_lg + "px"}) {
        font-size: 30px;
        line-height: 45px;
      }
    }
    .tab_line {
      width: fit-content;
      height: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      background: #f6f8fa;
      border-radius: 7px;
      border: 2px solid transparent;
      margin: 0 auto 0 0;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        width: fit-content;
        margin: 0 0 0 auto;
      }
      button {
        height: 25px;
        background-color: transparent;
        outline: none;
        border: none;
        border-radius: 7px;
        font-size: 16px;
        line-height: 25px;
        color: #0f1a20;
        padding: 0 14px;
        margin: 0;
        &.active {
          background-color: #fff;
          box-shadow: 0 3px 8px rgba(0, 0, 0, 0.12),
            0 3px 1px rgba(0, 0, 0, 0.04);
        }
      }
    }
  }

  .items_list {
    width: 100%;
    height: auto;
  }
`;

export const query = graphql`
  fragment BondsCirculationBlock on WordPressAcf_BondsCirculationBlock {
    id
    main_title
    bonds_circulation_outer_list {
      tab_title
      bonds_circulation_inner_list {
        bc_title
        bc_subtitle

        main_line {
          left_side {
            ls_subtitle
            ls_title
          }
          right_side {
            ls_subtitle
            ls_title
          }
        }
        is_graph_add
        graph_list {
          gr_tick
          gr_value
        }
        g_symbal
      }
    }
  }
`;
