import React from "react";
import styled from "styled-components";

import { translator } from "../../../helpers/lang";

import { BREAKPOINTS } from "../../../settings";

export const Alert = ({ alertDone, langCurrent }) => {
  return (
    <StyledAlert className={`form_alert ${alertDone ? "" : "bad"}`}>
      {alertDone ? (
        <>
          <div
            className="title"
            dangerouslySetInnerHTML={{
              __html: translator(langCurrent, {
                ru: "Спасибо!",
                en: "Thanks!",
                kz: "Рақмет!",
              }),
            }}
          />
          <div
            className="subtitle"
            dangerouslySetInnerHTML={{
              __html: translator(langCurrent, {
                ru:
                  "Ваше сообщение отправлено. Мы свяжемся с Вами в ближайшее время.",
                en: "Your message has been sent. We will contact you soon.",
                kz:
                  "Сіздің хабарламаңыз жіберілді. Сізбен жақын арада хабарласады.",
              }),
            }}
          />
        </>
      ) : (
        <>
          <div
            className="title"
            dangerouslySetInnerHTML={{
              __html: translator(langCurrent, {
                ru: "Извините!",
                en: "Sorry!",
                kz: "Кешіріңіз!",
              }),
            }}
          />
          <div
            className="subtitle"
            dangerouslySetInnerHTML={{
              __html: translator(langCurrent, {
                ru: "Сервис временно недоступен.",
                en: "The service is temporarily unavailable.",
                kz: "Уақытша қол жетімді емес.",
              }),
            }}
          />
        </>
      )}
    </StyledAlert>
  );
};

const StyledAlert = styled.div`
  width: 100%;
  height: auto;
  min-height: 150px;
  text-align: center;
  border-radius: 7px;
  border: 1px solid #c4e0ff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  &.bad {
    color: red;
  }
  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
    width: 50%;
    min-height: 200px;
    padding: 40px;
  }
  .title {
    font-size: 28px;
    color: #0083e5;
    margin-bottom: 20px;
  }
  .subtitle {
    width: 100%;
    height: auto;
  }
`;
