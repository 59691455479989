// libraries
import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
// components
import { InfoTabsItem } from './InfoTabsItem';
// constants
import { BREAKPOINTS } from '../../settings';

export const InformTabsBlock = ({ data }) => {
    const { title, tab_list } = data;
    const [activeIndex, setActiveIndex] = useState(0);
    const activeContent = tab_list[activeIndex];

    return (
        <StyledInformTabsBlock>
            <StyledWrapperBlock>
                <StyledHeaderTitle>
                    <h2
                        dangerouslySetInnerHTML={{
                            __html: title,
                        }}
                    />
                </StyledHeaderTitle>

                <StyledSubheaderBlock>
                    <StyledTabList>
                        {tab_list.map((tab, index) => (
                            <InfoTabsItem
                                key={index}
                                index={index}
                                className={`tab ${activeIndex === index ? 'active' : ''} `}
                                ariaLabel={tab.tab_title}
                                title={tab.tab_title}
                                setActiveIndex={setActiveIndex}
                            />
                        ))}
                    </StyledTabList>
                </StyledSubheaderBlock>

                <StyledContentWrapper>
                    <StyledContentPart>
                        <div
                            className="title"
                            dangerouslySetInnerHTML={{
                                __html: activeContent.title,
                            }}
                        />
                        <div
                            className="content"
                            dangerouslySetInnerHTML={{
                                __html: activeContent.content,
                            }}
                        />
                    </StyledContentPart>
                    <StyledImageWrapper>
                        <img src={activeContent.image.localFile.publicURL} alt="" aria-hidden />
                    </StyledImageWrapper>
                </StyledContentWrapper>
            </StyledWrapperBlock>
        </StyledInformTabsBlock>
    );
};

const StyledWrapperBlock = styled.div`
    position: relative;
    z-index: 2;
    max-width: 1366px;
    margin: 0 auto;
`;

const StyledInformTabsBlock = styled.section`
    position: relative;
    width: 100%;
    background-color: #19324b;
    height: auto;
    overflow: hidden;
    padding: 28px 20px;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        padding: 60px 20px;
    }

    @media (min-width: ${BREAKPOINTS.desktop + 'px'}) {
        padding: 60px 70px;
    }
`;

const StyledContentWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    max-width: var(--content-width);
    height: auto;
    margin-top: 32px;
    padding: 0;
    color: #fff;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        align-items: flex-start;
        margin-top: 64px;
        min-height: 520px;
    }
`;

const StyledContentPart = styled.div`
    width: 100%;
    height: auto;
    margin-bottom: 40px;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        max-width: 48%;
    }

    .title {
        text-align: left;
        margin-bottom: 15px;
        font-size: 20px;
        line-height: 30px;

        @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
            font-weight: 600;
            font-size: 25px;
            line-height: 1;
        }
    }

    .content {
        font-size: 16px;
        line-height: 1.625;

        p {
            margin-bottom: 15px;
            font-weight: 400;
            font-size: 16px;
            line-height: 1.6;

            @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
                font-size: 20px;
                line-height: 1.75;
                text-align: justify;
            }
        }

        ul {
            width: 100%;
            height: auto;
            li {
                position: relative;
                margin-bottom: 24px;
                padding-left: 20px;

                &:last-child {
                    margin-bottom: 0;
                }

                &:before {
                    content: '';
                    width: 4px;
                    height: 4px;
                    background-color: #078be4;
                    border-radius: 50%;
                    position: absolute;
                    top: 8px;
                    left: 0px;
                }
            }
        }
    }
`;

const StyledImageWrapper = styled.div`
    margin: 0;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        margin-left: auto;
        max-width: 48%;
    }

    img {
        width: 100%;
        border-radius: 7px;
    }
`;

const StyledHeaderTitle = styled.div`
    h2 {
        margin: 0;
        font-weight: 600;
        font-size: 28px;
        line-height: 1.3;
        color: #ffffff;
        text-align: center;

        @media (min-width: ${BREAKPOINTS.desktop + 'px'}) {
            font-size: 46px;
        }
    }
`;

const StyledSubheaderBlock = styled.div`
    position: relative;
    overflow: hidden;
    padding-top: 32px;

    @media (min-width: ${BREAKPOINTS.mobile + 'px'}) {
        padding-top: 64px;
    }
`;

const StyledTabList = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: auto;
`;

export const query = graphql`
    fragment InformTabsBlock on WordPressAcf_inform_tabs_block {
        title
        tab_list {
            title
            tab_title
            content
            image {
                localFile {
                    publicURL
                }
            }
        }
    }
`;
