import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { useProjects } from "../../hooks/use-projects";
import { langGetProjectItems, translator } from "../../helpers/lang";

import { BREAKPOINTS } from "../../settings";

import link_arrow from "../../images/link_arrow.svg";
import Img from "gatsby-image/withIEPolyfill";
import { usePageInfo } from "../../hooks/use-page-info";

export const ProjectHeaderBlock = ({ id }) => {
  const [project, setProject] = useState(null);
  const dataBase = useSelector(state => state.base);
  const langCurrent = dataBase.lang.current;

  const itemsAll = useProjects();

  useEffect(() => {
    if (langCurrent) {
      const projects = langGetProjectItems(itemsAll, "projects", langCurrent);
      setProject(projects.filter(item => item.wordpress_id === id)[0]);
    }
  }, [langCurrent]);

  const pageInfo = usePageInfo(id);

  return project ? (
    <StyledProjectHeaderBlock>
      <div className="block-container">
        <div className="content_wrapper">
          <ul className="breadcrumbs">
            <li>
              <Link
                to={translator(langCurrent, {
                  ru: "/",
                  en: "/en",
                  kz: "/kz",
                })}
                dangerouslySetInnerHTML={{
                  __html: translator(langCurrent, {
                    ru: "Главная",
                    en: "Home",
                    kz: "Басты бет",
                  }),
                }}
              />
            </li>

            {pageInfo.parent_element && (
              <li>
                <Link
                  className="child"
                  to={pageInfo.parent_element.path.replace("/wp/", "/")}
                  dangerouslySetInnerHTML={{
                    __html: pageInfo.parent_element.title,
                  }}
                />
              </li>
            )}
          </ul>

          <h1
            dangerouslySetInnerHTML={{
              __html: project.acf && project.acf.project_name,
            }}
          />

          <div className="link_list">
            {project.acf &&
              project.acf.project_websites.map((site, index) => (
                <Link
                  key={index}
                  to={site.project_website && site.project_website.url}
                  className="link"
                  target="_blank"
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        site.project_website && site.project_website.title,
                    }}
                  />

                  <img src={link_arrow} alt="Link" />
                </Link>
              ))}
          </div>

          <div
            className="desc"
            dangerouslySetInnerHTML={{
              __html: project.acf && project.acf.project_intro_extended,
            }}
          />
        </div>

        <div className="image_wrapper">
          {project.acf &&
            project.acf.project_illustration &&
            project.acf.project_illustration.localFile && (
              <Img
                className="project_image-mobile"
                fluid={
                  project.acf.project_illustration.localFile.childImageSharp
                    .fluid
                }
              />
            )}
        </div>
      </div>
    </StyledProjectHeaderBlock>
  ) : null;
};

const StyledProjectHeaderBlock = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 158px;
  padding-bottom: 20px;
  @media (min-width: ${BREAKPOINTS.mobile + "px"}) {
    height: auto;
    min-height: 480px;
    margin-bottom: 90px;
    background-color: #f6f8fa;
    padding-bottom: 40px;
  }

  @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
    margin-bottom: 70px;
    min-height: 600px;
    padding-bottom: 60px;
  }

  .block-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 120px;
    padding-bottom: 250px;
    position: relative;
    background-color: #f6f8fa;

    @media (min-width: 400px) {
      padding-bottom: 350px;
    }
    @media (min-width: ${BREAKPOINTS.mobile + "px"}) {
      background-color: transparent;
      padding-bottom: 0;
    }
    @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
      padding-top: 180px;
    }

    .content_wrapper {
      width: 100%;
      @media (min-width: ${BREAKPOINTS.mobile + "px"}) {
        width: 50%;
      }
      @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
        width: 35%;
      }

      .breadcrumbs {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        list-style-type: none;
        padding: 0;
        margin: 0 0 10px;
        li {
          &:nth-child(n + 2) {
            &:before {
              content: "—";
              display: inline-block;
              margin: 0 10px;
            }
          }
          a {
            color: #898989;
          }
          span {
            color: #898989;
            display: inline-block;
          }
        }
      }

      h1 {
        font-weight: bold;
        font-size: 30px;
        line-height: 40px;
        color: #0f1a20;
        display: inline-block;
        margin-bottom: 5px;
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          font-size: 50px;
          line-height: 65px;
          margin-bottom: 10px;
        }
      }

      .link_list {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
        a {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-right: 20px;
          span {
            display: inline-block;
            margin-right: 10px;
          }
          img {
            width: 10px;
            height: auto;
          }
        }
      }

      .desc {
        font-size: 16px;
        line-height: 25px;
        color: #040404;
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          font-size: 20px;
          line-height: 30px;
        }
      }
    }

    .image_wrapper {
      width: 100%;
      max-width: 300px;
      position: absolute;
      bottom: -150px;
      left: calc(50% - 120px);
      margin: 0 auto;

      @media (min-width: 400px) {
        max-width: 400px;
        bottom: -150px;
        left: calc(50% - 150px);
      }

      @media (min-width: ${BREAKPOINTS.mobile + "px"}) {
        width: 50%;
        max-width: 100%;
        min-height: 480px;
        top: 80px;
        right: 0;
        bottom: auto;
        left: auto;
      }
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        width: 360px;
        top: 100px;
        right: 20px;
      }
      @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
        width: 500px;
        top: 80px;
        right: 100px;
      }
    }
  }
`;
