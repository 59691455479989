// libraries
import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
// constants
import { BREAKPOINTS, COLORS } from '../../settings';
import { BreadcrumbsCustom } from '../CrumbsWithTitleBlock/BreadcrumbsCustom';
// hooks
import { usePageInfo } from '../../hooks/use-page-info';

export const PromoBlock = ({ data, id }) => {
    const { title, subtitle, images } = data;
    const pageInfo = usePageInfo(id);

    return (
        <StyledPromoBlock>
            <StyledInfoBlock className="element-container">
                <BreadcrumbsCustom pageInfo={pageInfo} />
                <StyledPromoTitle dangerouslySetInnerHTML={{ __html: title }} />
                <StyledPromoSubtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
            </StyledInfoBlock>
            <StyledImageBlock>
                <StyledImageWrap>
                    <StyledImage src={images.firstImage.localFile.publicURL} alt="" aria-hidden />
                </StyledImageWrap>
                <StyledImageWrap>
                    <StyledImage src={images.secondImage.localFile.publicURL} alt="" aria-hidden />
                </StyledImageWrap>
            </StyledImageBlock>
        </StyledPromoBlock>
    );
};

const StyledPromoBlock = styled.section`
    position: relative;
    z-index: 2;
    padding: 135px 0 20px;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        padding: 190px 0 45px;
    }
`;
const StyledInfoBlock = styled.div`
    &.element-container {
        margin-bottom: 40px;
    }

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        &.element-container {
            margin-bottom: 90px;
        }
    }

    ul {
        display: none;

        @media (min-width: ${BREAKPOINTS.desktop + 'px'}) {
            display: block;
        }
    }
`;

const StyledPromoTitle = styled.h1`
    font-weight: 700;
    font-size: 38px;
    line-height: 1.3;
    margin-bottom: 20px;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        margin-bottom: 10px;
        max-width: 954px;
        font-size: 50px;
    }

    span {
        color: ${COLORS.text_accent};
    }
`;

const StyledPromoSubtitle = styled.h2`
    font-weight: 400;
    font-size: 15px;
    line-height: 1.75;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        max-width: 954px;
        font-size: 20px;
    }
`;

const StyledImageBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: var(--content-width);
    margin: 0 auto;
    padding: 0 20px;

    @media (min-width: ${BREAKPOINTS.desktop_lg + 'px'}) {
        justify-content: flex-end;
        margin-left: auto;
    }
`;

const StyledImageWrap = styled.div`
    position: relative;
    border-radius: 7px;
    overflow: hidden;

    &:nth-child(1) {
        display: none;

        @media (min-width: ${BREAKPOINTS.desktop_lg + 'px'}) {
            display: block;
            margin-right: -90px;

            &::after {
                position: absolute;
                top: -20%;
                left: -37%;
                width: 100%;
                height: 100%;
                background: rgba(255, 255, 255, 0.2);
                transform: rotate(-60deg);
                content: '';
            }
        }
    }

    &:nth-child(2) {
        &::after {
            position: absolute;
            top: -50%;
            left: -55%;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.2);
            transform: rotate(-60deg);
            content: '';
        }
    }
`;

const StyledImage = styled.img`
    max-width: 100%;
`;

export const query = graphql`
    fragment PromoBlock on WordPressAcf_promo_block {
        title
        subtitle
        images {
            secondImage {
                localFile {
                    publicURL
                }
            }
            firstImage {
                localFile {
                    publicURL
                }
            }
        }
    }
`;
