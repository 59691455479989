import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { updateModalState } from "../../store/actions/base";
import { IconArrow } from "../Icons/IconArrow";
import { translator } from "../../helpers/lang";

const sendEvents = type => {
  switch (type) {
    case "loans":
      if (window.ym) {
        window.ym(67027090, "reachGoal", "start_invest");
        window.ym(67027090, "reachGoal", "all_open_form");

        window.ym(57564463, "reachGoal", "loan_calc_leadform_сlick");
      }
      if (window.dataLayer) {
        window.dataLayer.push({ event: "loan_calc_leadform_сlick" });
      }
      break;
    case "bonds":
      if (window.ym) {
        window.ym(57564463, "reachGoal", "bond_calc_leadform_сlick");
      }
      if (window.dataLayer) {
        window.dataLayer.push({ event: "bond_calc_leadform_сlick" });
      }
      break;
    default:
      break;
  }
};

export const OpenForm = ({ type }) => {
  const dispatch = useDispatch();
  const dataBase = useSelector(state => state.base);
  const langCurrent = dataBase.lang.current;

  const buttonClick = () => {
    sendEvents(type);
    updateModalState(dispatch, { type: type });
  };

  return (
    <StyledOpenForm
      onClick={() => buttonClick()}
      className="button button__with-arrow"
    >
      <span>
        {translator(langCurrent, {
          ru: "Заполнить заявку",
          en: "Fill in the application",
          kz: "Өтінімді толтыру",
        })}
        <IconArrow size={20} />
      </span>
    </StyledOpenForm>
  );
};

export const StyledOpenForm = styled.button`
  flex-shrink: 0;
`;
