import React from "react";

import styled from "styled-components";

import { BREAKPOINTS } from "../../../../settings";

export const CharityList = ({ data }) => {
  return (
    <StyledCharityList>
      {data.map((item, index) => (
        <div key={index} className="item">
          <div className="image_wrapper">
            <img
              src={item.cl_image && item.cl_image.localFile.publicURL}
              alt={item.eh_title}
            />
          </div>

          <div
            className="title"
            dangerouslySetInnerHTML={{
              __html: item.cl_title,
            }}
          />
        </div>
      ))}
    </StyledCharityList>
  );
};

const StyledCharityList = styled.div`
  margin-top: 35px;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  @media (min-width: ${BREAKPOINTS.desktop_lg + "px"}) {
    margin-top: 50px;
    flex-wrap: wrap;
    overflow-x: hidden;
  }
  .item {
    width: 23%;
    height: auto;
    margin-right: 2%;
    min-width: 200px;
    @media (min-width: ${BREAKPOINTS.desktop_lg + "px"}) {
      min-width: auto;
      margin-bottom: 20px;
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
    .title {
      font-size: 15px;
      line-height: 20px;
      color: #040404;
      @media (min-width: ${BREAKPOINTS.desktop_lg + "px"}) {
        font-size: 18px;
        line-height: 27px;
      }
    }
    .image_wrapper {
      width: 100%;
      height: 125px;
      background-color: #f6f8fa;
      border-radius: 7px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 15px;
      img {
        width: auto;
        height: auto;
        max-width: 70%;
        max-height: 80%;
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        }
      }
      @media (min-width: ${BREAKPOINTS.desktop_lg + "px"}) {
        margin-bottom: 35px;
        height: 160px;
      }
    }
  }
`;
