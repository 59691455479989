import React from "react";
import styled from "styled-components";

import { BREAKPOINTS } from "../../../settings";

export const AdvantagesSimplePart = ({ items }) => {
  return (
    <StyledList>
      {items.map((item, i) => (
        <li key={`adv_${i}`}>
          <div
            className="title"
            dangerouslySetInnerHTML={{ __html: item.data }}
          />
          <div
            className="desc"
            dangerouslySetInnerHTML={{ __html: item.description }}
          />
        </li>
      ))}
    </StyledList>
  );
};

const StyledList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 0 auto;
  padding: 0;

  @media (min-width: ${BREAKPOINTS.mobile + "px"}) {
    flex-direction: row;
  }

  @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
    width: calc(75% - 17.5px);
  }

  li {
    padding: 0;
    min-width: 160px;
    margin-bottom: 20px;
    height: auto;
    list-style-type: none;

    @media (min-width: ${BREAKPOINTS.mobile + "px"}) {
      margin-bottom: 0;
      margin-right: 50px;
    }

    @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
      margin-right: 100px;
    }

    &:last-child {
      margin-bottom: 0;
      margin-right: 0;
    }
    .title {
      font-weight: bold;
      font-size: 30px;
      line-height: 40px;
      color: #0083e5;
      margin-bottom: 10px;
      white-space: nowrap;
      @media (min-width: ${BREAKPOINTS.desktop_lg + "px"}) {
        font-size: 40px;
        line-height: 40px;
      }
    }
    .desc {
      font-size: 15px;
      line-height: 20px;
      color: #0f1a20;
      opacity: 0.8;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        font-size: 16px;
        line-height: 25px;
      }
      @media (min-width: ${BREAKPOINTS.desktop_lg + "px"}) {
        font-size: 20px;
        line-height: 35px;
      }
    }
  }
`;
