import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { BREAKPOINTS, TRANSLATIONS } from "../../../settings";

export const ProductsExtendedRegionsTabs = ({ data }) => {
  const { regions, activeTab, setActiveTab } = data;
  const dataBase = useSelector(state => state.base);
  const langCurrent = dataBase.lang.current;
  const { countries } = TRANSLATIONS;

  return regions ? (
    <StyledButtonsGroup>
      {regions.map((item, i) => (
        <StyledButton
          key={`button_${i}`}
          onClick={() => setActiveTab(item.region)}
          className={item.region === activeTab ? "active" : null}
          dangerouslySetInnerHTML={{
            __html: countries[item.region][langCurrent],
          }}
        />
      ))}
    </StyledButtonsGroup>
  ) : null;
};

const StyledButtonsGroup = styled.div`
  width: fit-content;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f6f8fa;
  border-radius: 7px;
  border: 2px solid transparent;
  margin: 0 auto 0 0;

  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
    width: fit-content;
    margin: 0 0 0 auto;
  }
`;

const StyledButton = styled.button`
  height: 25px;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 7px;
  font-size: 16px;
  line-height: 25px;
  color: #0f1a20;
  padding: 0 14px;
  margin: 0;

  &.active {
    background-color: #fff;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.12), 0 3px 1px rgba(0, 0, 0, 0.04);
  }
`;
