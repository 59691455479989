import React from 'react';

import { MainPageHeadingBlock } from '../components/MainPageHeadingBlock';
import { InnerPageHeadingBlock } from '../components/InnerPageHeadingBlock';
import { AboutBlock } from '../components/AboutBlock';
import { AdvantagesBlock } from '../components/AdvantagesBlock';
import { AdvantagesSimpleBlock } from '../components/AdvantagesSimpleBlock';
import { ProjectsBlock } from '../components/ProjectsBlock';
import { ProductsBlock } from '../components/ProductsBlock';
import { ProductsExtendedBlock } from '../components/ProductsExtendedBlock';
import { InfoBlock } from '../components/InfoBlock';
import { NewsBlock } from '../components/NewsBlock';
import { CrumbsWithTitleBlock } from '../components/CrumbsWithTitleBlock';
import { OurProjectsBlock } from '../components/OurProjectsBlock';
import { HistoryCompanyExternalBlock } from '../components/HistoryCompanyExternalBlock';
// import { OurTeamBlock } from "../components/OurTeamBlock"; //временно
import { OurAwardsBlock } from '../components/OurAwardsBlock';
import { FooterLinksBlock } from '../components/FooterLinksBlock';
import { OurProjectsExternalBlock } from '../components/OurProjectsExternalBlock';
import { ProjectHeaderBlock } from '../components/ProjectHeaderBlock';
import { ProjectAboutBlock } from '../components/ProjectAboutBlock';
import { ProjectFinanceBlock } from '../components/ProjectFinanceBlock';
import { ProjectTeamBlock } from '../components/ProjectTeamBlock';
import { ProjectTechnologyBlock } from '../components/ProjectTechnologyBlock';
import { GraphBlock } from '../components/GraphBlock';
import { BondsCirculationBlock } from '../components/BondsCirculationBlock';
import { HowBuyBondsBlock } from '../components/HowBuyBondsBlock';

import { BoundsCalculatorBlock } from '../components/BoundsCalculatorBlock';
// import { FaqBlock } from '../components/FaqBlock';
// import { LoansCalculatorBlock } from "../components/LoansCalculatorBlock"; //временно
// import { CareerCallbackFormBlock } from "../components/CareerCallbackFormBlock"; //временно
import { MediaPromoSliderBlock } from '../components/MediaPromoSliderBlock';
import { MediaFilesListBlock } from '../components/MediaFilesListBlock';
import { MediaNewsListBlock } from '../components/MediaNewsListBlock';
import { SubscribeBlock } from '../components/SubscribeBlock';
import { ContactsCitiesListBlock } from '../components/ContactsCitiesListBlock';
import { ContactsCallbackFormBlock } from '../components/ContactsCallbackFormBlock';
import { TechnologyListBlock } from '../components/TechnologyListBlock';
// import { DevTeamBlock } from '../components/DevTeamBlock'; //временно скрыл
import { ReadAlsoNewsBlock } from '../components/ReadAlsoNewsBlock';
import { ProgressAdvantagesBlock } from '../components/ProgressAdvantagesBlock';
import { ProgressActivityBlock } from '../components/ProgressActivityBlock';
import { ContentBlock } from '../components/ContentBlock';
import { TechnologyDevelopBlock } from '../components/TechnologyDevelopBlock';
import { TechnologyOurTeamsBlock } from '../components/TechnologyOurTeamsBlock';
import { FilesBlock } from '../components/FilesBlock';
import { OpenVacanciesBlock } from '../components/OpenVacanciesBlock';
// import { HelpfulInformationBlock } from "../components/HelpfulInformationBlock";
// import { CareerVideoBlock } from "../components/CareerVideoBlock";
// vacancy page
import { PromoBlock } from '../components/PromoBlock';
import { ShortInfoBlock } from '../components/ShortInfoBlock';
import { InformTabsBlock } from '../components/InformTabsBlock';
import { OfficeBlock } from '../components/OfficeBlock';
import { EmployeeSayBlock } from '../components/EmployeeSayBlock';
import { EmployeeHistoryBlock } from '../components/EmployeeHistoryBlock';

export const renderBlock = (block, pageId) => {
    switch (block.__typename) {
        case 'WordPressAcf_main_page_heading_block':
            return <MainPageHeadingBlock data={block} id={pageId} />;
        case 'WordPressAcf_inner_page_heading_block':
            return <InnerPageHeadingBlock data={block} id={pageId} />;
        case 'WordPressAcf_about_block':
            return <AboutBlock data={block} id={pageId} />;
        case 'WordPressAcf_advantages_block':
            return <AdvantagesBlock data={block} id={pageId} />;
        case 'WordPressAcf_advantages_simple_block':
            return <AdvantagesSimpleBlock data={block} id={pageId} />;
        case 'WordPressAcf_projects_block':
            return <ProjectsBlock data={block} id={pageId} />;
        case 'WordPressAcf_products_block':
            return <ProductsBlock data={block} id={pageId} />;
        case 'WordPressAcf_products_extended_block':
            return <ProductsExtendedBlock data={block} id={pageId} />;
        case 'WordPressAcf_info_block':
            return <InfoBlock data={block} id={pageId} />;
        case 'WordPressAcf_news_block':
            return <NewsBlock data={block} id={pageId} />;
        case 'WordPressAcf_crumbs_with_title_block':
            return <CrumbsWithTitleBlock data={block} id={pageId} />;
        case 'WordPressAcf_our_projects_block':
            return <OurProjectsBlock data={block} id={pageId} />;
        case 'WordPressAcf_our_projects_external':
            return <OurProjectsExternalBlock data={block} id={pageId} />;
        case 'WordPressAcf_history_company_external':
            return <HistoryCompanyExternalBlock data={block} id={pageId} hasTitle={true} />;
        // case "WordPressAcf_our_team_block":
        //   return <OurTeamBlock data={block} id={pageId} />;
        case 'WordPressAcf_our_awards':
            return <OurAwardsBlock data={block} id={pageId} />;
        case 'WordPressAcf_our_awards_extarnal':
            return <OurAwardsBlock data={block} id={pageId} />;
        case 'WordPressAcf_footer_links_block':
            return <FooterLinksBlock data={block} id={pageId} />;
        case 'WordPressAcf_project_header_block':
            return <ProjectHeaderBlock data={block} id={pageId} />;
        case 'WordPressAcf_project_about_block':
            return <ProjectAboutBlock data={block} id={pageId} />;
        case 'WordPressAcf_finance_block':
            return <ProjectFinanceBlock data={block} id={pageId} />;
        case 'WordPressAcf_project_team_block':
            return <ProjectTeamBlock data={block} id={pageId} />;
        case 'WordPressAcf_project_technology_block':
            return <ProjectTechnologyBlock data={block} id={pageId} />;
        case 'WordPressAcf_graph_block':
            return <GraphBlock data={block} id={pageId} />;
        case 'WordPressAcf_BondsCirculationBlock':
            return <BondsCirculationBlock data={block} id={pageId} />;
        case 'WordPressAcf_how_to_buy_bonds_block':
            return <HowBuyBondsBlock data={block} id={pageId} />;
        case 'WordPressAcf_bounds_calculator_block':
            return <BoundsCalculatorBlock data={block} id={pageId} />;
        // case 'WordPressAcf_faq_block': //временно
        //     return <FaqBlock data={block} id={pageId} />;
        // case "WordPressAcf_loans_calculator_block": //временно
        //   return <LoansCalculatorBlock data={block} id={pageId} />;
        // case "WordPressAcf_vacansy_block": //временно создал для проверки
        // return <CareerCallbackFormBlock data={block} id={pageId} />;
        case 'WordPressAcf_open_vacancies_block':
            return <OpenVacanciesBlock data={block} id={pageId} />;
        case 'WordPressAcf_files_block':
            return <FilesBlock data={block} id={pageId} />;
        case 'WordPressAcf_media_promo_slider_block':
            return <MediaPromoSliderBlock data={block} id={pageId} />;
        case 'WordPressAcf_media_files_list_block':
            return <MediaFilesListBlock data={block} id={pageId} />;
        case 'WordPressAcf_media_news_list_block':
            return <MediaNewsListBlock data={block} id={pageId} />;
        case 'WordPressAcf_subscribe_block':
            return <SubscribeBlock data={block} id={pageId} />;
        case 'WordPressAcf_contacts_cities_list_block':
            return <ContactsCitiesListBlock data={block} id={pageId} />;
        case 'WordPressAcf_contacts_callback_form_block':
            return <ContactsCallbackFormBlock data={block} id={pageId} />;
        case 'WordPressAcf_technology_list_block':
            return <TechnologyListBlock data={block} id={pageId} />;
        // case 'WordPressAcf_dev_team_block':
        //     return <DevTeamBlock data={block} id={pageId} />;
        case 'WordPressAcf_read_also_news_block':
            return <ReadAlsoNewsBlock data={block} id={pageId} />;
        case 'WordPressAcf_progress_advantages_block':
            return <ProgressAdvantagesBlock data={block} id={pageId} />;
        case 'WordPressAcf_progress_activity_block':
            return <ProgressActivityBlock data={block} id={pageId} />;
        case 'WordPressAcf_history_company':
            return <HistoryCompanyExternalBlock data={block} id={pageId} hasTitle={false} />;
        case 'WordPressAcf_content_block':
            return <ContentBlock data={block} id={pageId} />;
        case 'WordPressAcf_technology_develop_block':
            return <TechnologyDevelopBlock data={block} id={pageId} />;
        case 'WordPressAcf_technology_our_teams_block':
            return <TechnologyOurTeamsBlock data={block} id={pageId} />;
        // case "WordPressAcf_helpful_information_block":
        //   return <HelpfulInformationBlock data={block} id={pageId} />;
        // case "WordPressAcf_career_video_block":
        //   return <CareerVideoBlock data={block} id={pageId} />;
        case 'WordPressAcf_promo_block':
            return <PromoBlock data={block} id={pageId} />;
        case 'WordPressAcf_short_info_block':
            return <ShortInfoBlock data={block} id={pageId} />;
        case 'WordPressAcf_inform_tabs_block':
            return <InformTabsBlock data={block} id={pageId} />;
        case 'WordPressAcf_office_block':
            return <OfficeBlock data={block} id={pageId} />;
        case 'WordPressAcf_employee_say_block':
            return <EmployeeSayBlock data={block} id={pageId} />;
        case 'WordPressAcf_employee_history_block':
            return <EmployeeHistoryBlock data={block} id={pageId} />;
        default:
            return null;
    }
};
