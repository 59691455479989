import React, { useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import styled from "styled-components";

import { BREAKPOINTS } from "../../settings";
import { graphql } from "gatsby";
import { useGraphValues } from "../../hooks/use-graph-values";

export const GraphBlock = ({ data }) => {
  const { graph_val_list } = data;
  const { graph_data } = useGraphValues();

  const [activeIndex, setActiveIndex] = useState(0);
  const openTab = e => setActiveIndex(+e.target.dataset.index);

  const active_data = graph_val_list[activeIndex];
  const active_graph_data = graph_data.find(
    ({ graph_id }) => graph_id === active_data.graph_id
  );
  const active_graph_values = active_graph_data
    ? active_graph_data.graph_values
    : undefined;
  const domain_min = active_graph_values
    ? Math.min.apply(
        Math,
        active_graph_values.map(function (o) {
          return o.graph_values_value;
        })
      )
    : active_graph_data
    ? active_graph_data.graph_min_value
    : undefined;
  const domain_max = active_graph_values
    ? Math.max.apply(
        Math,
        active_graph_values.map(function (o) {
          return o.graph_values_value;
        })
      )
    : active_graph_data
    ? active_graph_data.graph_max_value
    : undefined;
  const prefix = active_data ? active_data.graph_values_prefix : undefined;
  const postfix = active_data ? active_data.graph_values_postfix : undefined;

  const subnav_img = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 12">
      <path d="M.42 11.017A1.448 1.448 0 010 10.005a1.432 1.432 0 01.42-1.012L8.994.42A1.421 1.421 0 0110.005 0a1.431 1.431 0 011.012.42l8.573 8.573a1.432 1.432 0 01-1.011 2.443 1.43 1.43 0 01-1.012-.42l-7.562-7.564-7.561 7.565a1.431 1.431 0 01-2.024 0z"/>
    </svg>
  `;

  return graph_val_list && active_graph_data ? (
    <StyledGraphBlock className="block-container">
      <div className="graph_main_wrapper">
        <div className="control_block">
          {graph_val_list.map((item, index) => (
            <div
              key={"title_" + index}
              className={`control_item ${
                index === activeIndex ? "active" : ""
              }`}
            >
              <div className="title_line">
                <button
                  className="tab"
                  onClick={openTab}
                  data-index={index}
                  dangerouslySetInnerHTML={{ __html: item.main_title }}
                />

                <span dangerouslySetInnerHTML={{ __html: subnav_img }} />
              </div>

              {item.show_additional_data && item.additional_data && (
                <div className="options_list">
                  {item.additional_data.map((opt, index) => (
                    <div key={index} className="option">
                      <div
                        className="title"
                        dangerouslySetInnerHTML={{
                          __html: opt.additional_data_title,
                        }}
                      />

                      <div
                        className="desc"
                        dangerouslySetInnerHTML={{
                          __html: opt.additional_data_subtitle,
                        }}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="graph_wrapper">
          <ResponsiveContainer>
            <AreaChart data={active_graph_values}>
              <CartesianGrid stroke="#DADDE0" vertical={false} />

              <XAxis dataKey="graph_values_year" tickMargin={30} interval={0} />

              <YAxis
                type="number"
                domain={[dataMin => domain_min, dataMax => domain_max]}
                hide={true}
                dataKey="graph_values_value"
              />

              <Tooltip
                separator=""
                wrapperStyle={{ width: "auto", height: "35px", padding: "0" }}
                labelStyle={{ display: "none", padding: "0" }}
                contentStyle={{
                  backgroundColor: "#1B252A",
                  border: "none",
                  padding: "0 10px",
                }}
                itemStyle={{ color: "#fff", fontSize: "16px" }}
                formatter={value => [value + ` ${postfix}`, prefix]}
                isAnimationActive={false}
              />

              <Area
                type="monotone"
                dataKey="graph_values_value"
                activeDot={{
                  stroke: "#0083E5",
                  strokeWidth: 4,
                  fill: "#fff",
                  r: 6,
                }}
                stroke="#0083E5"
                strokeWidth="4"
                fill="rgba(0, 131, 229, .3)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </StyledGraphBlock>
  ) : null;
};

const StyledGraphBlock = styled.div`
  &.block-container {
    padding-left: 0;
    padding-right: 0;
  }

  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
    &.block-container {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
    &.block-container {
      padding-left: 70px;
      padding-right: 70px;
    }
  }
  .graph_main_wrapper {
    width: 100%;
    height: auto;
    min-height: 480px;
    background: #f6f8fa;
    border-radius: 0;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      border-radius: 7px;
    }
    .control_block {
      width: 100%;
      height: auto;
      padding: 20px;
      background-color: #2d3b4d;
      color: #fff;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        width: 40%;
      }
      @media (min-width: ${BREAKPOINTS.desktop_lg + "px"}) {
        width: 35%;
        padding: 40px;
      }
      .control_item {
        width: 100%;
        height: auto;
        &.active {
          .title_line {
            svg {
              display: block;
              transform: rotate(90deg);
            }
          }
          .options_list {
            display: block;
          }
        }
        .title_line {
          width: 100%;
          height: auto;
          position: relative;
          border-bottom: 1px solid rgba(255, 255, 255, 0.3);
          &:hover {
            button,
            svg {
              opacity: 0.8;
            }
          }
          button {
            width: 100%;
            border: none;
            background-color: transparent;
            padding: 10px 20px 10px 0;
            margin: 0;
            font-weight: bold;
            font-size: 25px;
            line-height: 30px;
            color: #fff;
            outline: none;
            text-align: left;
            position: relative;
            z-index: 2;
            @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
              font-size: 20px;
              line-height: 29px;
            }
            @media (min-width: ${BREAKPOINTS.desktop_lg + "px"}) {
              padding: 15px 20px 15px 0;
              font-size: 25px;
              line-height: 35px;
            }
          }
          svg {
            position: absolute;
            display: none;
            width: 14px;
            height: auto;
            right: 0;
            top: 25px;
            fill: #fff;
            z-index: 1;
          }
        }
        .options_list {
          width: 100%;
          height: auto;
          display: none;
          &:last-child {
            padding: 10px 0 30px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
          }

          .option {
            width: 100%;
            height: auto;
            margin-bottom: 15px;
            &:last-child {
              margin-bottom: 0;
            }
            .title {
              font-weight: bold;
              font-size: 16px;
              line-height: 25px;

              @media (min-width: ${BREAKPOINTS.desktop_lg + "px"}) {
                font-size: 19px;
                line-height: 25px;
              }
            }
            .desc {
              font-size: 15px;
              line-height: 20px;
              opacity: 0.7;
              @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
                font-size: 16px;
                line-height: 25px;
              }
            }
          }
        }
      }
    }
    .graph_wrapper {
      width: 100%;
      height: auto;
      padding: 30px 30px 40px;
      overflow-x: auto;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        width: 60%;
        overflow: hidden;
      }
      @media (min-width: ${BREAKPOINTS.desktop_lg + "px"}) {
        width: 65%;
      }
      .recharts-responsive-container {
        width: 100%;
        min-width: 500px;
        height: 330px !important;
        position: relative;
        &:after {
          content: "";
          display: block;
          position: absolute;
          right: -2rem;
          width: 2rem;
          height: 1px;
        }
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          height: 500px !important;
          border-right: 0;
          min-width: 0;
        }
        .recharts-wrapper {
          .recharts-surface {
            overflow: visible;
            .recharts-xAxis {
              line {
                display: none;
              }
              .recharts-text {
                font-size: 16px;
                color: #898989;
                position: relative;
              }
            }
          }
        }
      }
    }
  }
`;

export const query = graphql`
  fragment GraphBlock on WordPressAcf_graph_block {
    id
    graph_val_list {
      main_title
      show_additional_data
      additional_data {
        additional_data_title
        additional_data_subtitle
      }
      graph_id
      graph_values_prefix
      graph_values_postfix
    }
  }
`;
