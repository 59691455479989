import React from "react";
import { useSelector } from "react-redux";
import { graphql, Link } from "gatsby";
import styled from "styled-components";

import { BREAKPOINTS, COLORS } from "../../settings";
import { translator, langGetProjectItems } from "../../helpers/lang";
import { useProjects } from "../../hooks/use-projects";
import { SliderProjects } from "./SliderProjects";
import { Translator } from "../Translator";
import { CardProjects } from "./CardProjects";

import icoBlockHeadingLinkArrow from "../../images/block_heading_link_arrow.svg";

export const ProjectsBlock = () => {
  const dataBase = useSelector(state => state.base);
  const langCurrent = dataBase.lang.current;
  const itemsAll = useProjects();

  const items = langGetProjectItems(itemsAll, "projects", langCurrent);

  return (
    <StyledProjectsBlock className="block-container">
      <div className="block-title block-title__styled">
        <h2 className="block-title_title h2">
          <Translator
            langCode={langCurrent}
            translations={{
              ru: "Наши проекты",
              en: "Our projects",
              kz: "Біздің жобаларымыз",
            }}
          />
        </h2>
        <Link
          to={translator(langCurrent, {
            ru: "/about/#products",
            en: "/en/about-en/#products",
            kz: "/kz/about-kz/#products",
          })}
          className="block-title_link"
        >
          <Translator
            langCode={langCurrent}
            translations={{ ru: "Проекты", en: "Projects", kz: "Жобаларымыз" }}
          />
        </Link>
      </div>

      {items && (
        <SliderProjects items={items}>
          {items.map((item, index) => (
            <CardProjects key={index} item={item} />
          ))}
        </SliderProjects>
      )}
    </StyledProjectsBlock>
  );
};

const StyledProjectsBlock = styled.div`
  overflow: hidden;

  .block-title {
    display: none;
    padding: 0 0 15px;
    border-bottom: 1px solid ${COLORS.lines};

    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      display: flex;
      align-items: baseline;
    }

    .block-title_title {
      margin: 0 auto 0 0;
    }

    .block-title_link {
      color: ${COLORS.text};
      font-size: 16px;
      line-height: 25px;

      @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
        display: none;
      }

      &::after {
        content: "";
        vertical-align: -5px;

        display: inline-block;
        background: url(${icoBlockHeadingLinkArrow}) center/contain no-repeat;
        width: 20px;
        height: 20px;
        margin: 0 0 0 5px;
      }
    }

    &.block-title__styled {
      position: relative;

      &::before {
        content: "";

        position: absolute;
        bottom: -1px;
        left: 0;
        background: ${COLORS.text_accent};
        width: 40%;
        height: 1px;
      }
    }
  }
`;

export const query = graphql`
  fragment ProjectsBlock on WordPressAcf_projects_block {
    id
  }
`;
