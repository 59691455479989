import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import { BREAKPOINTS } from "../../settings";

export const ProgressAdvantagesBlock = ({ data }) => {
  const progress_advantages_list = data ? data.progress_advantages_list : null;

  return (
    <>
      {progress_advantages_list && (
        <StyledProgressAdvantagesBlock className="block-container">
          <div className="advantages_wrapper">
            {progress_advantages_list.map((item, index) => (
              <div key={index} className="item">
                <div
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: item.pa_title,
                  }}
                />

                <img
                  src={item.pa_img && item.pa_img.localFile.publicURL}
                  alt={item.pa_title}
                />
              </div>
            ))}
          </div>
        </StyledProgressAdvantagesBlock>
      )}
    </>
  );
};

const StyledProgressAdvantagesBlock = styled.div`
  padding-top: 20px;
  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
    padding-top: 10px;
  }
  .advantages_wrapper {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      width: calc(100% + 2%);
    }
    @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
      width: calc(100% + 2.33%);
    }
    .item {
      width: 100%;
      padding: 20px 100px 20px 20px;
      border-radius: 7px;
      background-color: #f6f8fa;
      margin-bottom: 35px;
      position: relative;
      min-height: 100px;
      display: flex;
      align-items: center;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        width: 48%;
        margin-right: 2%;
        padding: 30px 20px 110px;
        min-height: 180px;
        height: auto;
        display: block;
      }
      @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
        width: 31%;
        margin-right: 2.33%;
      }

      .title {
        font-weight: bold;
        font-size: 16px;
        line-height: 21px;
        color: #040404;

        @media (min-width: ${BREAKPOINTS.mobile_xs + "px"}) {
          font-size: 20px;
          line-height: 30px;
        }

        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          line-height: 25px;
        }
      }
      img {
        position: absolute;
        right: 20px;
        top: calc(50% - 25px);
        display: block;
        width: auto;
        height: auto;
        max-width: 60px;
        max-height: 60px;
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          right: auto;
          left: 20px;
          top: auto;
          bottom: 30px;
          max-width: 70px;
          max-height: 70px;
        }
      }
    }
  }
`;

export const query = graphql`
  fragment ProgressAdvantagesBlock on WordPressAcf_progress_advantages_block {
    progress_advantages_list {
      pa_title
      pa_img {
        localFile {
          publicURL
        }
      }
    }
  }
`;
