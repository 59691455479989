import React from "react";

import styled from "styled-components";

import { BREAKPOINTS } from "../../../../settings";

export const BlueTitlesList = ({ data }) => {
  return (
    <StyledBlueTitlesList>
      {data.map((item, index) => (
        <div key={index} className="item">
          <div
            className="title"
            dangerouslySetInnerHTML={{
              __html: item.bt_title,
            }}
          />

          <div
            className="subtitle"
            dangerouslySetInnerHTML={{
              __html: item.bt_subtitle,
            }}
          />
        </div>
      ))}
    </StyledBlueTitlesList>
  );
};

const StyledBlueTitlesList = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  overflow-x: auto;
  .item {
    width: 32%;
    min-width: 220px;
    height: auto;
    margin-right: 1.33%;
    &:last-child {
      margin-right: 0;
    }
    .title {
      font-weight: bold;
      font-size: 30px;
      line-height: 40px;
      color: #0083e5;
      margin-bottom: 5px;
      @media (min-width: ${BREAKPOINTS.desktop_lg + "px"}) {
        font-size: 40px;
        margin-bottom: 15px;
      }
    }
    .subtitle {
      font-size: 15px;
      line-height: 20px;
      color: #0f1a20;
      opacity: 0.8;
      @media (min-width: ${BREAKPOINTS.desktop_lg + "px"}) {
        font-size: 20px;
        line-height: 35px;
      }
    }
  }
`;
