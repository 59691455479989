import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import { TitleBlock } from "./TitleBlock";
import { TopButton } from "./TopButton";
import { BreadcrumbsCustom } from "./BreadcrumbsCustom";
import Img from "gatsby-image/withIEPolyfill";

import { BREAKPOINTS, COLORS } from "../../settings";

import { usePageInfo } from "../../hooks/use-page-info";

export const InnerPageHeadingBlock = ({ data, id }) => {
  const pageInfo = usePageInfo(id);

  return (
    <StyledInnerPageHeadingBlock className="block-container custom_container">
      <Img
        fluid={data.h1_image.localFile.childImageSharp.fluid}
        objectFit="cover"
        objectPosition="25% 50%"
        alt=""
      />
      <div className="element-container">
        <div className="head-bread-crumbs">
          <BreadcrumbsCustom pageInfo={pageInfo} />
        </div>
        <TitleBlock data={data} />
        {data.is_show_button && (
          <TopButton theme={"light"} data={data} pageId={id} />
        )}
      </div>
    </StyledInnerPageHeadingBlock>
  );
};

const StyledInnerPageHeadingBlock = styled.div`
  position: relative;
  display: flex;
  min-height: 650px;
  height: 1px; // NOTE: hack for ie
  background: ${COLORS.bg_dark};
  padding: 105px 0 60px;
  margin: 0 0 30px;

  flex-direction: column;
  justify-content: flex-end;
  align-items: center; // NOTE: hack for ie

  @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
    margin: 0 0 60px;
    max-width: 1366px;
    margin-left: auto;
    margin-right: auto;
  }

  &::before {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #004b8473;
    position: absolute;
    content: "";
    z-index: 1;
  }

  .element-container {
    position: relative;
    z-index: 3;
    width: 100%; // NOTE: hack for ie
  }

  .head-bread-crumbs {
    li::before {
      color: ${COLORS.text_inverted};
    }

    a {
      color: ${COLORS.text_inverted};
    }
  }

  .gatsby-image-wrapper {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  h1 {
    color: ${COLORS.text_inverted};
    z-index: 2;
    position: relative;

    & + span {
      color: ${COLORS.text_inverted};
      opacity: 1;
      z-index: 2;
      position: relative;
    }
  }
`;

export const query = graphql`
  fragment InnerPageHeadingBlock on WordPressAcf_inner_page_heading_block {
    h1_title
    h1_subtitle
    is_show_button
    button_link
    button_title
    h1_image {
      localFile {
        publicURL
        childImageSharp {
          fluid(
            maxWidth: 1920
            srcSetBreakpoints: [1920]
            jpegProgressive: true
            jpegQuality: 100
            pngQuality: 100
            quality: 100
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;
