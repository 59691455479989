import React from "react";
import { Link } from "gatsby";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { useProjects } from "../../hooks/use-projects";
import { langGetProjectItems, translator } from "../../helpers/lang";

import Img from "gatsby-image/withIEPolyfill";
import { BREAKPOINTS } from "../../settings";
import link_arrow from "../../images/link_arrow.svg";

export const OurProjectsExternalBlock = () => {
  const dataBase = useSelector(state => state.base);
  const langCurrent = dataBase.lang.current;
  const itemsAll = useProjects();

  const projects = langGetProjectItems(itemsAll, "projects", langCurrent);

  return (
    <StyledOurProjectsExternalBlock className="block-container">
      {projects &&
        projects.map((project, index) => (
          <div className="project" key={index}>
            <div className="content_wrapper">
              <h2
                dangerouslySetInnerHTML={{
                  __html: project.acf.project_name,
                }}
              />

              <div className="link_list">
                {project.acf.project_websites &&
                  project.acf.project_websites.map((site, i) => (
                    <div key={index + "_" + i} className="link_wrapper">
                      <img
                        src={site.country_flag.localFile.publicURL}
                        alt={
                          site.country_flag.alt_text
                            ? site.country_flag.alt_text
                            : "Flag"
                        }
                        className="country_flag"
                      />

                      <Link to={site.project_website.url} target="_blank">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: site.project_website.title,
                          }}
                        />

                        <img src={link_arrow} alt="Link arrow" />
                      </Link>
                    </div>
                  ))}
              </div>

              <Img
                className="project_illustration_mobile"
                fluid={
                  project.acf.project_illustration_mobile &&
                  project.acf.project_illustration_mobile.localFile &&
                  project.acf.project_illustration_mobile.localFile
                    .childImageSharp.fluid
                }
                objectFit="contain"
                objectPosition="50% 50%"
                alt="Project illustration mobile"
              />

              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: project.acf.project_intro_extended,
                }}
              />

              {project.acf.project_advantages_cards && (
                <div className="info_list">
                  {project.acf.project_advantages_cards.map((item, i) => (
                    <div key={`adv_${i}`} className="info_item">
                      <div
                        className="title"
                        dangerouslySetInnerHTML={{ __html: item.data }}
                      />
                      <div
                        className="desc"
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      />
                    </div>
                  ))}
                </div>
              )}

              <div className="info_list_btn">
                <div className="info_item_btn">
                  <Link
                    to={project.path.replace("/wp/", "/")}
                    className="btn_go"
                    dangerouslySetInnerHTML={{
                      __html: translator(langCurrent, {
                        ru: "Подробнее",
                        en: "More",
                        kz: "Көбірек",
                      }),
                    }}
                  />
                  {project.acf.is_show_link_invest ? (
                    <Link
                      to={translator(langCurrent, {
                        ru: "/investors/#invest-products",
                        en: "/en/investors-en/#invest-products",
                        kz: "/kz/investors-kz/#invest-products",
                      })}
                      className="button button__empty"
                      dangerouslySetInnerHTML={{
                        __html: translator(langCurrent, {
                          ru: "Стать инвестором",
                          en: "Become an investor",
                          kz: "Инвестор болыңыз",
                        }),
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="info_item_btn">
                  {project.acf.project_short_form ? (
                    <Link
                      to={translator(langCurrent, {
                        ru: "/investors",
                        en: "/en/investors-en",
                        kz: "/kz/investors-kz",
                      })}
                      className="button button__empty button__light"
                      dangerouslySetInnerHTML={{
                        __html: translator(langCurrent, {
                          ru: "Начать инвестировать",
                          en: "Start investing",
                          kz: "Инвестициялауды бастаңыз",
                        }),
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="image_wrapper">
              <Img
                className="project_illustration"
                fluid={
                  project.acf.project_illustration &&
                  project.acf.project_illustration.localFile &&
                  project.acf.project_illustration.localFile.childImageSharp
                    .fluid
                }
                objectFit="contain"
                objectPosition="50% 50%"
                alt="Project illustration"
              />
            </div>
          </div>
        ))}
    </StyledOurProjectsExternalBlock>
  );
};

const StyledOurProjectsExternalBlock = styled.div`
  &.block-container {
    padding: 0 0 20px;
    @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
      padding: 30px 70px 60px;
    }
  }

  .project {
    margin-bottom: 80px;
    position: relative;
    background: linear-gradient(0deg, #f3f5f9 0%, rgba(243, 245, 249, 0) 33.64%),
      linear-gradient(180deg, #f3f5f9 0%, #f3f5f9 46.86%, #f3f5f9 100%);
    padding: 30px 20px;
    display: flex;
    flex-direction: row;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      padding: 40px 50px;
      margin-bottom: 90px;
    }
    @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
      padding: 60px 70px;
      min-height: 465px;
    }
    &:last-child {
      margin-bottom: 0;
    }

    .image_wrapper {
      width: 300px;
      height: auto;
      margin-left: auto;
      position: absolute;
      right: -30px;
      top: calc(50% - 170px);
      display: none;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        display: block;
      }
      @media (min-width: ${BREAKPOINTS.desktop_lg + "px"}) {
        width: 480px;
        top: -50px;
      }

      picture {
        width: 500px;
      }
    }

    .content_wrapper {
      width: 100%;
      height: auto;
      margin-right: auto;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        width: 65%;
      }
      @media (min-width: ${BREAKPOINTS.desktop_lg + "px"}) {
        width: 50%;
      }

      h2 {
        font-weight: bold;
        font-size: 30px;
        line-height: 40px;
        color: #0f1a20;
        display: block;
        margin-bottom: 10px;
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          font-size: 50px;
          line-height: 65px;
          margin-bottom: 7px;
        }
      }

      .project_illustration_mobile {
        display: block;
        width: 100%;
        max-width: 350px;
        height: auto;
        margin-bottom: 30px;
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          display: none;
        }
      }

      .link_list {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .link_wrapper {
          width: fit-content;
          height: auto;
          margin-right: 30px;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 22px;
          &:last-child {
            margin-right: 0;
          }

          .country_flag {
            width: 30px;
            height: 20px;
            margin-right: 10px;
          }

          a {
            display: flex;
            flex-direction: row;
            align-items: center;
            span {
              font-size: 15px;
              line-height: 20px;
              text-align: right;
              color: #0083e5;
              display: block;
              margin-right: 10px;
              @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
                font-size: 16px;
                line-height: 25px;
                margin-right: 5px;
              }
            }
            img {
              width: 10px;
              height: auto;
            }
          }
        }
      }

      .content {
        font-size: 16px;
        line-height: 25px;
        color: #040404;
        margin-bottom: 30px;
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          margin-bottom: 20px;
        }
      }

      .info_list {
        display: flex;
        flex-direction: row;
        margin-bottom: 30px;
        .info_item {
          height: auto;
          margin-right: 30px;
          @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
            width: 30%;
          }

          .title {
            font-weight: bold;
            font-size: 20px;
            line-height: 30px;
            color: #0083e5;
            white-space: nowrap;
            @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
              font-size: 30px;
              line-height: 30px;
            }
          }

          .desc {
            font-size: 15px;
            line-height: 20px;
            color: #898989;
            white-space: nowrap;
            @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
              font-size: 16px;
              line-height: 25px;
            }
          }
        }
      }

      .btn_go {
        width: fit-content;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 30px;
        border-radius: 60px;
        border: 1px solid #0083e5;
        font-size: 15px;
        line-height: 20px;
        color: #0083e5;
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          font-size: 16px;
          line-height: 25px;
        }
        &:hover {
          color: #0062ac;
          border-color: #0062ac;
          text-decoration: none;
        }
        &:focus {
          color: #104f7e;
          border-color: #104f7e;
          outline: none;
        }
      }

      .info_item_btn {
        display: flex;
        flex-wrap: wrap;
        margin: -10px -15px;
        @media (min-width: ${BREAKPOINTS.mobile + "px"}) {
          flex-wrap: nowrap;
        }

        & > * {
          display: flex;
          margin: 10px 15px;
          padding: 14px 24px;
          height: 55px;
          font-size: 16px;
          line-height: 25px;
          border-radius: 60px;
          @media (min-width: ${BREAKPOINTS.mobile + "px"}) {
            display: block;
          }
        }
      }
    }
  }
`;
