import React from "react";
import styled from "styled-components";

import { BREAKPOINTS } from "../../../settings";

export const ProductsExtendedItemDescription = ({ description }) => {
  return <StyledDesc dangerouslySetInnerHTML={{ __html: description }} />;
};

const StyledDesc = styled.p`
  margin: 20px 0 0;
  color: #0f1a20;
  opacity: 0.8;

  @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
    margin: 30px 0 0;
  }
`;
