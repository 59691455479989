import React, { useState } from "react";
import { Link, graphql } from "gatsby";
import styled from "styled-components";

import { EventItem } from "./EventItem";

import { useSelector } from "react-redux";
import { useHistoryCompanyList } from "../../hooks/use-history-company-list";
import { langGetHistoryItems, translator } from "../../helpers/lang";

import { BREAKPOINTS } from "../../settings";
import arrow_right from "../../images/arrow_right.svg";
import { Translator } from "../Translator";
import { IconArrow } from "../Icons/IconArrow";

export const HistoryCompanyExternalBlock = ({ data, hasTitle, id: pageId }) => {
  const dataBase = useSelector(state => state.base);
  const langCurrent = dataBase.lang.current;
  const allLangHistoryList = useHistoryCompanyList();
  const [showAllItems, setShowAllItems] = useState(false);
  const initialVisibleNewsCount = 4;

  const historyItems = langGetHistoryItems(
    allLangHistoryList,
    "history",
    langCurrent
  );
  const isHistoryPage = [2936, 2941, 2943].includes(pageId);
  const [activeIndex, setActiveIndex] = useState(0);
  const openTab = e => setActiveIndex(+e.target.dataset.index);

  const isShowButton = data.is_show_button;
  let classNamesArray = ["button", "button__secondary", "button__with-arrow"];

  if (isShowButton) {
    classNamesArray.push("button__hide-mobile");
  }

  return (
    <StyledHistoryCompanyExternalBlock className="block-container">
      {hasTitle && (
        <div className="block_title">
          <h2
            dangerouslySetInnerHTML={{
              __html: translator(langCurrent, {
                ru: "История компании",
                en: "History of the company",
                kz: "Компания тарихы",
              }),
            }}
          />

          <Link
            to={translator(langCurrent, {
              ru: "/history/",
              en: "/en/history-en/",
              kz: "/kz/history-kz/",
            })}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: translator(langCurrent, {
                  ru: "Подробнее",
                  en: "More",
                  kz: "Толығырақ",
                }),
              }}
            />

            <img src={arrow_right} alt="" />
          </Link>
        </div>
      )}
      <div className="history_preview_wrapper">
        <div className="tabs_list">
          {historyItems &&
            historyItems.map((item, index) => (
              <button
                key={item.year}
                className={`tab ${index === activeIndex ? "active" : ""}`}
                onClick={openTab}
                data-index={index}
                aria-label={item.year}
                dangerouslySetInnerHTML={{
                  __html: item.year,
                }}
              />
            ))}
        </div>

        <div className="preview_list">
          {historyItems && historyItems[activeIndex] && (
            <>
              <div className="year_block">
                {historyItems[activeIndex].events &&
                  historyItems[activeIndex].events.map((event, index) =>
                    showAllItems || index < initialVisibleNewsCount ? (
                      <EventItem key={index} event={event} />
                    ) : null
                  )}
              </div>
              {historyItems[activeIndex].events.length >
              initialVisibleNewsCount ? (
                <button
                  type="button"
                  onClick={() => {
                    setShowAllItems(!showAllItems);
                  }}
                  className={classNamesArray.join(" ")}
                >
                  <span>
                    {!showAllItems
                      ? translator(langCurrent, {
                          ru: "Подробнее",
                          en: "More",
                          kz: "Толығырақ",
                        })
                      : translator(langCurrent, {
                          ru: "Скрыть",
                          en: "Hide",
                          kz: "Жасыру",
                        })}
                    <IconArrow size={20} />
                  </span>
                </button>
              ) : null}
            </>
          )}
        </div>
      </div>
      {!isHistoryPage && (
        <Link
          to={translator(langCurrent, {
            ru: "/history/",
            en: "/en/history-en/",
            kz: "/kz/history-kz/",
          })}
          className="history_link button button__secondary button__with-arrow"
        >
          <span>
            <Translator
              langCode={langCurrent}
              translations={{
                ru: "Подробнее",
                en: "More",
                kz: "Толығырақ",
              }}
            />
            <IconArrow size={20} />
          </span>
        </Link>
      )}
    </StyledHistoryCompanyExternalBlock>
  );
};

const StyledHistoryCompanyExternalBlock = styled.div`
  ${props => (props.hasTitle ? "" : "padding-top: 0;")};

  .block_title {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #b6bec0;
    margin-bottom: 15px;
    padding-bottom: 15px;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
      padding-bottom: 0;
    }
    h2 {
      font-weight: bold;
      font-size: 30px;
      line-height: 40px;
      color: #040404;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        font-size: 30px;
        line-height: 45px;
      }
    }

    a {
      font-size: 16px;
      line-height: 25px;
      color: #040404;
      display: none;
      width: fit-content;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        display: block;
      }
      &:hover {
        img {
          right: -3px;
        }
      }
      img {
        width: 10px;
        height: 10px;
        display: inline-block;
        margin-left: 4px;
        position: relative;
        right: 0;
        transition: right 0.3s ease-in-out;
      }
    }
  }

  .history_preview_wrapper {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .tabs_list {
      width: 100%;
      overflow: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 20px;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        width: 15%;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0;
      }
      @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
        width: 25%;
      }
      .tab {
        border: none;
        background: transparent;
        font-size: 18px;
        line-height: 32px;
        color: #898989;
        cursor: pointer;
        display: block;
        padding: 0 10px;
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          margin-bottom: 20px;
          padding: 0;
          font-size: 20px;
          line-height: 35px;
        }

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          margin-bottom: 0;
          padding-right: 0;
        }

        &:hover,
        &.active {
          color: #0083e5;
        }
      }
    }
    .preview_list {
      width: 100%;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        width: 85%;
      }
      @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
        width: 75%;
      }
      .year_block {
        width: 100%;
        height: auto;

        & + button {
          margin-top: 25px;
        }
      }
    }
  }

  .history_link {
    margin: 30px 0 0;

    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      display: none;
    }
  }

  .button {
    &__hide-mobile {
      display: none;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        display: block;
      }
    }
  }
`;

export const query = graphql`
  fragment HistoryCompanyExternal on WordPressAcf_history_company_external {
    is_show_button
  }
`;
