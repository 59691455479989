import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const SliderProjectTeamBlock = ({ children }) => {
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    setMobile(window.matchMedia("(max-width: 576px)").matches);
  }, []);

  const settings = {
    arrows: false,
    focusOnSelect: true,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    centerMode: true,
  };

  return isMobile ? (
    <StyledSliderProjectTeamBlock {...settings}>
      {children}
    </StyledSliderProjectTeamBlock>
  ) : (
    <>{children}</>
  );
};

const StyledSliderProjectTeamBlock = styled(Slider)`
  width: 100%;
  flex-grow: 1;
  .slick-list {
    padding: 0 40px 0 0;
    margin-left: -50px;
    .slick-track {
      width: 100%;
      display: flex;
      flex-direction: row;
      .slick-slide {
        margin-right: 10px;
      }
    }
  }
`;
