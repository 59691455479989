import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { COLORS } from "../../settings";

export const AboutBlock = ({ data }) => {
  const { text } = data;

  const is_full_width = data.is_full_width;

  return (
    <StyledAboutBlock className="block-container">
      <div
        className={`side-panel text-big ${is_full_width && "full_width"}`}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </StyledAboutBlock>
  );
};

const StyledAboutBlock = styled.div`
  .side-panel {
    &.full_width {
      width: 100%;
    }
  }

  .side-panel > * {
    margin: 0;

    em {
      color: ${COLORS.text_accent};
      font-style: normal;
    }

    + * {
      margin: 15px 0 0;
    }
  }
`;

export const query = graphql`
  fragment AboutBlock on WordPressAcf_about_block {
    id
    text
    is_full_width
  }
`;
