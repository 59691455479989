import React from "react";

import styled from "styled-components";

import { BREAKPOINTS } from "../../../../settings";

export const EcologyHorizontalList = ({ data }) => {
  return (
    <StyledEcologyHorizontalList>
      {data.map((item, index) => (
        <div key={index} className="item">
          <div
            className="title"
            dangerouslySetInnerHTML={{
              __html: item.eh_title,
            }}
          />

          <img
            src={item.eh_image && item.eh_image.localFile.publicURL}
            alt={item.eh_title}
          />
        </div>
      ))}
    </StyledEcologyHorizontalList>
  );
};

const StyledEcologyHorizontalList = styled.div`
  margin-top: 50px;
  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
    margin-top: 70px;
  }
  .item {
    width: 100%;
    height: auto;
    min-height: 100px;
    background: #f6f8fa;
    border-radius: 7px;
    margin-bottom: 35px;
    padding: 20px 150px 20px 20px;
    position: relative;
    &:last-child {
      margin-bottom: 0;
    }
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      padding: 40px 170px 40px 40px;
    }
    .title {
      font-size: 15px;
      line-height: 20px;
      color: #040404;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        font-size: 25px;
        line-height: 35px;
      }
    }
    img {
      position: absolute;
      top: 20px;
      right: 20px;
      width: auto;
      height: auto;
      max-width: 100px;
      max-height: 60px;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        max-width: 120px;
        max-height: 80px;
        top: 40px;
        right: 40px;
      }
    }
  }
`;
