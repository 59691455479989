import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { BREAKPOINTS, COLORS } from "../../../settings";
import { updateBondsCalcTerm } from "../../../store/actions/calc_bonds";
import { updateBondsCalcActiveBonds } from "../../../store/actions/calc_bonds";
import { updateBondsCalcCurrentActiveBond } from "../../../store/actions/calc_bonds";

export const BondsCalculatorTermsSwitcher = ({ data }) => {
  const dispatch = useDispatch();
  const dataBase = useSelector(state => state.base);
  const [active, setActive] = useState(
    data.lastActiveBonds[data.currentProduct]
  );

  const langCurrent = dataBase.lang.current;
  const currentProduct = data.currentProduct;

  useEffect(() => {
    setActive(data.lastActiveBonds[data.currentProduct]);
  }, [currentProduct]);

  const clickHandler = (termID, key) => {
    if (window.ym) {
      window.ym(57564463, "reachGoal", "bond_calc_interact");
    }
    if (window.dataLayer) {
      window.dataLayer.push({ event: "bond_calc_interact" });
    }

    updateBondsCalcTerm(dispatch, termID);
    updateBondsCalcActiveBonds(dispatch, key);
    updateBondsCalcCurrentActiveBond(dispatch, key);
    setActive(key);
  };

  return (
    <StyledBondsCalculatorTermsSwitcher>
      {Object.keys(data.products[data.currentProduct]).map(function (
        key,
        index
      ) {
        return key !== "percents" && key !== "name" ? (
          <StyledBondsCalculatorTermsSwitcherButton
            key={`bond_${index}`}
            className={key === active ? "active" : ""}
            onClick={() => clickHandler(index, key)}
          >
            <div className="title">
              {data.products[data.currentProduct][key].name[langCurrent]}
            </div>
            <div className="info">
              <span className="term">
                {data.products[data.currentProduct][key].term}
              </span>
              <span className="rate">
                {data.products[data.currentProduct][key].rate}%
              </span>
            </div>
          </StyledBondsCalculatorTermsSwitcherButton>
        ) : null;
      })}
    </StyledBondsCalculatorTermsSwitcher>
  );
};

const StyledBondsCalculatorTermsSwitcher = styled.div``;

const StyledBondsCalculatorTermsSwitcherButton = styled.button`
  display: block;
  width: 100%;
  background: hsl(0, 0%, 100%);
  padding: 20px 30px;
  border: 1px solid transparent;
  border-radius: 6px;
  font-size: 15px;
  line-height: 20px;
  text-align: left;
  @media (min-width: ${BREAKPOINTS.desktop}px) {
    font-size: 16px;
    line-height: 25px;
  }
  .title {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
  }
  .info {
    display: flex;
    justify-content: space-between;
    margin: 5px 0 0;
    .term {
      color: ${COLORS.text_secondary};
    }
    .rate {
      color: ${COLORS.text_secondary};
      font-size: 20px;
      line-height: 20px;
      font-weight: 700;
    }
  }
  & + & {
    margin: 30px 0 0;
  }
  &.active {
    border-color: ${COLORS.text_accent};
    .title,
    .info .rate {
      color: ${COLORS.text_accent};
    }
  }
`;
