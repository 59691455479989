import { useStaticQuery, graphql } from "gatsby";

export const useDepartamentTranslate = () => {
  const { allWordpressAcfOptions } = useStaticQuery(
    graphql`
      query Departaments {
        allWordpressAcfOptions {
          nodes {
            options {
              departments {
                name
                name_en
                name_kz
              }
            }
          }
        }
      }
    `
  );

  return allWordpressAcfOptions.nodes[0].options.departments;
};
