import React from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import styled from "styled-components";
import { BREAKPOINTS, COLORS, LAYOUT } from "../../settings";
import imgCFS from "../../images/cfs-alt.svg";

export const MainPageHeadingBlock = ({ data }) => {
  const { title, link, background } = data;

  return (
    <StyledMainPageHeadingBlock>
      <Img
        fluid={background?.localFile?.childImageSharp?.fluid}
        objectFit="cover"
        objectPosition="32% 50%"
        alt=""
      />
      <div className="element-container">
        <h1 className="h1" dangerouslySetInnerHTML={{ __html: title }} />
        <Link to={link.url} className="button button__empty button__light">
          <span>{link.title}</span>
        </Link>
      </div>
    </StyledMainPageHeadingBlock>
  );
};

const StyledMainPageHeadingBlock = styled.div`
  position: relative;
  display: flex;
  min-height: 650px;
  height: 1px; // NOTE: hack for ie
  background: ${COLORS.bg_dark};
  padding: 105px 0 60px;
  margin: 0 0 30px;

  flex-direction: column;
  justify-content: flex-end;
  align-items: center; // NOTE: hack for ie

  @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
    margin: 0 0 60px;
    max-width: 1366px;
    margin-left: auto;
    margin-right: auto;
  }

  .gatsby-image-wrapper {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .element-container {
    position: relative;
    z-index: 3;
    width: 100%; // NOTE: hack for ie

    &:after {
      content: "";
      position: absolute;
      right: 0px;
      bottom: -87px;
      width: 110px;
      height: 65px;
      border-radius: 30px 0 0 0;
      background-color: #fff;
      z-index: 1;

      @media (min-width: ${BREAKPOINTS.mobile + "px"}) {
        right: 55px;
        width: 200px;
        height: 120px;
        border-radius: 50px 50px 0 0;
      }
    }

    &::before {
      content: "";
      position: absolute;
      right: 4px;
      bottom: -85px;
      width: 100px;
      height: 58px;
      z-index: 2;
      background: url(${imgCFS}) center/contain no-repeat;

      @media (min-width: ${BREAKPOINTS.mobile + "px"}) {
        right: 68px;
        bottom: -80px;
        width: 173px;
        height: 100px;
      }

      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        right: 68px;
        bottom: -80px;
      }

      @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
        right: ${LAYOUT.gap_desktop * 2 + "px"};
      }
    }
  }

  .h1 {
    color: ${COLORS.text_inverted};
    max-width: 600px;
  }

  .button {
    margin: 20px 0 0;

    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      margin: 30px 0 0;
    }
  }
`;

export const query = graphql`
  fragment MainPageHeadingBlock on WordPressAcf_main_page_heading_block {
    id
    title
    link {
      url
      title
    }
    background {
      localFile {
        childImageSharp {
          fluid(
            maxWidth: 1920
            srcSetBreakpoints: [1920]
            jpegProgressive: true
            jpegQuality: 100
            pngQuality: 100
            quality: 100
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;
