// libraries
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
// constants
import { BREAKPOINTS, COLORS } from '../../settings';
// hooks
import { useCitiesTranslate } from '../../hooks/use-cities-translate';
import { useVacanciesList } from '../../hooks/use-vacancies-list';
import { useDepartamentTranslate } from '../../hooks/use-departament-translate';
// helpers
import { translator } from '../../helpers/lang';
import { getTranslatedDepartamentTitle, getTranslatedCityTitle } from '../../helpers/base';
// store
import { updateModalState } from '../../store/actions/base';
// static
import nav_bottom_svg from '../../images/subnav_open_dark.svg';
import arrowIcon from '../../images/arrow.svg';

function getCropVacancyList(fullVacancyList, depTranslateList, citeTranslateList, langCurrent) {
    let list = [];

    fullVacancyList.forEach((vacancy) => list.push(vacancy.node.acf));

    list.forEach(
        (vacancy) =>
            (vacancy.department = getTranslatedDepartamentTitle(vacancy.department, langCurrent, depTranslateList)) &&
            (vacancy.region = getTranslatedCityTitle(vacancy.city, langCurrent, citeTranslateList))
    );

    return list;
}

function getCitiesTitlesList(cropVacancyList, langCurrent) {
    let set = new Set();

    switch (langCurrent) {
        case 'ru':
            set.add('Все города');
            break;
        case 'en':
            set.add('All cities');
            break;
        default:
            set.add('Все города');
            break;
    }

    cropVacancyList.forEach((vacancy) => set.add(vacancy.region));

    return Array.from(set.values());
}

function getDepartamentsTitlesList(cropVacancyList, langCurrent) {
    let set = new Set();

    switch (langCurrent) {
        case 'ru':
            set.add('Все вакансии');
            break;
        case 'en':
            set.add('All vacancies');
            break;
        default:
            set.add('Все вакансии');
            break;
    }

    cropVacancyList.forEach((vacancy) => set.add(vacancy.department));

    return Array.from(set.values());
}

function getFilteredVacancyList(
    cropVacancyList,
    citiesTitlesList,
    departamentsTitlesList,
    citiesIndex,
    departamentIndex
) {
    if (citiesIndex === 0 && departamentIndex === 0) {
        return cropVacancyList;
    }

    let tempList = [];

    let сhosenCity = citiesTitlesList[citiesIndex];
    let сhosenDep = departamentsTitlesList[departamentIndex];

    if (citiesIndex !== 0) {
        for (let vacancy of cropVacancyList) {
            if (vacancy.region === сhosenCity) {
                tempList.push(vacancy);
            }
        }

        if (tempList.length < 1) {
            return null;
        } else {
            cropVacancyList = tempList;
            tempList = [];
        }
    }

    if (departamentIndex !== 0) {
        for (let vacancy of cropVacancyList) {
            if (vacancy.department === сhosenDep) {
                tempList.push(vacancy);
            }
        }

        if (tempList.length < 1) {
            return null;
        } else {
            cropVacancyList = tempList;
            tempList = [];
        }
    }

    return cropVacancyList;
}

export const OpenVacanciesBlock = ({ data }) => {
    const { title } = data;
    const dataBase = useSelector((state) => state.base);
    const langCurrent = dataBase.lang.current;
    const dispatch = useDispatch();

    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [isDepartamentsOpen, setIsDepartamentsOpen] = useState(false);
    const [departamentIndex, setDepartamentIndex] = useState(0);

    const depIndex = (e) => (setDepartamentIndex(+e.target.dataset.index), setIsDepartamentsOpen(!isDepartamentsOpen));

    const [isCitiesOpen, setIsCitiesOpen] = useState(false);
    const [citiesIndex, setCitiesIndex] = useState(0);

    const cityIndex = (e) => (setCitiesIndex(+e.target.dataset.index), setIsCitiesOpen(!isCitiesOpen));

    const depTranslateList = useDepartamentTranslate();
    const citeTranslateList = useCitiesTranslate();
    const fullVacancyList = useVacanciesList(langCurrent);
    const cropVacancyList = getCropVacancyList(fullVacancyList, depTranslateList, citeTranslateList, langCurrent);
    const citiesTitlesList = getCitiesTitlesList(cropVacancyList, langCurrent);
    const departamentsTitlesList = getDepartamentsTitlesList(cropVacancyList, langCurrent);
    const vacancyList = getFilteredVacancyList(
        cropVacancyList,
        citiesTitlesList,
        departamentsTitlesList,
        citiesIndex,
        departamentIndex
    );

    const buttonClick = (index) => {
        updateModalState(dispatch, { type: 'vacancyModal', dataVacancy: vacancyList[index] });
    };

    return (
        <StyledOpenVacanciesBlock className="block-container">
            <StyledTitle
                dangerouslySetInnerHTML={{
                    __html: title,
                }}
            />

            <StyledBlockTitle>
                <StyledVacancyBlockTitle
                    dangerouslySetInnerHTML={{
                        __html: translator(langCurrent, {
                            ru: 'Вакансии',
                            en: 'Vacancies',
                        }),
                    }}
                />

                <StyledFiltersButton
                    className={isFilterOpen ? 'open' : ''}
                    onClick={() => setIsFilterOpen(!isFilterOpen)}
                >
                    <span
                        dangerouslySetInnerHTML={{
                            __html: translator(langCurrent, {
                                ru: 'Фильтр',
                                en: 'Filter',
                            }),
                        }}
                    />
                    <img src={nav_bottom_svg} alt="nav" className={isCitiesOpen ? 'open' : ''} />
                </StyledFiltersButton>

                <StyledTitleFilter className={isFilterOpen ? 'show' : ''}>
                    <StyledVisibleItem onClick={() => setIsDepartamentsOpen(!isDepartamentsOpen)}>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: departamentsTitlesList[departamentIndex],
                            }}
                        />
                        <img src={nav_bottom_svg} alt="nav" className={isDepartamentsOpen ? 'open' : ''} />
                    </StyledVisibleItem>

                    <StyledHiddenList className={isDepartamentsOpen ? 'opened' : ''}>
                        {departamentsTitlesList &&
                            departamentsTitlesList.map((departament_title, index) => (
                                <StyledHiddenListItem
                                    key={index}
                                    className={index === departamentIndex ? 'active' : ''}
                                    dangerouslySetInnerHTML={{
                                        __html: departament_title,
                                    }}
                                    data-index={index}
                                    data-title={departament_title}
                                    onClick={depIndex}
                                />
                            ))}
                    </StyledHiddenList>
                </StyledTitleFilter>

                <StyledTitleFilter className={isFilterOpen ? 'show' : ''}>
                    <StyledVisibleItem onClick={() => setIsCitiesOpen(!isCitiesOpen)}>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: citiesTitlesList[citiesIndex],
                            }}
                        />
                        <img src={nav_bottom_svg} alt="nav" className={isCitiesOpen ? 'open' : ''} />
                    </StyledVisibleItem>

                    <StyledHiddenList className={isCitiesOpen ? 'opened' : ''}>
                        {citiesTitlesList &&
                            citiesTitlesList.map((city_title, index) => (
                                <StyledHiddenListItem
                                    key={index}
                                    className={index === citiesIndex ? 'active' : ''}
                                    dangerouslySetInnerHTML={{
                                        __html: city_title,
                                    }}
                                    data-title={city_title}
                                    data-index={index}
                                    onClick={cityIndex}
                                />
                            ))}
                    </StyledHiddenList>
                </StyledTitleFilter>
            </StyledBlockTitle>
            <StyledVacancyList>
                {vacancyList ? (
                    vacancyList.map((vacancy, index) => (
                        <StyledVacancyLinkButton
                            key={index}
                            onClick={() => buttonClick(index)}
                            aria-label="open vacancy information"
                        >
                            <StyledVacancyTitle
                                dangerouslySetInnerHTML={{
                                    __html: vacancy.title,
                                }}
                            />

                            <StyledVacancyDepart
                                dangerouslySetInnerHTML={{
                                    __html: vacancy.department,
                                }}
                            />

                            <StyledVacancyCity
                                dangerouslySetInnerHTML={{
                                    __html: vacancy.region,
                                }}
                            />

                            <StyledArrowIcon src={arrowIcon} alt="" aria-hidden />
                        </StyledVacancyLinkButton>
                    ))
                ) : (
                    <StyledEmptyResult
                        dangerouslySetInnerHTML={{
                            __html: translator(langCurrent, {
                                ru: 'Нет открытых позиций с заданными критериями',
                                en: 'There are no open positions with this criteria',
                            }),
                        }}
                    />
                )}
            </StyledVacancyList>
        </StyledOpenVacanciesBlock>
    );
};

const StyledOpenVacanciesBlock = styled.section`
    position: relative;
    z-index: 2;
    margin-bottom: -60px;
    padding-bottom: 80px;
`;

const StyledFiltersButton = styled.button`
    width: 100px;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 16px;
    line-height: 25px;
    text-align: right;
    color: #0f1a20;
    border: none;
    background-color: transparent;
    outline: none;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        display: none;
    }

    &.open {
        img {
            transform: rotate(180deg);
        }
    }

    img {
        width: 20px;
        height: 20px;
        position: relative;
        transform: rotate(0deg);
        transition: transform 0.4s ease-in-out;
    }
`;

const StyledVacancyList = styled.div`
    height: auto;
    position: relative;
    z-index: 1;
`;

const StyledEmptyResult = styled.div`
    height: auto;
    padding: 20px 0;
    font-weight: bold;
    text-align: center;
`;

const StyledArrowIcon = styled.img`
    display: none;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        display: block;
        position: absolute;
        right: 0;
        top: 29px;
    }

    svg {
        width: 20px;
        height: 20px;
    }
`;

const StyledVacancyLinkButton = styled.button`
    width: 100%;
    height: auto;
    border: none;
    border-bottom: 1px solid #b6bec0;
    display: flex;
    flex-direction: row;
    position: relative;
    flex-wrap: wrap;
    padding: 20px 0;
    color: #040404;
    background-color: transparent;
    outline: none;
    margin: 0;
    text-align: left;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        padding: 30px 30px 30px 0;
    }

    &:hover {
        text-decoration: none;
        opacity: 0.7;

        ${StyledArrowIcon} {
            svg {
                path {
                    stroke: #0083e5;
                }
            }
        }
    }
`;

const StyledVacancyDepart = styled.span`
    width: fit-content;
    padding-right: 15px;
    font-size: 15px;
    line-height: 20px;
    color: #0f1a20;
    display: flex;
    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        width: 20%;
        font-size: 16px;
        line-height: 25px;
        padding-right: 20px;
    }
`;

const StyledVacancyCity = styled.span`
    width: fit-content;
    font-size: 15px;
    line-height: 20px;
    color: #898989;
    display: flex;
    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        width: 20%;
        padding-right: 20px;
        font-size: 16px;
        line-height: 25px;
    }
`;

const StyledVacancyTitle = styled.span`
    width: 100%;
    padding-right: 20px;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 5px;
    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        width: 60%;
        font-size: 25px;
        line-height: 35px;
        margin-bottom: 0;
    }
`;

const StyledBlockTitle = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-right: 50px;
        padding-bottom: 15px;
        border-bottom: 1px solid #b6bec0;
    }
`;

const StyledTitleFilter = styled.div`
    display: none;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        position: relative;
        display: block;
        height: auto;
        width: 20%;
    }

    &.show {
        display: block;
        width: 100%;

        @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
            width: 20%;
        }
    }
`;

const StyledHiddenList = styled.div`
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 0 20px;
    border-bottom: 1px solid #b6bec0;
    overflow-x: auto;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        position: absolute;
        z-index: 2;
        left: 0;
        top: 40px;
        background-color: #fff;
        padding: 10px;
        border-radius: 0 0 7px 7px;
        display: none;
        border-bottom: none;

        &.opened {
            display: block;
        }
    }
`;

const StyledHiddenListItem = styled.button`
    color: #898989;
    cursor: pointer;
    margin-right: 20px;
    font-size: 15px;
    text-align: left;
    line-height: 20px;
    width: fit-content;
    white-space: nowrap;
    border: none;
    background-color: transparent;

    &.active {
        color: #0f1a20;
    }

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        display: block;
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 25px;
        color: #0f1a20;
        white-space: normal;

        &.active {
            display: none;
        }
    }

    &:hover {
        opacity: 0.7;
    }

    &:last-child {
        margin-bottom: 0;
        margin-right: 0;
    }
`;

const StyledVisibleItem = styled.button`
    display: none;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        padding-right: 40px;
        padding-left: 10px;
        background-color: transparent;
        border: none;
        outline: none;
        margin: 0;
        text-align: left;
        cursor: pointer;
    }

    &:hover {
        opacity: 0.7;
    }

    span {
        display: block;
        font-size: 16px;
        line-height: 25px;
        color: #0f1a20;
        text-overflow: ellipsis;
        position: relative;
        white-space: nowrap;
        overflow: hidden;
    }

    img {
        width: 20px;
        height: 20px;
        transform: rotate(0deg);
        transition: transform 0.4s ease-in-out;
        &.open {
            transform: rotate(180deg);
        }
    }
`;

const StyledVacancyBlockTitle = styled.h2`
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    color: #040404;
    margin: 0;
    margin-right: auto;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        font-size: 30px;
        line-height: 40px;
        width: 60%;
    }

    @media (min-width: ${BREAKPOINTS.desktop + 'px'}) {
        visibility: hidden;
    }
`;

const StyledTitle = styled.h2`
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 28px;
    line-height: 1.3;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        margin-bottom: 64px;
        font-size: 46px;
        text-align: center;
    }

    span {
        color: ${COLORS.text_accent};
    }
`;

export const query = graphql`
    fragment OpenVacanciesBlock on WordPressAcf_open_vacancies_block {
        title
    }
`;
