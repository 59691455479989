import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { graphql } from "gatsby";
import styled from "styled-components";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { BREAKPOINTS } from "../../settings";

export const TechnologyDevelopBlock = ({ data }) => {
  const tecnology_develop_list = data.tecnology_develop_list;

  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    setMobile(window.matchMedia("(max-width: 768px)").matches);
  }, []);

  const settings = {
    arrows: false,
    focusOnSelect: true,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 1.2,
    centerMode: false,
  };

  return (
    <>
      {tecnology_develop_list && (
        <StyledTechnologyDevelopBlock className="block-container">
          {isMobile ? (
            <Slider {...settings} className="items_list">
              {tecnology_develop_list.map((item, index) => (
                <div key={index} className="item">
                  <div
                    className="title"
                    dangerouslySetInnerHTML={{
                      __html: item.db_title,
                    }}
                  />
                  <div className="image">
                    <img
                      src={item.db_image && item.db_image.localFile.publicURL}
                      alt={item.db_title}
                    />
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            <div className="items_list">
              {tecnology_develop_list.map((item, index) => (
                <div key={index} className="item">
                  <div
                    className="title"
                    dangerouslySetInnerHTML={{
                      __html: item.db_title,
                    }}
                  />
                  <div className="image">
                    <img
                      src={item.db_image && item.db_image.localFile.publicURL}
                      alt={item.db_title}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </StyledTechnologyDevelopBlock>
      )}
    </>
  );
};

const StyledTechnologyDevelopBlock = styled.div`
  padding-top: 70px;
  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
    padding-top: 40px;
  }
  .items_list {
    position: relative;
    width: 100%;
    height: auto;
    display: block;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      width: calc(100% + 1.33%);
      left: -1.33%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-end;
    }
    @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
      width: calc(100% + 2.5%);
      left: -2.5%;
    }
    .item {
      width: 100%;
      height: auto;
      background: #f6f8fa;
      border-radius: 7px;
      padding: 30px 20px 90px;
      min-height: 215px;
      position: relative;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        margin-bottom: 50px;
        width: 32%;
        margin-left: 1.33%;
      }
      @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
        width: 23%;
        margin-left: 2%;
      }
      .title {
        font-weight: bold;
        font-size: 20px;
        line-height: 25px;
        color: #040404;
      }
      .image {
        width: auto;
        height: auto;
        max-width: 70px;
        max-height: 70px;
        position: absolute;
        right: 20px;
        bottom: 30px;
      }
    }
  }

  .slick-slide {
    > div {
      position: relative;
      margin-left: 35px;
      left: -35px;
    }
  }
`;

export const query = graphql`
  fragment TechnologyDevelopBlock on WordPressAcf_technology_develop_block {
    tecnology_develop_list {
      db_image {
        localFile {
          publicURL
        }
      }
      db_title
    }
  }
`;
