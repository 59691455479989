// libraries
import React from 'react';
import styled from 'styled-components';

export const SlidesCounter = ({ slideIndex, sliderLenght }) => {
    return (
        <StyledSlidesCounter>
            <div
                dangerouslySetInnerHTML={{
                    __html: slideIndex,
                }}
            />

            <span>-</span>

            <div
                dangerouslySetInnerHTML={{
                    __html: sliderLenght,
                }}
            />
        </StyledSlidesCounter>
    );
};

const StyledSlidesCounter = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #0f1a20;
    span {
        display: inline-block;
        margin: 0 5px;
    }
`;
