import { useStaticQuery, graphql } from "gatsby";

export const useHistoryCompanyList = () => {
  const {
      ru,
      // kz,
      en
  } = useStaticQuery(
    graphql`
      query HistoryCompanyList {
        ru: allWordpressPage(filter: { id: {}, wordpress_id: { eq: 2936 } }) {
          nodes {
            acf {
              components_page {
                ... on WordPressAcf_history_company {
                  history_list {
                    events {
                      content
                      type
                      #                      image {
                      #                        source_url
                      #                        alt_text
                      #                      }
                    }
                    year
                  }
                }
              }
            }
          }
        }
        # kz: allWordpressPage(filter: { id: {}, wordpress_id: { eq: 2941 } }) {
        #   nodes {
        #     acf {
        #       components_page {
        #         ... on WordPressAcf_history_company {
        #           history_list {
        #             events {
        #               content
        #               type
        #               image {
        #                 source_url
        #                 alt_text
        #               }
        #             }
        #             year
        #           }
        #         }
        #       }
        #     }
        #   }
        # }
        en: allWordpressPage(filter: { id: {}, wordpress_id: { eq: 2943 } }) {
          nodes {
            acf {
              components_page {
                ... on WordPressAcf_history_company {
                  history_list {
                    events {
                      content
                      type
                      #                      image {
                      #                        source_url
                      #                        alt_text
                      #                      }
                    }
                    year
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const historyRuNodes = ru.nodes[0].acf.components_page
    ? ru.nodes[0].acf.components_page.filter(
        item => item.__typename === "WordPressAcf_history_company"
      )[0].history_list
    : null;
  // const historyKzNodes = kz.nodes[0].acf.components_page
  //   ? kz.nodes[0].acf.components_page.filter(
  //       item => item.__typename === "WordPressAcf_history_company"
  //     )[0].history_list
  //   : null;
  const historyEnNodes = en.nodes[0].acf.components_page
    ? en.nodes[0].acf.components_page.filter(
        item => item.__typename === "WordPressAcf_history_company"
      )[0].history_list
    : null;

  return {
      historyRuNodes,
      // historyKzNodes,
      historyEnNodes
  };
};
