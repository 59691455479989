// libraries
import React from 'react';
import styled from 'styled-components';
// constants
import { BREAKPOINTS } from '../../../settings';

export const InfoTabsItem = ({ index, className, title, ariaLabel, setActiveIndex }) => {
    return (
        <StyledInfoTabsItem
            className={className}
            aria-label={ariaLabel}
            dangerouslySetInnerHTML={{
                __html: title,
            }}
            onClick={(event) => {
                setActiveIndex(index);
                event.target.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'center',
                });
            }}
        />
    );
};

const StyledInfoTabsItem = styled.button`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 35px;
    outline: none;
    border: none;
    background-color: transparent;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: -0.3px;
    color: #ffffff;
    opacity: 0.5;
    white-space: nowrap;
    cursor: pointer;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        min-width: 200px;
        height: 40px;
        font-size: 18px;
        
    }

    &.active {
        opacity: 1;
    }

    &:after {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: #fff;
        content: '';
    }

    &:focus,
    &:hover {
        outline: none;

        &:not(.active) {
            opacity: 0.7;
        }
    }
`;
