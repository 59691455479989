import React from "react";

export const IconDownload = ({ size }) => {
  return (
    <svg
      width={size || 20}
      height={size || 20}
      viewBox="0 0 20 20"
      className="icon icon__download"
    >
      <g fill="currentColor">
        <path d="M17.223 17.722H2.778a.555.555 0 100 1.111h14.445a.556.556 0 000-1.11z" />
        <path d="M10.001 16.878l5.895-5.894a.556.556 0 00-.784-.784l-4.555 4.55V3.278a.555.555 0 10-1.112 0V14.75l-4.55-4.55a.556.556 0 10-.783.784l5.889 5.894z" />
      </g>
    </svg>
  );
};
