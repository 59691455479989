import React from "react";
import styled from "styled-components";
import { useDispatch } from 'react-redux';
import { COLORS } from "../../../settings";
import { IconWatch } from "../../Icons/IconWatch";

// store
import { updateModalState } from '../../../store/actions/base';

export const VideoButton = ({ title, link }) => {
  const dispatch = useDispatch();

  const buttonClick = (link) => {
    updateModalState(dispatch, { type: 'videoModal', videoLink: link });
  };

  return (
    <StyledButton onClick={() => buttonClick(link)}>
      <IconWatch />
      {title}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  background-color: unset;
  color: ${COLORS.text_accent};
  font-size: 16px;
  line-height: 25px;
  text-decoration: none;

  &:hover,
  &:focus {
    color: #0062ac;
    text-decoration: none;
  }

  &:active {
    color: #104f7e;
  }
`;
