import { useStaticQuery, graphql } from "gatsby";

export const usePageInfo = pageId => {
  const { allWordpressPage } = useStaticQuery(
    graphql`
      query allWordpressPage {
        allWordpressPage {
          nodes {
            wordpress_id
            link
            path
            slug
            title
            content
            parent_element {
              link
              path
              title
            }
          }
        }
      }
    `
  );

  return allWordpressPage.nodes.filter(item => item.wordpress_id === pageId)[0];
};
