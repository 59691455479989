import React from "react";
import styled from "styled-components";

import { BREAKPOINTS, COLORS } from "../../../settings";

export const ProductsExtendedItemInfo = ({ info }) => {
  return info ? (
    <StyledList>
      {info.map((item, i) => (
        <StyledListItem key={`item_${i}`}>
          <StyledNumber
            className="number"
            dangerouslySetInnerHTML={{ __html: item.data }}
          />
          <StyledDescription
            dangerouslySetInnerHTML={{ __html: item.description }}
          />
        </StyledListItem>
      ))}
    </StyledList>
  ) : null;
};

const StyledList = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
`;

const StyledListItem = styled.li`
  display: flex;
  flex-direction: column;

  & + & {
    margin: 0 0 0 12px;

    @media (min-width: ${BREAKPOINTS.mobile_s + "px"}) {
      margin: 0 0 0 35px;
    }

    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      margin: 0 0 0 40px;
    }

    @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
      margin: 0 0 0 75px;
    }
  }
`;

const StyledNumber = styled.span`
  font-size: 15px;
  line-height: 30px;

  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
    font-size: 16px;
    line-height: 30px;
  }

  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
    font-size: 20px;
    line-height: 40px;
  }

  span {
    font-size: 30px;
    line-height: 30px;

    @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
      font-size: 40px;
      line-height: 40px;
    }
  }
`;

const StyledDescription = styled.span`
  margin: 5px 0 0;
  color: ${COLORS.text_secondary};
  font-size: 15px;
  line-height: 20px;

  @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
    font-size: 16px;
    line-height: 25px;
  }
`;
