import { useStaticQuery, graphql } from "gatsby";

export const useNewsComponent = () => {
  const { ru, en, kz } = useStaticQuery(
    graphql`
      query NewsComponent {
        ru: allWordpressPost(
          limit: 4
          filter: {
            polylang_current_lang: { eq: "ru_RU" }
            status: { eq: "publish" }
          }
          sort: { order: DESC, fields: date }
        ) {
          edges {
            node {
              id
              title
              path
              slug
              date(formatString: "DD MMM YYYY", locale: "ru")
              featured_media {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              categories {
                name
                slug
              }
            }
          }
        }

        en: allWordpressPost(
          limit: 4
          filter: {
            polylang_current_lang: { eq: "en_US" }
            status: { eq: "publish" }
          }
          sort: { order: DESC, fields: date }
        ) {
          edges {
            node {
              id
              title
              path
              slug
              date(formatString: "DD MMM YYYY", locale: "en")
              featured_media {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              categories {
                name
                slug
              }
            }
          }
        }

        kz: allWordpressPost(
          limit: 4
          filter: {
            polylang_current_lang: { eq: "kk" }
            status: { eq: "publish" }
          }
          sort: { order: DESC, fields: date }
        ) {
          edges {
            node {
              id
              title
              path
              slug
              date(formatString: "DD MMM YYYY", locale: "kk")
              featured_media {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              categories {
                name
                slug
              }
            }
          }
        }
      }
    `
  );

  const newsRuItems = ru.edges;
  const newsEnItems = en.edges;
  const newsKzItems = kz.edges;

  return { newsRuItems, newsEnItems, newsKzItems };
};
