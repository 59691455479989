import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Slider from "react-slick";

import { useNewsList } from "../../../hooks/use-news-list";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { BREAKPOINTS, COLORS } from "../../../settings";

export const PromoSlider = () => {
  const dataBase = useSelector(state => state.base);
  const langCurrent = dataBase.lang.current;
  const news_list = useNewsList(langCurrent);
  const sliced_news = news_list.slice(0, 4);

  const customeSlider = React.createRef();
  const settings = {
    arrows: false,
    focusOnSelect: true,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    centerMode: false,
    dots: true,
  };

  return (
    <>
      {sliced_news && (
        <StyledPromoSlider>
          <Slider {...settings} ref={customeSlider}>
            {sliced_news.map((news, index) => (
              <div key={index} className="slide_wrapper" title={news.title}>
                <div
                  className="date"
                  dangerouslySetInnerHTML={{
                    __html: news.date,
                  }}
                />

                <Link
                  className="title"
                  to={news.path.replace("/wp/", "/")}
                  dangerouslySetInnerHTML={{
                    __html: news.title,
                  }}
                />
              </div>
            ))}
          </Slider>
        </StyledPromoSlider>
      )}
    </>
  );
};

const StyledPromoSlider = styled.div`
  width: calc(100% + 40px);
  position: relative;
  left: -20px;
  height: auto;
  overflow: hidden;
  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
    border-radius: 7px;
    width: 100%;
    left: 0;
  }

  .slick-slider {
    width: 100%;
    height: auto;
    color: #fff;
    padding: 30px 20px;
    position: relative;
    z-index: 5;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      padding: 0;
    }
    .slide_wrapper {
      width: 100%;
      height: auto;
      padding-bottom: 130px;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        padding-bottom: 100px;
      }
      .date {
        font-size: 15px;
        line-height: 20px;
        color: #ffffff;
        margin-bottom: 5px;
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          font-size: 14px;
          line-height: 20px;
        }
      }
      .title {
        display: block;
        width: 100%;
        height: auto;
        color: ${COLORS.text_inverted};
        font-size: 25px;
        line-height: 30px;
        font-weight: bold;
        text-decoration: none;
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          width: 50%;
          font-size: 30px;
          line-height: 45px;
        }
      }
    }

    .slick-dots {
      position: relative;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex !important;
      flex-direction: row;
      justify-content: center;
      margin: 0;
      padding: 0;
      li {
        display: block;
        width: auto;
        height: auto;
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
        }

        &.slick-active {
          button {
            opacity: 1;
          }
        }

        button {
          display: block;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          padding: 0;
          margin: 0;
          background-color: #fff;
          border: none;
          outline: none;
          opacity: 0.5;
          &:before {
            display: none;
          }
        }
      }
    }
  }
`;
