import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { graphql } from "gatsby";
import styled from "styled-components";
import { BREAKPOINTS, COLORS } from "../../settings";
import { initBondsCalc } from "../../store/actions/calc_bonds";
import { BondsCalculatorSliders } from "./BondsCalculatorSliders";
import { BondsCalculatorPeriodSwitcher } from "./BondsCalculatorPeriodSwitcher";
import { BondsCalculatorTermsSwitcher } from "./BondsCalculatorTermsSwitcher";
import { BondsCalculatorInfo } from "./BondsCalculatorInfo";
import { translator } from "../../helpers/lang";
import { ProductsButton } from "../ShortFormButtons/ProductsButton";
import { ButtonManager } from "../ButtonManager";
import { OpenForm } from "../OpenForm";

const bondsCalsIsInit = bondsCalcState => {
  if (
    bondsCalcState.currentTerm === null ||
    bondsCalcState.percents === null ||
    bondsCalcState.currentPercent === null
  ) {
    return false;
  } else {
    return true;
  }
};

export const BoundsCalculatorBlock = ({ data, id }) => {
  const dispatch = useDispatch();
  const dataBase = useSelector(state => state.base);
  const isOldUser = dataBase.managerData;
  const dataCalcBonds = useSelector(state => state.calcBonds);
  const langCurrent = dataBase.lang.current;

  if (!bondsCalsIsInit(dataCalcBonds)) {
    initBondsCalc(dispatch);
  }

  return bondsCalsIsInit(dataCalcBonds) ? (
    <StyledBondsCalculatorBlock id="calc" className="block-container">
      <div className="block-container_inner">
        <StyledBondsCalculatorTitle>
          {translator(langCurrent, {
            ru: "Поможем рассчитать",
            en: "We’ll help calculate it",
            kz: "Айналымдағы облигациялар",
          })}
          {/*<BondsCalculatorPeriodSwitcher data={dataCalcBonds} />*/}
        </StyledBondsCalculatorTitle>
        <div className="calc-row">
          <div className="calc-column">
            <BondsCalculatorTermsSwitcher data={dataCalcBonds} />
          </div>
          <div className="calc-column">
            <BondsCalculatorSliders />
            <BondsCalculatorInfo />
          </div>
        </div>
        <div className="calc-submit-request">
          {isOldUser ? (
            <ButtonManager type={"bonds"} dataType={null} pageId={id} />
          ) : (
            <OpenForm type={"bonds"} />
          )}
          <p className="info">
            {translator(langCurrent, {
              ru: "Предварительный расчет.",
              en: "Preliminary calculation.",
              kz: "Алдын ала есептеу.",
            })}
            <br />
            {translator(langCurrent, {
              ru: "Не является публичной офертой.",
              en: "It is not a public offer.",
              kz: "Бұқаралық оферта болып табылмайды.",
            })}
          </p>
        </div>
      </div>
      {!isOldUser && <ProductsButton productType="bonds" />}
    </StyledBondsCalculatorBlock>
  ) : null;
};

export const StyledBondsCalculatorTitle = styled.h1`
  border-bottom: 1px solid ${COLORS.lines};
  padding: 0 0 25px;
  margin: 0;
  font-size: 25px;
  line-height: 30px;
  font-weight: bold;
  @media (min-width: ${BREAKPOINTS.tablet}px) {
    display: flex;
    justify-content: space-between;
    padding: 0 0 20px;
    line-height: 35px;
  }
  @media (min-width: ${BREAKPOINTS.desktop}px) {
    padding: 0 0 15px;
    font-size: 30px;
    line-height: 45px;
  }
`;

export const StyledBondsCalculatorBlock = styled.div`
  @media (max-width: ${BREAKPOINTS.desktop_lg - 1}px) {
    padding-right: 0;
    padding-left: 0;
  }
  .block-container_inner {
    background: ${COLORS.bg_light};
    padding: 60px;
    border-radius: 7px;
    @media (max-width: ${BREAKPOINTS.desktop_lg - 1}px) {
      padding-right: 70px;
      padding-left: 70px;
      border-radius: 0;
    }
    @media (max-width: ${BREAKPOINTS.desktop - 1}px) {
      padding: 40px 20px;
    }
    @media (max-width: ${BREAKPOINTS.tablet - 1}px) {
      padding: 30px 20px;
    }
  }
  .calc-row {
    display: flex;
    flex-direction: column;
    margin: 25px 0 0;
    @media (min-width: ${BREAKPOINTS.tablet}px) {
      flex-direction: row;
      margin: 30px -30px 0;
    }
    @media (min-width: ${BREAKPOINTS.desktop}px) {
      margin: 40px -30px 0;
    }
  }
  .calc-column {
    @media (min-width: ${BREAKPOINTS.tablet}px) {
      width: 50%;
      margin: 0 30px;
    }
  }
  .calc-submit-request {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 25px 0 0 0;
    border-top: 1px solid ${COLORS.lines};
    margin: 25px 0 0 0;
    @media (min-width: ${BREAKPOINTS.mobile}px) {
      flex-direction: row;
      align-items: center;
    }
    @media (min-width: ${BREAKPOINTS.desktop}px) {
      padding: 0;
      border: none;
      margin: 40px 0 0 0;
    }
    @media (min-width: ${BREAKPOINTS.desktop}px) {
      width: 50%;
      padding: 0 0 0 30px;
      margin: 40px 0 0 auto;
    }
    .info {
      margin: 20px 0 0 0;
      color: ${COLORS.text_secondary};
      font-size: 14px;
      line-height: 20px;
      @media (min-width: ${BREAKPOINTS.mobile}px) {
        margin: 0 0 0 35px;
      }
    }
  }
`;

export const query = graphql`
  fragment BoundsCalculatorBlock on WordPressAcf_bounds_calculator_block {
    id
  }
`;
