import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";

import { BREAKPOINTS } from "../../settings";

import { FilesGroup } from "./FilesGroup";

export const FilesBlock = ({ data }) => {
  const { category, block_title } = data;

  return category ? (
    <StyledFilesBlock className="block-container">
      <h2 className="files-block__title" dangerouslySetInnerHTML={{ __html: block_title }} />
      {category.map((item, i) => (
        <FilesGroup key={`files_category_${i}`} item={item} index={i} />
      ))}
    </StyledFilesBlock>
  ) : null;
};

const StyledFilesBlock = styled.div`
  .files-block__title {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 25px;
    line-height: 1.2;
    color: #0F1A20;

    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      margin-bottom: 30px;
      font-size: 30px;
      line-height: 1.5;
    }
  }
`;

export const query = graphql`
  fragment FilesBlock on WordPressAcf_files_block {
    id
    block_title
    category {
      category_name
      files {
        file_name
        file_variants {
          file_variant {
            url
            title
          }
          youtube
        }
      }
    }
  }
`;
