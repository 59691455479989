// libraries
import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/dist/css/splide-core.min.css';
// components
import { SliderPagination } from '../UI/SliderPagination';
import { ShortInfoItem } from './ShortInfoItem';
// constants
import { BREAKPOINTS, COLORS, LAYOUT } from '../../settings';

export const ShortInfoBlock = ({ data }) => {
    const { title, items } = data;

    const sliderOptions = {
        arrows: false,
        type: 'loop',
        fixedWidth: '280px',
        gap: 24,
        mediaQuery: 'min',
        padding: {left:LAYOUT.gap + 'px'},
        breakpoints: {
            [BREAKPOINTS.tablet]: {
                destroy: true,
            },
        },
    };    

    return (
        <StyledShortInfoBlock >
            <StyledTitle dangerouslySetInnerHTML={{ __html: title }} className="element-container"/>
            <StyledMobileSlider hasTrack={false} options={sliderOptions} aria-label="principles">
                <SplideTrack>
                    {items.map(({ icon, name: nameItem, description }, index) => (
                        <SplideSlide key={index}>
                            <ShortInfoItem key={index} icon={icon} name={nameItem} description={description} />
                        </SplideSlide>
                    ))}
                </SplideTrack>
                <SliderPagination />
            </StyledMobileSlider>
            <StyledDesktopGrid className="element-container">
                {items.map(({ icon, name: nameItem, description }, index) => (
                    <ShortInfoItem key={index} icon={icon} name={nameItem} description={description} />
                ))}
            </StyledDesktopGrid>
        </StyledShortInfoBlock>
    );
};

const StyledShortInfoBlock = styled.section`
    position: relative;
    z-index: 2;
    padding: 20px 0 50px;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        padding: 45px 0 120px;
    }
`;

const StyledMobileSlider = styled(Splide)`
    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        display: none;
    }

    .splide__track {
        margin: 0;
    }

    .splide__slide {
        display: flex;
        flex-direction: column;
    }

    .splide__slide__row {
        flex-grow: 1;
        height: initial !important;
    }
`;

const StyledDesktopGrid = styled.div`
    display: none;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        z-index: 2;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-grow: 1;
        flex-basis: 0;
        gap: 64px 0;
    }
`;

const StyledTitle = styled.h2`
    font-weight: 700;
    font-size: 28px;
    line-height: 1.3;
    
    &.element-container {
        margin-bottom: 24px;
    }

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        font-size: 46px;
        text-align: center;
        
        &.element-container {
            margin-bottom: 64px;
        }
    }

    span {
        color: ${COLORS.text_accent};
    }
`;

export const query = graphql`
    fragment ShortInfoBlock on WordPressAcf_short_info_block {
        title
        items {
            name
            description
            icon {
                localFile {
                    publicURL
                }
            }
        }
    }
`;
