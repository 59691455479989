import React from "react";
import { graphql } from "gatsby";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { translator } from "../../helpers/lang";
import { PromoSlider } from "./PromoSlider";
import { usePageInfo } from "../../hooks/use-page-info";
import { BreadcrumbsCustom } from "../InnerPageHeadingBlock/BreadcrumbsCustom";
import Img from "gatsby-image/withIEPolyfill";

import { BREAKPOINTS, COLORS } from "../../settings";

export const MediaPromoSliderBlock = ({ data, id }) => {
  const dataBase = useSelector(state => state.base);
  const langCurrent = dataBase.lang.current;
  const pageInfo = usePageInfo(id);

  return (
    <>
      {data && (
        <StyledMediaPromoSliderBlock className="block-container custom_container">
          <div className="head-bread-crumbs">
            <BreadcrumbsCustom pageInfo={pageInfo} />
          </div>
          <div className="title_block">
            <h1
              dangerouslySetInnerHTML={{
                __html: data.pc_title,
              }}
            />
          </div>
          <Img
            fluid={data.pc_background_img.localFile.childImageSharp.fluid}
            objectFit="cover"
            objectPosition="25% 50%"
            alt=""
          />

          <PromoSlider />
        </StyledMediaPromoSliderBlock>
      )}
    </>
  );
};

const StyledMediaPromoSliderBlock = styled.div`
  &.custom_container {
    position: relative;
    padding-top: 120px;
    padding-bottom: 0;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      padding-top: 180px;
      padding-bottom: 40px;
    }
  }

  .head-bread-crumbs {
    z-index: 3;
    position: relative;

    li::before {
      color: ${COLORS.text_inverted};
    }

    a {
      color: ${COLORS.text_inverted};
    }
  }

  .title_block {
    width: 100%;
    height: auto;
    margin-bottom: 40px;
    z-index: 3;
    position: relative;
    color: ${COLORS.text_inverted};

    h1 {
      font-weight: bold;
      font-size: 25px;
      line-height: 30px;
      display: block;
      margin-bottom: 0;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        font-size: 30px;
        line-height: 35px;
      }
      @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
        font-size: 40px;
        line-height: 45px;
      }
    }
  }

  .gatsby-image-wrapper {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &::before {
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: #004b8473;
      position: absolute;
      content: "";
      z-index: 1;
    }
  }
`;

export const query = graphql`
  fragment MediaPromoSliderBlock on WordPressAcf_media_promo_slider_block {
    pc_title
    pc_background_img {
      localFile {
        publicURL
        childImageSharp {
          fluid(
            maxWidth: 1920
            srcSetBreakpoints: [1920]
            jpegProgressive: true
            jpegQuality: 100
            pngQuality: 100
            quality: 100
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      alt_text
    }
  }
`;
