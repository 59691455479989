import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { translator } from "../../helpers/lang";
import { SliderProjectTeamBlock } from "./SliderProjectTeamBlock";

import { useTeamList } from "../../hooks/use-team-list";

import { BREAKPOINTS } from "../../settings";

export const ProjectTeamBlock = ({ data }) => {
  const dataBase = useSelector(state => state.base);
  const langCurrent = dataBase.lang.current;

  const ot_team_list = useTeamList();
  function get_sorted_team(arr, project_id) {
    let projects_array = [];

    for (let person of arr) {
      if (person.ot_project) {
        for (let person_project of person.ot_project) {
          if (project_id === person_project.value) {
            projects_array.push(person);
          }
        }
      }
    }

    return projects_array;
  }
  const sorted_team = get_sorted_team(ot_team_list, data.this_project_id);

  return (
    <>
      {sorted_team && (
        <StyledProjectTeamBlock className="block-container">
          <div className="block_title">
            <h2
              dangerouslySetInnerHTML={{
                __html: translator(langCurrent, {
                  ru: "Команда проекта",
                  en: "Project team",
                  kz: "Жоба тобы",
                }),
              }}
            />
          </div>

          <div className="our_team_wrapper">
            <SliderProjectTeamBlock>
              {sorted_team.map((user, index) => (
                <div key={index} className="team_item">
                  <div
                    className="image"
                    style={{
                      backgroundImage: `url(${
                        user.ot_image && user.ot_image.localFile.publicURL
                      })`,
                    }}
                  />

                  <div
                    className="name"
                    dangerouslySetInnerHTML={{
                      __html:
                        user.ot_name &&
                        user.ot_name.filter(
                          item => item.lang === langCurrent
                        ) &&
                        user.ot_name.filter(
                          item => item.lang === langCurrent
                        )[0].title,
                    }}
                  />

                  <div
                    className="description"
                    dangerouslySetInnerHTML={{
                      __html:
                        user.ot_position &&
                        user.ot_position.filter(
                          item => item.lang === langCurrent
                        ) &&
                        user.ot_position.filter(
                          item => item.lang === langCurrent
                        )[0].title,
                    }}
                  />
                </div>
              ))}
            </SliderProjectTeamBlock>
          </div>
        </StyledProjectTeamBlock>
      )}
    </>
  );
};

const StyledProjectTeamBlock = styled.div`
  .block_title {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #b6bec0;
    margin-bottom: 15px;
    padding-bottom: 15px;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
      padding-bottom: 0;
    }
    h2 {
      font-weight: bold;
      font-size: 30px;
      line-height: 40px;
      color: #040404;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        font-size: 30px;
        line-height: 45px;
      }
    }
  }

  .our_team_wrapper {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (min-width: ${BREAKPOINTS.mobile + "px"}) {
      justify-content: flex-start;
    }
    .team_item {
      width: 100%;
      height: auto;
      margin-bottom: 20px;

      @media (min-width: ${BREAKPOINTS.mobile + "px"}) {
        width: 48%;
        margin-right: 4%;
        &:nth-child(2n) {
          margin-right: 0;
        }
      }

      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        width: 31%;
        margin-right: 3.5%;
        margin-bottom: 50px;
        &:nth-child(2n) {
          margin-right: 3.5%;
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
      }

      @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
        width: 22%;
        margin-right: 4%;
        margin-bottom: 50px;
        &:nth-child(2n) {
          margin-right: 4%;
        }
        &:nth-child(3n) {
          margin-right: 4%;
        }
        &:nth-child(4n) {
          margin-right: 0;
        }
      }

      .image {
        width: 100%;
        height: 70vw;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: 20px;
        border-radius: 7px;
        overflow: hidden;
        position: relative;
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          height: 300px;
          margin-bottom: 25px;
        }

        @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
          height: 220px;
          margin-bottom: 30px;
        }
      }

      .name {
        width: 100%;
        font-weight: bold;
        font-size: 20px;
        line-height: 25px;
        color: #040404;
        margin-bottom: 5px;
        text-overflow: ellipsis;
        overflow: hidden;

        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          font-size: 22px;
          line-height: 31px;
        }

        @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
          font-size: 25px;
          line-height: 35px;
        }
      }

      .description {
        width: 100%;
        font-size: 15px;
        line-height: 20px;
        color: #898989;

        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          font-size: 16px;
          line-height: 25px;
        }
      }
    }
  }
`;
//TODO открыть, если на странице проектов нужен блок команда, ток же открыть в page.js
// export const query = graphql`
//   fragment ProjectTeamBlock on WordPressAcf_project_team_block {
//     this_project_id
//   }
// `;
