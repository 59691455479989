import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { BREAKPOINTS, COLORS, LAYOUT } from "../../../settings";
import { hexToRgb } from "../../../helpers/base";

import icoSliderArrow from "../../../images/slider_arrow.svg";
import icoSliderArrowInactive from "../../../images/slider_arrow_inactive.svg";

export const SliderProjects = ({ children, items }) => {
  const settings = {
    dots: true,
    arrows: true,
    fade: true,
    focusOnSelect: true,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    customPaging: function (i) {
      return <button type="button">{items[i]?.acf.project_name}</button>;
    },
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          dots: false,
          fade: false,
        },
      },
    ],
  };

  return <StyledSlider {...settings}>{children}</StyledSlider>;
};

const StyledSlider = styled(Slider)`
  width: 90%;
  margin: 0;

  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
    width: auto;
    margin: 40px 0 0;
  }

  @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
    margin: 50px 0 0;
  }

  .slick-list,
  .slick-track {
    height: 100%;
  }

  .slick-list {
    overflow: visible;
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    height: auto;

    > div {
      height: 100%;
    }
  }

  .slick-slide {
    pointer-events: none;

    padding: 0 35px 0 0;

    &.slick-active {
      pointer-events: auto;
    }
  }

  .slick-slide > div {
    position: relative;
  }

  .slick-arrow {
    z-index: 6;

    transform: translate(0, 0);
  }

  .slick-prev {
    top: auto;
    right: 50px;
    bottom: 0;
    left: auto;
    width: 30px;
    height: 30px;

    @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
      top: auto;
      right: auto;
      bottom: 0;
      left: 0;
    }

    &::before {
      content: "";

      display: block;
      background: url(${icoSliderArrow}) center/contain no-repeat;
      width: 30px;
      height: 30px;
      transform: scaleX(-1);
      transition: background 0.15s ease-out;
    }

    &.slick-disabled {
      &::before {
        opacity: 1;

        background-image: url(${icoSliderArrowInactive});
      }
    }
  }

  .slick-next {
    top: auto;
    right: 0;
    bottom: 0;
    left: auto;
    width: 30px;
    height: 30px;

    @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
      top: auto;
      right: auto;
      bottom: 0;
      left: 50px;
    }

    &::before {
      content: "";

      display: block;
      background: url(${icoSliderArrow}) center/contain no-repeat;
      width: 30px;
      height: 30px;
      transition: background 0.15s ease-out;
    }

    &.slick-disabled {
      &::before {
        opacity: 1;

        background-image: url(${icoSliderArrowInactive});
      }
    }
  }

  .slick-dots {
    top: auto;
    right: auto;
    bottom: 0;
    width: auto;

    @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
      top: 0;
      right: auto;
      bottom: auto;
      width: auto;
      display: flex !important;
      flex-direction: column;
    }

    li {
      width: auto;
      height: auto;
      margin: 0;

      &.slick-active {
        button {
          color: ${COLORS.text};
        }
      }

      + li {
        margin: 0 0 0 20px;
        @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
          margin: 10px 0 0;
        }
      }
    }

    button {
      width: auto;
      height: auto;
      padding: 0;
      color: ${COLORS.text_secondary};
      font-size: 18px;
      line-height: 20px;
      transition: color 0.15s ease-out;

      @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
        font-size: 20px;
        line-height: 30px;
      }

      &:hover {
        color: ${COLORS.text};
      }

      &::before {
        content: none;
      }
    }
  }
`;
