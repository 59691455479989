import React from "react";
import styled from "styled-components";

import { BREAKPOINTS } from "../../../settings";

export const TitleBlock = ({ data }) => {
  return (
    <StyledTitleBlock>
      <h1
        dangerouslySetInnerHTML={{
          __html: data.h1_title,
        }}
      />
      {data.h1_subtitle && (
        <span
          dangerouslySetInnerHTML={{
            __html: data.h1_subtitle,
          }}
        />
      )}
    </StyledTitleBlock>
  );
};

const StyledTitleBlock = styled.div`
  width: 100%;
  height: auto;
  h1 {
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    display: block;
    margin-bottom: 0;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      font-size: 30px;
      line-height: 35px;
    }
    @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
      font-size: 40px;
      line-height: 45px;
    }
  }
  span {
    font-size: 15px;
    line-height: 20px;
    color: #040404;
    opacity: 0.8;
    display: block;
    margin-top: 20px;
    @media (min-width: ${BREAKPOINTS.mobile + "px"}) {
      font-size: 20px;
      line-height: 35px;
    }
    br {
      display: none;
      @media (min-width: ${BREAKPOINTS.mobile + "px"}) {
        display: inline;
      }
    }
  }
`;
