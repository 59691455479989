import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { BREAKPOINTS, COLORS } from "../../../settings";
import { hexToRgb } from "../../../helpers/base";

import icoSliderArrow from "../../../images/slider_arrow.svg";
import icoSliderArrowInactive from "../../../images/slider_arrow_inactive.svg";

export const SliderBonds = ({ children }) => {
  const settings = {
    arrows: true,
    focusOnSelect: true,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
          slidesToScroll: 1,
          slidesToShow: 1,
        },
      },
    ],
  };

  return <StyledSlider {...settings}>{children}</StyledSlider>;
};

const StyledSlider = styled(Slider)`
  flex-grow: 1;
  width: 90%;

  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
    width: calc(100% / 3 * 2);
  }

  .slick-list,
  .slick-track {
    height: 100%;
  }

  .slick-list {
    overflow: visible;
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    height: auto;

    > div {
      height: 100%;
    }
  }

  .slick-list {
    &::before {
      content: "";
      pointer-events: none;
      z-index: 3;

      position: absolute;
      top: 0;
      bottom: 0;
      left: -20px;
      background: linear-gradient(
        to right,
        ${COLORS.bg_light} 30%,
        rgba(${hexToRgb(COLORS.bg_light).toString()}, 0) 100%
      );
      width: 20px;

      @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
        left: -35px;
        width: 35px;
      }
    }

    &::after {
      content: "";
      z-index: 3;

      position: absolute;
      top: 0;
      right: calc(-50% - 20px);
      bottom: 0;
      background: linear-gradient(
        to left,
        ${COLORS.bg_light} 30%,
        rgba(${hexToRgb(COLORS.bg_light).toString()}, 0) 100%
      );
      width: calc(100% / 3 * 2);

      @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
        right: calc(-50% - 36px);
      }
    }
  }

  .slick-slide {
    padding: 0 20px 0 0;

    @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
      padding: 0 35px 0 0;
    }
  }

  .slick-arrow {
    z-index: 6;
  }

  .slick-next {
    top: auto;
    right: calc(-50% + 5px);
    bottom: 0;
    left: auto;
    width: 30px;
    height: 30px;

    &::before {
      content: "";

      display: block;
      background: url(${icoSliderArrow}) center/contain no-repeat;
      width: 30px;
      height: 30px;
      transition: background 0.15s ease-out;
    }

    &.slick-disabled {
      &::before {
        opacity: 1;

        background-image: url(${icoSliderArrowInactive});
      }
    }
  }

  .slick-prev {
    top: auto;
    right: calc(-50% + 50px);
    bottom: 0;
    left: auto;
    width: 30px;
    height: 30px;

    &::before {
      content: "";

      display: block;
      background: url(${icoSliderArrow}) center/contain no-repeat;
      width: 30px;
      height: 30px;
      transform: scaleX(-1);
      transition: background 0.15s ease-out;
    }

    &.slick-disabled {
      &::before {
        opacity: 1;

        background-image: url(${icoSliderArrowInactive});
      }
    }
  }
`;
