import React from "react";
import styled from "styled-components";

import { BREAKPOINTS } from "../../../settings";

import icoCross from "../../../images/cross.svg";

export const FilesGroupTitle = ({ item, openedState }) => {
  const { category_name } = item;
  const { isOpened, setIsOpened } = openedState;

  return (
    <StyledButton onClick={() => setIsOpened(!isOpened)}>
      <h3 className="h3" dangerouslySetInnerHTML={{ __html: category_name }} />
      <StyledIcon src={icoCross} alt="" aria-hidden="true" opened={isOpened} />
    </StyledButton>
  );
};

const StyledButton = styled.button`
  position: relative;
  width: 100%;
  background: transparent;
  padding: 25px 36px 25px 0;
  border: none;
  color: #0F1A20;
  text-align: left;
  transition: opacity 0.15s ease-in-out;

  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
    padding: 32px 42px 32px 0;
  }

  @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
    padding: 50px 42px 50px 0;

    &:hover {
      opacity: 0.7;
    }
  }
`;

const StyledIcon = styled.img`
  position: absolute;
  top: 50%;
  right: 8px;
  display: block;
  width: 15px;
  height: 15px;
  transform: translateY(-50%)
    ${props => (props.opened ? "rotate(90deg)" : "rotate(-45deg)")};
  transition: transform 0.3s ease-in-out;

  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
    right: 18px;
    width: 18px;
    height: 18px;
  }
`;
