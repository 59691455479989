import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";

import { BREAKPOINTS } from "../../settings";

export const FooterLinksBlock = ({ data }) => {
  const right_arrow = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
  <path stroke="#040404" stroke-linecap="round" stroke-width="3" d="M2 15.4h24"/>
  <path stroke="#040404" stroke-width="2" d="M17.4 24.8v0l.002.002a.5.5 0 00.706 0V24.8l9-9a.502.502 0 00.11-.544.49.49 0 00-.11-.162l-9-9a.502.502 0 00-.854.354.501.501 0 00.147.354l8.64 8.64-8.64 8.64H17.4a.5.5 0 000 .706z"/>
</svg>`;

  return (
    <StyledHFooterLinksBlock className="block-container">
      {data.links_list &&
        data.links_list.map((item, index) => (
          <Link
            to={item.link}
            key={index}
            dangerouslySetInnerHTML={{
              __html: `<span>${item.title}</span>${right_arrow}`,
            }}
          />
        ))}
    </StyledHFooterLinksBlock>
  );
};

const StyledHFooterLinksBlock = styled.div`
  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 30px 0;
    border-bottom: 1px solid #b6bec0;
    &:first-child {
      border-top: 1px solid #b6bec0;
    }
    &:hover {
      text-decoration: none;
      span {
        color: #0083e5;
      }
      svg {
        path {
          stroke: #0083e5;
        }
      }
    }
    span {
      font-weight: bold;
      font-size: 20px;
      line-height: 25px;
      color: #0f1a20;
      display: block;
      margin-right: auto;
      width: 90%;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        font-size: 25px;
        line-height: 35px;
      }
    }
    svg {
      width: 20px;
      height: 20px;
      display: block;
      margin-left: auto;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        width: 24px;
        height: 24px;
      }
    }
  }
`;

export const query = graphql`
  fragment FooterLinksBlock on WordPressAcf_footer_links_block {
    links_list {
      title
      link
    }
  }
`;
