import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { useProjects } from "../../hooks/use-projects";
import { langGetProjectItems, translator } from "../../helpers/lang";
import { ProjectsItem } from "./ProjectsItem";

import { BREAKPOINTS } from "../../settings";

export const OurProjectsBlock = () => {
  const dataBase = useSelector(state => state.base);
  const langCurrent = dataBase.lang.current;
  const itemsAll = useProjects();

  const projects = langGetProjectItems(itemsAll, "projects", langCurrent);

  return (
    <StyledOurProjectsBlock className="block-container">
      <div className="block_title">
        <h2
          dangerouslySetInnerHTML={{
            __html: translator(langCurrent, {
              ru: "Наши проекты",
              en: "Our projects",
              kz: "Біздің жобаларымыз",
            }),
          }}
        />
      </div>

      <div className="projects_list">
        {projects &&
          projects.map((project, index) => (
            <ProjectsItem key={index} project={project} />
          ))}
      </div>
    </StyledOurProjectsBlock>
  );
};

const StyledOurProjectsBlock = styled.div`
  width: 100%;
  height: auto;
  .block_title {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #b6bec0;
    margin-bottom: 15px;
    padding-bottom: 15px;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
      padding-bottom: 0;
    }
    h2 {
      font-weight: bold;
      font-size: 30px;
      line-height: 40px;
      color: #040404;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        font-size: 30px;
        line-height: 45px;
      }
    }

    a {
      font-size: 16px;
      line-height: 25px;
      color: #040404;
      &:hover {
        img {
          right: -3px;
        }
      }
      img {
        height: 10px;
        display: inline-block;
        margin-left: 4px;
        position: relative;
        right: 0;
        transition: right 0.3s ease-in-out;
      }
    }
  }
  .projects_list {
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      margin: 0 -10px;
    }
    
    @media (min-width: ${BREAKPOINTS.desktop_lg + "px"}) {
      flex-wrap: nowrap;
    }
  }
`;
