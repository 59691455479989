import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { translator } from "../../../helpers/lang";
import { updateModalState } from "../../../store/actions/base";

export const InvestorsButton = ({ productType }) => {
  const dispatch = useDispatch();
  const dataBase = useSelector(state => state.base);
  const langCurrent = dataBase.lang.current;

  const buttonClick = () => {
    updateModalState(dispatch, { type: "shortForm", productType: productType });
  };

  return (
    <StyledInvestorsButton
      type="button"
      onClick={() => buttonClick()}
      className="button button__empty button__light"
    >
      {translator(langCurrent, {
        ru: "Начать инвестировать",
        en: "Start investing",
        kz: "Инвестициялауды бастаңыз",
      })}
    </StyledInvestorsButton>
  );
};

const StyledInvestorsButton = styled.button``;
