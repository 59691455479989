import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { useProjects } from "../../hooks/use-projects";
import { langGetProjectItems } from "../../helpers/lang";

import { AdvantagesSimplePart } from "../BlocksParts/AdvantagesSimplePart";

export const ProjectAboutBlock = ({ id }) => {
  const [project, setProject] = useState(null);
  const dataBase = useSelector(state => state.base);
  const langCurrent = dataBase.lang.current;
  const itemsAll = useProjects();

  useEffect(() => {
    if (langCurrent) {
      const projects = langGetProjectItems(itemsAll, "projects", langCurrent);
      setProject(projects.filter(item => item.wordpress_id === id)[0]);
    }
  }, [langCurrent]);

  return project?.acf.project_advantages.length>1 ? (
    <StyledProjectAboutBlock className="block-container">
      {project.acf.project_advantages && (
        <AdvantagesSimplePart items={project.acf.project_advantages} />
      )}
    </StyledProjectAboutBlock>
  ) : null;
};

const StyledProjectAboutBlock = styled.div`
  padding-top: 10px !important;
  overflow: auto;
`;
