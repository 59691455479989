import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import { BREAKPOINTS, COLORS, LAYOUT } from "../../settings";
import { SliderAdvantages } from "./SliderAdvantages";

export const AdvantagesBlock = ({ data }) => {
  const { advantages } = data;
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    setMobile(window.matchMedia("(max-width: 1023px)").matches);
  }, []);

  return (
    <StyledAdvantagesBlock className="advantages">
      <div className="block-container">
        {advantages && (
          <>
            {isMobile ? (
              <SliderAdvantages>
                {advantages.map((item, i) => (
                  <li key={i} className="advantages_item">
                    <img
                      src={item.illustration.localFile.publicURL}
                      className="advantages_illustration"
                      alt={item.title}
                    />
                    <h3 className="advantages_title">{item.title}</h3>
                    <p className="advantages_description">{item.description}</p>
                  </li>
                ))}
              </SliderAdvantages>
            ) : (
              <ul className="advantages_list">
                {advantages.map((item, i) => (
                  <li key={i} className="advantages_item">
                    <img
                      src={item.illustration.localFile.publicURL}
                      className="advantages_illustration"
                      alt={item.title}
                    />
                    <h3 className="advantages_title">{item.title}</h3>
                    <p className="advantages_description">{item.description}</p>
                  </li>
                ))}
              </ul>
            )}
          </>
        )}
      </div>
    </StyledAdvantagesBlock>
  );
};

const StyledAdvantagesBlock = styled.div`
  overflow: hidden;
  margin: -10px 0 0;

  @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
    margin: 10px 0 0;
  }

  .block-container {
    padding-top: 0;
  }

  .advantages_list {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .advantages_item {
    background: ${COLORS.bg_light};
    height: 100%;
    padding: 30px 20px;
    border-radius: 7px;

    flex: 1;

    @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
      height: auto;
    }

    > * {
      margin: 0;
    }

    .advantages_illustration {
      margin: 0 0 30px;
    }

    .advantages_title {
      font-size: 20px;
      line-height: 30px;
      font-weight: 700;
    }

    .advantages_description {
      margin: 5px 0 0;
      font-size: 16px;
      line-height: 25px;
    }

    + .advantages_item {
      margin: 0 0 0 ${LAYOUT.gap_desktop + "px"};
    }
  }
`;

export const query = graphql`
  fragment AdvantagesBlock on WordPressAcf_advantages_block {
    id
    advantages {
      title
      description
      illustration {
        localFile {
          publicURL
        }
      }
    }
  }
`;
