import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import { BREAKPOINTS } from '../../settings';

export const ProjectFinanceBlock = ({ data }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const openTab = (e) => setActiveIndex(+e.target.dataset.index);

    const subnav_img = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 12">
      <path d="M.42 11.017A1.448 1.448 0 010 10.005a1.432 1.432 0 01.42-1.012L8.994.42A1.421 1.421 0 0110.005 0a1.431 1.431 0 011.012.42l8.573 8.573a1.432 1.432 0 01-1.011 2.443 1.43 1.43 0 01-1.012-.42l-7.562-7.564-7.561 7.565a1.431 1.431 0 01-2.024 0z"/>
    </svg>
  `;

    return data ? (
        <StyledProjectFinanceBlock className="block-container">
            <div className="finance-block__wrap">
                <ul className="finance-block__general">
                    {data.general_data.map((item, i) => (
                        <li key={`adv_${i}`}>
                            <div
                                className="finance-block__general-value"
                                dangerouslySetInnerHTML={{ __html: item.value }}
                            />
                            <div
                                className="finance-block__general-desc"
                                dangerouslySetInnerHTML={{ __html: item.desc }}
                            />
                        </li>
                    ))}
                </ul>
                <div className="finance-block__data">
                    {data.financial_values.map((item, index) => (
                        <div key={'title_' + index} className={`control_item ${index === activeIndex ? 'active' : ''}`}>
                            <div className="finance-block__title-line">
                                <button
                                    className="tab"
                                    onClick={openTab}
                                    data-index={index}
                                    dangerouslySetInnerHTML={{ __html: item.title }}
                                />

                                <span dangerouslySetInnerHTML={{ __html: subnav_img }} />
                            </div>
                            <div
                                className="finance-block__value"
                                dangerouslySetInnerHTML={{
                                    __html: item.value,
                                }}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </StyledProjectFinanceBlock>
    ) : null;
};

const StyledProjectFinanceBlock = styled.div`
    &.block-container {
        padding-top: 0;
        padding-bottom: 0;
    }

    .finance-block__wrap {
        display: flex;
        flex-direction: column;

        @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
            flex-direction: row;
            justify-content: space-between;
            margin-left: auto;
        }

        @media (min-width: ${BREAKPOINTS.desktop + 'px'}) {
            width: calc(75% - 17.5px);
            margin-left: auto;
        }
    }

    .finance-block__data {
        height: auto;
        padding: 20px 20px;
        background-color: #2d3b4d;
        color: #fff;
        margin-left: -20px;
        margin-right: -20px;

        @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
            margin-left: 0;
            margin-right: 0;
            width: 50%;
            border-radius: 7px;
        }

        @media (min-width: ${BREAKPOINTS.desktop_lg + 'px'}) {
            padding: 30px 40px;
            width: 60%;
        }

        .control_item {
            width: 100%;
            height: auto;
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);

            &:last-child {
                border-bottom: 0;

                .finance-block__value {
                    padding-bottom: 10px;
                }
            }

            &.active {
                .finance-block__title-line {
                    svg {
                        transform: rotate(0deg);
                    }
                }
                .finance-block__value {
                    display: block;
                    border-top: 1px solid rgba(255, 255, 255, 0.3);
                }
            }
        }
    }

    .finance-block__title-line {
        width: 100%;
        height: auto;
        position: relative;
        padding: 10px 0;

        &:hover {
            button,
            svg {
                opacity: 0.8;
            }
        }

        &:last-child {
            border-bottom: none;
        }

        button {
            width: 100%;
            border: none;
            padding: 0;
            background-color: transparent;
            margin: 0;
            font-weight: bold;
            font-size: 20px;
            line-height: 1.25;
            color: #fff;
            outline: none;
            text-align: left;
            position: relative;
            z-index: 2;

            @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
                font-size: 20px;
                line-height: 1.45;
            }
            @media (min-width: ${BREAKPOINTS.desktop_lg + 'px'}) {
                font-size: 25px;
                line-height: 1.4;
            }
        }
        svg {
            position: absolute;
            width: 14px;
            height: auto;
            right: 0;
            top: 20px;
            fill: #fff;
            transform: rotate(180deg);
            z-index: 1;

            @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
                top: 25px;
            }
        }
    }

    .finance-block__value {
        font-weight: 700;
        font-size: 20px;
        line-height: 1.25;
        color: #ffffff;
        padding-top: 30px;
        padding-bottom: 40px;
        display: none;

        @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
            font-size: 25px;
            padding-top: 15px;
            padding-bottom: 30px;
        }
    }

    .finance-block__general {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 40px;
        padding: 0;

        @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
            flex-direction: column;
            justify-content: flex-start;
            width: 50%;
        }

        @media (min-width: ${BREAKPOINTS.desktop + 'px'}) {
            width: 40%;
        }

        li {
            padding: 0;
            margin: 0;
            margin-right: 20px;
            height: auto;
            list-style-type: none;

            @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
                margin-bottom: 35px;
            }

            .finance-block__general-value {
                font-weight: bold;
                font-size: 30px;
                line-height: 1.33;
                color: #0083e5;
                margin-bottom: 15px;
                white-space: nowrap;

                @media (min-width: ${BREAKPOINTS.desktop_lg + 'px'}) {
                    font-size: 40px;
                    line-height: 1;
                }
            }
            .finance-block__general-desc {
                font-size: 15px;
                line-height: 1.33;
                color: #0f1a20;
                opacity: 0.8;

                @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
                    font-size: 16px;
                    line-height: 1.55;
                }

                @media (min-width: ${BREAKPOINTS.desktop_lg + 'px'}) {
                    font-size: 20px;
                    line-height: 1.75;
                }
            }
        }
    }
`;

export const query = graphql`
    fragment ProjectFinanceBlock on WordPressAcf_finance_block {
        id
        general_data {
            desc
            value
        }
        financial_values {
            title
            value
        }
    }
`;
