// libraries
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { graphql } from 'gatsby';
import styled from 'styled-components';
// components
import { Alert } from './Alert';
import { BREAKPOINTS } from '../../settings';
// constants
import { API } from '../../settings';
// helpers
import { translator } from '../../helpers/lang';

export const ContactsCallbackFormBlock = ({ data }) => {
    const dataBase = useSelector((state) => state.base);
    const langCurrent = dataBase.lang.current;

    const [isMailSended, mailSended] = useState(false);
    const [alertDone, setAlertDone] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const [isCheckedName, setCheckedName] = useState(false);
    const [isCheckedPhone, setCheckedPhone] = useState(false);
    const [isCheckedMessage, setCheckedMessage] = useState(false);

    const senderUrl = API.SEND_CONTACT_PAGE_FORM;
    const mainForm = React.createRef();

    const callBackFormScroll = React.createRef();

    const checkPhone = (event) => {
        event.preventDefault();
        event.target.value = event.target.value.replace(/[^0-9()+\-)]/g, '');
    };
    const fileInput = React.createRef();
    const [filename, setFilename] = useState(null);
    const checkForm = (event, isEvent = true) => {
        let element;

        if (!isEvent) {
            element = event;
        } else {
            element = event.target;
        }

        switch (element.name) {
            case 'name':
                if (element.value.length > 1) {
                    element.parentNode.dataset.checked = true;
                    setCheckedName(true);
                } else {
                    element.parentNode.dataset.checked = false;
                    setCheckedName(false);
                }
                break;
            case 'phone':
                if (element.value.length > 6 && element.value.length < 20) {
                    element.parentNode.dataset.checked = true;
                    setCheckedPhone(true);
                } else {
                    element.parentNode.dataset.checked = false;
                    setCheckedPhone(false);
                }
                break;
            case 'message':
                if (element.value.length > 3) {
                    element.parentNode.dataset.checked = true;
                    setCheckedMessage(true);
                } else {
                    element.parentNode.dataset.checked = false;
                    setCheckedMessage(false);
                }
                break;
            case 'file':
                let file = event.target.files[0];

                if (!['image/jpeg', 'image/png'].includes(file.type)) {
                    alert('Разрешены только изображения (.jpg, .jpeg, .png).');
                    fileInput.current.value = '';
                    return;
                }

                if (file.size > 2 * 1024 * 1024) {
                    alert('Файл должен быть менее 2 МБ.');
                    fileInput.current.value = '';
                    return;
                }

                setFilename(fileInput.current.value.replace(/^.*[\\\/]/, ''));
                break;
        }
    };

    useEffect(() => {
        if (isCheckedName && isCheckedPhone && isCheckedMessage) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }, [isCheckedName, isCheckedPhone, isCheckedMessage]);

    const getRecaptchaToken = (event) => {
        event.preventDefault();
        typeof window !== 'undefined' &&
            window.grecaptcha.ready(async () => {
                const response = await window.grecaptcha.execute('6LdlFF0gAAAAAGN7qZCPcfABsf6NJSGADIIeGsqS', {
                    action: 'submit',
                });
                const token = await response;
                sendForm(token);
            });

        callBackFormScroll.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };

    const sendForm = (token) => {
        let formData = new FormData(mainForm.current);
        formData.append('recaptchaToken', token);

        setIsButtonDisabled(true);

        fetch(senderUrl, {
            method: 'post',
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                if (window.ym) {
                    window.ym(57564463, 'reachGoal', 'cont_feedback_leadform_send');
                }
                if (window.dataLayer) {
                    window.dataLayer.push({ event: 'cont_feedback_leadform_send' });
                }

                mailSended(true);

                if (data.status) {
                    setAlertDone(true);
                } else {
                    setAlertDone(false);
                }
            })
            .catch((err) => {
                setAlertDone(false);
            });
        setIsButtonDisabled(false);
    };

    const removeFile = () => {
        fileInput.current.value = '';
        setFilename(null);
    };

    return (
        <>
            {data && (
                <StyledContactsCallbackFormBlock className="block-container">
                    <div className="callBackFormScroll" ref={callBackFormScroll} />

                    <div className="callback_form_wrapper">
                        <div
                            className="title_part"
                            dangerouslySetInnerHTML={{
                                __html: data.ccf_title,
                            }}
                        />

                        {isMailSended ? (
                            <Alert alertDone={alertDone} langCurrent={langCurrent} />
                        ) : (
                            <form
                                id="callback_form"
                                className="form_wrapper"
                                ref={mainForm}
                                onSubmit={getRecaptchaToken}
                            >
                                <div className="input_wrapper" data-checked="">
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: translator(langCurrent, {
                                                ru: 'ФИО',
                                                en: 'Full name',
                                                kz: 'Аты-жөні',
                                            }),
                                        }}
                                    />
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        data-req={true}
                                        onChange={(event) => !checkForm(event)}
                                        placeholder={translator(langCurrent, {
                                            ru: 'Введите имя',
                                            en: 'Enter your name',
                                            kz: 'Атын енгізіңіз',
                                        })}
                                    />
                                </div>

                                <div className="input_wrapper" data-checked="">
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: translator(langCurrent, {
                                                ru: 'Телефон',
                                                en: 'Telephone',
                                                kz: 'Телефоны',
                                            }),
                                        }}
                                    />
                                    <input
                                        type="text"
                                        id="phone"
                                        name="phone"
                                        data-req={true}
                                        onChange={(event) => !checkForm(event)}
                                        placeholder="+7 (___) ___–__–__"
                                        onInput={checkPhone}
                                    />
                                </div>

                                <div className="input_wrapper" data-checked="">
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: translator(langCurrent, {
                                                ru: 'Сообщение',
                                                en: 'Message',
                                                kz: 'Хабарлама мәтіні',
                                            }),
                                        }}
                                    />
                                    <textarea
                                        id="message"
                                        name="message"
                                        data-req={true}
                                        onChange={(event) => !checkForm(event)}
                                        placeholder={translator(langCurrent, {
                                            ru: 'Напишите что-нибудь',
                                            en: 'Write something',
                                            kz: 'Мәтінді жазыңыз',
                                        })}
                                    />
                                </div>

                                <div className="input_file">
                                    <span
                                        className="filename"
                                        dangerouslySetInnerHTML={{
                                            __html: filename && filename,
                                        }}
                                    />

                                    {!filename ? (
                                        <label
                                            htmlFor="file"
                                            className="fileLabel"
                                            dangerouslySetInnerHTML={{
                                                __html: translator(langCurrent, {
                                                    ru: '+ Прикрепить файл',
                                                    en: '+ Attach file',
                                                    kz: '+ Файлды жүктеңіз',
                                                }),
                                            }}
                                        />
                                    ) : (
                                        <span
                                            className="fileLabel"
                                            dangerouslySetInnerHTML={{
                                                __html: translator(langCurrent, {
                                                    ru: 'Убрать файл',
                                                    en: 'Remove file',
                                                    kz: 'Файлды жою',
                                                }),
                                            }}
                                            onClick={removeFile}
                                        />
                                    )}
                                    <input
                                        id="file"
                                        type="file"
                                        name="file"
                                        ref={fileInput}
                                        onChange={(event) => !checkForm(event)}
                                    />
                                </div>

                                <div className="submit_line">
                                    <button
                                        className="button"
                                        type="submit"
                                        disabled={isButtonDisabled}
                                        dangerouslySetInnerHTML={{
                                            __html: translator(langCurrent, {
                                                ru: 'Отправить',
                                                en: 'Send',
                                                kz: 'Жіберу',
                                            }),
                                        }}
                                    />

                                    <div
                                        className="click_content"
                                        dangerouslySetInnerHTML={{
                                            __html: data.ccf_personal_data_text,
                                        }}
                                    />
                                </div>
                            </form>
                        )}
                    </div>
                </StyledContactsCallbackFormBlock>
            )}
        </>
    );
};

const StyledContactsCallbackFormBlock = styled.div`
    position: relative;
    overflow: hidden;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }

    .callBackFormScroll {
        width: 0;
        height: 0;
        position: absolute;
        top: -100px;
        left: 0;
    }

    .callback_form_wrapper {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        .title_part {
            width: 100%;
            font-weight: bold;
            font-size: 25px;
            line-height: 30px;
            color: #0f1a20;
            margin-bottom: 35px;

            @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
                width: 40%;
                margin-bottom: 0;
                font-size: 30px;
                line-height: 45px;
            }

            @media (min-width: ${BREAKPOINTS.desktop_lg + 'px'}) {
                width: 25%;
            }
        }

        .form_wrapper {
            width: 100%;
            height: auto;

            @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
                width: 50%;
            }

            .input_wrapper {
                width: 100%;
                height: auto;
                padding-bottom: 15px;
                border-bottom: 1px solid #898989;
                margin-bottom: 20px;
                &[data-checked='true'] {
                    border-bottom: 1px solid mediumspringgreen;
                }
                &[data-checked='false'] {
                    border-bottom: 1px solid red;
                    span {
                        color: red;
                    }
                }
                @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
                    margin-bottom: 30px;
                    padding-bottom: 20px;
                }
                span {
                    width: 100%;
                    height: auto;
                    font-size: 15px;
                    line-height: 25px;
                    color: #898989;

                    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
                        font-size: 16px;
                        margin-bottom: 5px;
                    }
                }
                input,
                textarea {
                    width: 100%;
                    height: auto;
                    color: #898989;
                    font-size: 20px;
                    line-height: 30px;
                    outline: none;
                    border: none;
                    padding: 0;
                    margin: 0;
                    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
                        font-size: 30px;
                        line-height: 45px;
                    }
                }
                textarea {
                    height: 60px;
                    resize: none;
                    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
                        height: 120px;
                    }
                }
            }

            .input_file {
                width: 100%;
                height: auto;
                margin-bottom: 30px;
                position: relative;
                display: flex;
                flex-direction: row;
                align-items: center;
                @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
                    padding-bottom: 20px;
                }
                .filename {
                    font-size: 16px;
                    line-height: 25px;
                }

                .fileLabel {
                    font-size: 16px;
                    line-height: 25px;
                    color: #0083e5;
                    cursor: pointer;
                    margin-bottom: 0;
                }

                span.fileLabel {
                    margin-left: 30px;
                }

                input {
                    position: absolute;
                    width: 0;
                    height: 0;
                    top: 0;
                    left: 0;
                    transform: scale(0);
                }
            }

            .submit_line {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                .button {
                    height: 55px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 16px;
                    line-height: 25px;
                    color: #0083e5;
                    border-color: #0083e5;
                    background: transparent;
                    margin-bottom: 35px;
                    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
                        margin-bottom: 0;
                    }
                    &:disabled {
                        color: #96d2ff;
                        border-color: #96d2ff;
                        &:hover {
                            background: transparent;
                            color: #96d2ff;
                        }
                    }
                    &:hover {
                        background: #0083e5;
                        color: #fff;
                    }
                }

                .click_content {
                    width: 100%;
                    height: auto;
                    font-size: 14px;
                    line-height: 20px;
                    color: #898989;
                    p {
                        margin-bottom: 0;
                    }
                    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
                        width: 50%;
                        margin-left: 40px;
                    }
                    a {
                        text-decoration: underline;
                        color: #898989;
                    }
                }
            }
        }
    }
`;

export const query = graphql`
    fragment ContactsCallbackFormBlock on WordPressAcf_contacts_callback_form_block {
        ccf_title
        ccf_personal_data_text
    }
`;
