// libraries
import React from 'react';
import styled from 'styled-components';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/dist/css/splide-core.min.css';
// components
import { SliderPagination } from '../UI/SliderPagination';
// constants
import { BREAKPOINTS, COLORS } from '../../settings';

export const EmployeeHistoryBlock = ({ data }) => {
    const { title, employee } = data;

    const sliderOptions = {
        arrows: false,
        type: 'loop',
        fixedWidth: '290px',
        gap: 40,
        mediaQuery: 'min',
        breakpoints: {
            [BREAKPOINTS.tablet]: {
                destroy: true,
            },
        },
    };

    return (
        <StyledEmployeeHistoryBlock className="block-container">
            <StyledTitle
                dangerouslySetInnerHTML={{
                    __html: title,
                }}
            />

            <StyledMobileSlider hasTrack={false} options={sliderOptions} aria-label="principles">
                <SplideTrack>
                    {employee.map(({ title, text, image }, index) => (
                        <SplideSlide key={index}>
                            <StyledEmployeeCard>
                                <StyledEmployeeImage src={image.localFile.publicURL} alt={title} />
                                <StyledEmployeeTitle dangerouslySetInnerHTML={{ __html: title }} />
                                <StyledEmployeeText dangerouslySetInnerHTML={{ __html: text }} />
                            </StyledEmployeeCard>
                        </SplideSlide>
                    ))}
                </SplideTrack>
                <SliderPagination />
            </StyledMobileSlider>
            <StyledDesktopGrid>
                {employee.map(({ title, text, image }, index) => (
                    <StyledEmployeeCard key={index}>
                        <StyledEmployeeImage src={image.localFile.publicURL} alt={title} />
                        <StyledEmployeeTitle dangerouslySetInnerHTML={{ __html: title }} />
                        <StyledEmployeeText dangerouslySetInnerHTML={{ __html: text }} />
                    </StyledEmployeeCard>
                ))}
            </StyledDesktopGrid>
        </StyledEmployeeHistoryBlock>
    );
};

const StyledEmployeeHistoryBlock = styled.section`
    position: relative;
    z-index: 1;
`;

const StyledTitle = styled.h2`
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 28px;
    line-height: 1.3;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        margin-bottom: 64px;
        font-size: 46px;
        text-align: center;
    }

    span {
        color: ${COLORS.text_accent};
    }
`;

const StyledMobileSlider = styled(Splide)`
    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        display: none;
    }

    .splide__track {
        margin: 0;
    }

    .splide__slide {
        display: flex;
        flex-direction: column;
    }

    .splide__slide__row {
        flex-grow: 1;
        height: initial !important;
    }
`;

const StyledDesktopGrid = styled.div`
    display: none;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0px;

        @media (min-width: ${BREAKPOINTS.desktop + 'px'}) {
            margin: 0 -35px;
        }
    }
`;

const StyledEmployeeCard = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 280px;
    margin: 0 auto;

    @media (min-width: ${BREAKPOINTS.desktop + 'px'}) {
        margin: 0 18px;
    }
`;

const StyledEmployeeImage = styled.img`
    border-radius: 7px;
    margin-bottom: 20px;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        margin-bottom: 30px;
    }
`;

const StyledEmployeeTitle = styled.h2`
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.25;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        margin-bottom: 12px;
        font-size: 25px;
        line-height: 1.4;
    }
`;

const StyledEmployeeText = styled.div`
    font-weight: 400;
    font-size: 15px;
    line-height: 1.3;
    color: ${COLORS.text_secondary};

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        font-size: 16px;
        line-height: 1.6;
    }
`;

export const query = graphql`
    fragment EmployeeHistoryBlock on WordPressAcf_employee_history_block {
        title
        employee {
            title
            text
            image {
                localFile {
                    publicURL
                }
            }
        }
    }
`;
