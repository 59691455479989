import React, { useState } from "react";
import styled from "styled-components";

import { COLORS } from "../../../settings";
import { FilesGroupTitle } from "../FilesGroupTitle";
import { FilesGroupList } from "../FilesGroupList";

export const FilesGroup = ({ item, index }) => {
  const [isOpened, setIsOpened] = useState(index === 0);

  const openedSate = {
    isOpened: isOpened,
    setIsOpened: setIsOpened,
  };

  return (
    <StyledFilesGroup>
      <FilesGroupTitle item={item} openedState={openedSate} />
      <FilesGroupList item={item} openedState={openedSate} />
    </StyledFilesGroup>
  );
};

const StyledFilesGroup = styled.div`
  position: relative;
  border-top: 1px solid ${COLORS.lines};

  &:last-child {
    border-bottom: 1px solid ${COLORS.lines};
  }
`;
