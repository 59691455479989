import React from "react";
import styled from "styled-components";

import { BREAKPOINTS, COLORS } from "../../../settings";
import { ButtonDownload } from "../../BlocksParts/ButtonDownload";
import { VideoButton } from "../../BlocksParts/VideoButton";

export const FilesGroupList = ({ item, openedState }) => {
  const { files } = item;
  const { isOpened } = openedState;

  return files ? (
    <StyledFilesList className="side-panel" opened={isOpened}>
      <StyledList>
        {files.map((file_group, i) => {
          const { file_name, file_variants } = file_group;

          return (
            <StyledItem key={`file_group_${i}`}>
              <StyledItemTitle
                dangerouslySetInnerHTML={{ __html: file_name }}
              />

              {file_variants && (
                <StyledItemLinks>
                  {file_variants.map((file, i) => {
                    const {
                      file_variant: { url, title }, youtube
                    } = file;

                    return youtube ? (
                      <VideoButton
                        key={`file_${i}`}
                        title={title}
                        link={url}
                      />
                    ) :
                      <ButtonDownload
                        key={`file_${i}`}
                        title={title}
                        link={url}
                      />
                    ;
                  })}
                </StyledItemLinks>
              )}
            </StyledItem>
          );
        })}
      </StyledList>
    </StyledFilesList>
  ) : null;
};

const StyledFilesList = styled.div`
  position: relative;
  z-index: 5;
  display: ${props => (props.opened ? "block" : "none")};
`;

const StyledList = styled.ul`
  padding: 0;
  margin: -25px 0 0;
  list-style: none;

  @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
    padding-right: 100px;
    margin: -60px 0 0;
  }
`;

const StyledItem = styled.li`
  padding: 25px 0;

  @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
    padding: 30px 0;
  }

  & + & {
    border-top: 1px solid ${COLORS.lines};
  }
`;

const StyledItemTitle = styled.h3`
  margin: 0;
  font-size: 18px;
  line-height: 1.4;

  @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
    font-size: 25px;
  }
`;

const StyledItemLinks = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0 0;

  @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
    margin: 15px 0 0;
  }

  * + * {
    margin: 0 0 0 40px;
  }
`;
