import React from "react";
import styled from "styled-components";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { BREAKPOINTS } from "../../../../settings";

export const BondsGraph = ({ symbol, graph_list }) => {
  const domain_min = Math.min.apply(
    Math,
    graph_list.map(function (o) {
      return o.gr_value;
    })
  );
  const domain_max = Math.max.apply(
    Math,
    graph_list.map(function (o) {
      return o.gr_value;
    })
  );

  return (
    <StyledBondsGraph>
      <ResponsiveContainer>
        <AreaChart data={graph_list}>
          <CartesianGrid stroke="#DADDE0" vertical={false} horizontal={false} />

          <XAxis dataKey="gr_tick" tickMargin={30} interval={0} hide={true} />

          <YAxis
            type="number"
            domain={[dataMin => domain_min, dataMax => domain_max]}
            hide={true}
            dataKey="gr_value"
          />

          <Tooltip
            separator=""
            wrapperStyle={{ width: "auto", height: "35px", padding: "0" }}
            labelStyle={{ display: "none", padding: "0" }}
            contentStyle={{
              backgroundColor: "#F3F3F3",
              border: "none",
              padding: "0 10px",
            }}
            itemStyle={{ color: "#0F1A20", fontSize: "16px" }}
            formatter={value => [value + ` ${symbol}`, ""]}
            isAnimationActive={false}
          />

          <Area
            type="monotone"
            dataKey="gr_value"
            activeDot={{
              stroke: "#0083E5",
              strokeWidth: 4,
              fill: "#fff",
              r: 6,
            }}
            stroke="#0083E5"
            strokeWidth="4"
            fill="rgba(0, 131, 229, .3)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </StyledBondsGraph>
  );
};

const StyledBondsGraph = styled.div`
  width: calc(100% + 40px);
  height: 130px;
  margin-bottom: 30px;
  position: relative;
  left: -20px;
  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
    width: 100%;
  }
  .recharts-responsive-container {
    width: 100%;
    .recharts-wrapper {
      .recharts-surface {
        overflow: visible;
        .recharts-xAxis {
          line {
            display: none;
          }
          .recharts-text {
            font-size: 16px;
            color: #898989;
            position: relative;
          }
        }
      }
    }
  }
`;
