// libraries
import React from 'react';
import styled from 'styled-components';
// constants
import { BREAKPOINTS, COLORS } from '../../../settings';

export const ShortInfoItem = ({ icon, name, description }) => {
    return (
        <StyledShortInfoItem>
            <img src={icon.localFile.publicURL} alt="" aria-hidden />
            <h3 dangerouslySetInnerHTML={{ __html: name }} />
            <div dangerouslySetInnerHTML={{ __html: description }} />
        </StyledShortInfoItem>
    );
};

const StyledShortInfoItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    padding: 30px 20px;
    background: #fff;
    border-radius: 7px;
        
    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        width: calc((100% / 2) - 24px);
        min-height: 310px;
    }

    @media (min-width: ${BREAKPOINTS.desktop + 'px'}) {
        width: calc((100% / 3) - 24px);
    }

    img {
        margin-bottom: 25px;

        @media (min-width: ${BREAKPOINTS.desktop + 'px'}) {
            margin-bottom: 30px;
        }
    }

    h3 {
        margin: 0;
        margin-bottom: 5px;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        color: ${COLORS.text_accent};
    }

    p {
        margin: 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.8;
        color: ${COLORS.text};
        opacity: 0.8;

        @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
            line-height: 1.55;
        }

        @media (min-width: ${BREAKPOINTS.desktop_lg + 'px'}) {
            font-size: 16px;
        }
    }

    ul {
        padding-left: 25px;

        li {
            font-weight: 400;
            font-size: 16px;
            line-height: 1.6;
            text-align: left;
        }
    }
`;
