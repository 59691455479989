import React from "react";
import styled from "styled-components";

import { BREAKPOINTS } from "../../../settings";

export const MainImage = ({ source_url }) => {
  return (
    <StyledMainImage
      style={{
        backgroundImage: `url(${source_url})`,
      }}
    />
  );
};

const StyledMainImage = styled.div`
  width: 100%;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 40px 0 0;
  overflow: hidden;
  border-radius: 7px;
  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
    height: 400px;
  }
`;
