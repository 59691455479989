import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { translator } from "../../../helpers/lang";
import { updateModalState } from "../../../store/actions/base";
import { BREAKPOINTS } from "../../../settings";
import { IconArrow } from "../../Icons/IconArrow";

export const ProductsButton = ({ productType }) => {
  const dispatch = useDispatch();
  const dataBase = useSelector(state => state.base);
  const langCurrent = dataBase.lang.current;

  const buttonClick = () => {
    updateModalState(dispatch, { type: "shortForm", productType: productType });
  };

  return (
    <StyledProductsButton>
      <div className="text-button-below">
        <div className="text-button-below_text">
          {translator(langCurrent, {
            ru: "Остались вопросы?",
            en: "Still have questions?",
            kz: "Әлі де сұрақтарыңыз бар ма?",
          })}
        </div>
        <button
          onClick={() => buttonClick()}
          className="button button__with-arrow"
        >
          <span>
            {translator(langCurrent, {
              ru: "Получить консультацию",
              en: "Get a consultation",
              kz: "Консультация алу үшін",
            })}
            <IconArrow size={20} />
          </span>
        </button>
      </div>
    </StyledProductsButton>
  );
};

const StyledProductsButton = styled.div`
  .text-button-below {
    margin-top: 20px;
    padding: 0 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    @media (min-width: ${BREAKPOINTS.mobile}px) {
      justify-content: center;
    }
  }
  .text-button-below_text {
    margin: 15px 30px 15px 0;
  }
`;
