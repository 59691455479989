import React, { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Link } from "gatsby";
import { useNewsList } from "../../hooks/use-news-list";
import { translator } from "../../helpers/lang";

import { BREAKPOINTS, SETTINGS } from "../../settings";

export const MediaNewsListBlock = () => {
  const dataBase = useSelector(state => state.base);
  const langCurrent = dataBase.lang.current;
  const news_list = useNewsList(langCurrent);

  const news_to_show = 7;
  const [newsToShow, setNewsToShow] = useState(news_to_show);

  function getSortedNews(news_list, news_to_show = 7) {
    if (!news_list) {
      return false;
    }

    let returned_list = [];

    returned_list.is_show_more = news_list.length > news_to_show;
    returned_list.news_list = news_list.slice(0, news_to_show);

    return returned_list;
  }
  const sorted_news = getSortedNews(news_list, newsToShow);

  return (
    <StyledMediaNewsListBlock className="block-container">
      <div className="block_title">
        <h2
          dangerouslySetInnerHTML={{
            __html: translator(langCurrent, {
              ru: "Новости",
              en: "News",
              kz: "Жаңалықтары",
            }),
          }}
        />
      </div>

      {sorted_news ? (
        <div className="news_list">
          {sorted_news.news_list &&
            sorted_news.news_list.map((news, index) => (
              <div key={index} className="news_wrapper">
                <div className="news_info">
                  <div
                    className="date"
                    dangerouslySetInnerHTML={{
                      __html: news.date,
                    }}
                  />
                </div>
                <div
                  className={`title_wrapper ${
                    (news.acf && news.acf.nsc_image) ||
                    (news.featured_media &&
                      news.featured_media.localFile.publicURL)
                      ? "has_image"
                      : ""
                  }`}
                >
                  <Link
                    to={news.path.replace("/wp/", "/")}
                    className="title"
                    dangerouslySetInnerHTML={{ __html: news.title }}
                  />

                  {((news.acf &&
                    news.acf.nsc_image &&
                    news.acf.nsc_image.source_url.replace(
                      SETTINGS.URL_MASTER,
                      ""
                    )) ||
                    (news.featured_media &&
                      news.featured_media.localFile.publicURL)) && (
                    <div
                      className="image"
                      style={{
                        backgroundImage: `url(
                            ${
                              (news.acf &&
                                news.acf.nsc_image &&
                                news.acf.nsc_image.source_url.replace(
                                  SETTINGS.URL_MASTER,
                                  ""
                                )) ||
                              (news.featured_media &&
                                news.featured_media.localFile.publicURL)
                            }
                            )`,
                      }}
                    />
                  )}
                </div>
              </div>
            ))}
        </div>
      ) : (
        <div
          className="empty_wrapper"
          dangerouslySetInnerHTML={{
            __html: translator(langCurrent, {
              ru: "В данной категории новостей нет.",
              en: "There is no news in this category.",
              kz: "Бұл категорияда жаңалық жоқ",
            }),
          }}
        />
      )}

      {sorted_news.is_show_more ? (
        <div
          className="button"
          dangerouslySetInnerHTML={{
            __html: translator(langCurrent, {
              ru: "Загрузить еще",
              en: "Load more",
              kz: "Көбірек жүктеңіз",
            }),
          }}
          onClick={() => setNewsToShow(newsToShow + news_to_show)}
        />
      ) : null}
    </StyledMediaNewsListBlock>
  );
};

const StyledMediaNewsListBlock = styled.div`
  width: 100%;
  height: auto;

  .block_title {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #b6bec0;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    h2 {
      font-weight: bold;
      font-size: 25px;
      line-height: 30px;
      color: #040404;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        font-size: 30px;
        line-height: 45px;
      }
    }
  }

  .news_list {
    width: 100%;
    height: auto;

    .news_wrapper {
      width: 100%;
      padding: 20px 0;
      border-bottom: 1px solid #898989;
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      flex-wrap: wrap;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        flex-direction: row;
      }

      .news_info {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          width: 25%;
          flex-direction: column;
        }

        .date {
          font-size: 15px;
          line-height: 20px;
          color: #898989;
          @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
            font-size: 16px;
            line-height: 25px;
          }
        }
      }

      .title_wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          width: 70%;
        }
        &.has_image {
          flex-direction: column-reverse;
          @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
            flex-direction: row;
          }
          .title {
            width: 100%;
            @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
              width: calc(100% - 215px);
            }
          }
        }

        .title {
          font-size: 18px;
          line-height: 25px;
          color: #040404;
          width: 100%;
          margin-bottom: 5px;
          @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
            font-size: 25px;
            line-height: 35px;
            margin-bottom: 0;
          }
          &:hover {
            color: #0083e5;
            text-decoration: none;
          }
        }

        .image {
          width: 100%;
          height: 200px;
          border-radius: 7px;
          margin: 0 0 15px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
            width: 180px;
            height: 115px;
            margin: 0 0 0 auto;
          }
        }
      }
    }
  }

  .button {
    background-color: transparent;
    color: #0083e5;
    cursor: pointer;
    margin: 40px auto 0;
    display: block;
    width: fit-content;
    max-width: 300px;
    &:hover {
      background-color: #0083e5;
      color: #fff;
    }
  }
`;
