import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import { useSelector } from "react-redux";
import { translator } from "../../helpers/lang";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { BREAKPOINTS } from "../../settings";

export const TechnologyOurTeamsBlock = ({ data }) => {
  const dataBase = useSelector(state => state.base);
  const langCurrent = dataBase.lang.current;

  const tecnology_our_team_list = data.tecnology_our_team_list;

  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    setMobile(window.matchMedia("(max-width: 768px)").matches);
  }, []);

  const settings = {
    arrows: false,
    focusOnSelect: true,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 1.2,
    centerMode: false,
  };

  return (
    <>
      {tecnology_our_team_list && (
        <StyledTechnologyOurTeamsBlock className="block-container">
          <div className="title_block">
            <h2
              dangerouslySetInnerHTML={{
                __html: translator(langCurrent, {
                  ru: "Наши команды",
                  en: "Our Teams",
                  kz: "Біздің командалар",
                }),
              }}
            />
          </div>

          {isMobile ? (
            <Slider {...settings} className="items_list">
              {tecnology_our_team_list.map((item, index) => (
                <div key={index} className="item">
                  <div
                    className="title"
                    dangerouslySetInnerHTML={{
                      __html: item.to_title,
                    }}
                  />

                  <div
                    className="counter"
                    dangerouslySetInnerHTML={{
                      __html: item.to_counter,
                    }}
                  />

                  <div className="image">
                    <img
                      src={item.to_image && item.to_image.localFile.publicURL}
                      alt={item.to_title}
                    />
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            <div className="items_list">
              {tecnology_our_team_list.map((item, index) => (
                <div key={index} className="item">
                  <div
                    className="title"
                    dangerouslySetInnerHTML={{
                      __html: item.to_title,
                    }}
                  />

                  <div
                    className="counter"
                    dangerouslySetInnerHTML={{
                      __html: item.to_counter,
                    }}
                  />

                  <div className="image">
                    <img
                      src={item.to_image && item.to_image.localFile.publicURL}
                      alt={item.to_title}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </StyledTechnologyOurTeamsBlock>
      )}
    </>
  );
};

const StyledTechnologyOurTeamsBlock = styled.div`
  .title_block {
    width: 100%;
    height: auto;
    padding-bottom: 10px;
    margin-bottom: 30px;
    border-bottom: 1px solid #b6bec0;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      margin-bottom: 50px;
    }
    h2 {
      font-weight: bold;
      font-size: 25px;
      line-height: 30px;
      color: #0f1a20;
      margin-bottom: 0;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        font-size: 30px;
        line-height: 45px;
      }
    }
  }

  .items_list {
    position: relative;
    width: 100%;
    right: 0;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      width: calc(75% + 2%);
      margin-left: auto;
      right: -2%;
    }
    @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
      width: calc(75% + 2.33%);
      right: -2.33%;
    }
    .item {
      width: 31%;
      margin-right: 2.33%;
      min-height: 170px;
      background: #f6f8fa;
      border-radius: 7px;
      padding: 30px 20px 120px;
      margin-bottom: 0;
      position: relative;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        margin-bottom: 30px;
        width: 48%;
        margin-right: 2%;
      }
      @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
        width: 31%;
        margin-right: 2.33%;
        margin-bottom: 50px;
      }
      .title {
        font-weight: bold;
        font-size: 20px;
        line-height: 25px;
        color: #040404;
      }
      .counter {
        position: absolute;
        bottom: 30px;
        left: 20px;
        font-weight: bold;
        font-size: 40px;
        line-height: 40px;
        color: #0083e5;
      }
      .image {
        position: absolute;
        bottom: 30px;
        right: 20px;
        img {
          width: auto;
          height: auto;
          max-width: 70px;
          max-height: 70px;
        }
      }
    }
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    height: auto;
    > div {
      position: relative;
      margin-left: 35px;
      left: -35px;
      height: 100%;
      .item {
        height: 100%;
      }
    }
  }
`;

export const query = graphql`
  fragment TechnologyOurTeamsBlock on WordPressAcf_technology_our_teams_block {
    tecnology_our_team_list {
      to_counter
      to_title
      to_image {
        localFile {
          publicURL
        }
      }
    }
  }
`;
