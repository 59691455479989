import React, { useState } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { COLORS, BREAKPOINTS, LAYOUT } from "../../settings";
import { ProductsExtendedItemTitle } from "./ProductsExtendedItemTitle";
import { ProductsExtendedItemSubtitle } from "./ProductsExtendedItemSubtitle";
import { ProductsExtendedItemInfo } from "./ProductsExtendedItemInfo";
import { ProductsExtendedItemDescription } from "./ProductsExtendedItemDescription";
import { ProductsExtendedItemLink } from "./ProductsExtendedItemLink";
import { ProductsExtendedRegionsTabs } from "./ProductsExtendedRegionsTabs";
import { ProductsExtendedRegionsContent } from "./ProductsExtendedRegionsContent";
import { ButtonManager } from "../ButtonManager";
import { InvestorsButton } from "../ShortFormButtons/InvestorsButton";

export const ProductsExtendedBlock = ({ data }) => {
  const dispatch = useDispatch();
  const dataBase = useSelector(state => state.base);
  const langCurrent = dataBase.lang.current;
  const isOldUser = dataBase.managerData;
  const { loan_investment_ext, bonds_investment_ext } = data;
  const [activeTab, setActiveTab] = useState(
    bonds_investment_ext.regions[0].region
  );

  const scheme = [
    // {
    //   productType: "loans",
    //   title: <ProductsExtendedItemTitle title={loan_investment_ext.title} />,
    //   subtitle: (
    //     <ProductsExtendedItemSubtitle subtitle={loan_investment_ext.subtitle} />
    //   ),
    //   info: <ProductsExtendedItemInfo info={loan_investment_ext.info} />,
    //   description: (
    //     <ProductsExtendedItemDescription
    //       description={loan_investment_ext.description}
    //     />
    //   ),
    //   link: <ProductsExtendedItemLink link={loan_investment_ext.link} />,
    // },
    {
      productType: "bonds",
      title: <ProductsExtendedItemTitle title={bonds_investment_ext.title} />,
      subtitle: (
        <ProductsExtendedRegionsTabs
          data={{
            regions: bonds_investment_ext.regions,
            activeTab: activeTab,
            setActiveTab: setActiveTab,
          }}
        />
      ),
      info: (
        <ProductsExtendedRegionsContent
          data={{
            regions: bonds_investment_ext.regions,
            activeTab: activeTab,
          }}
        />
      ),
      description: (
        <ProductsExtendedItemDescription
          description={bonds_investment_ext.description}
        />
      ),
      link: <ProductsExtendedItemLink link={bonds_investment_ext.link} />,
    },
  ];

  return scheme ? (
    <StyledWrapper id="invest-products" className="block-container">
      {scheme.map((panel, index) => (
        <StyledPanel key={index}>
          <StyledHeading>
            {panel.title}
            {/*<StyledSubtitle>{panel.subtitle}</StyledSubtitle>*/}
          </StyledHeading>
          <StyledInfo>
            {panel.info}
            {panel.description}
            <div className="product_bottom">
              {panel.link}
              {isOldUser ? (
                <ButtonManager
                  type={panel.productType}
                  dataType="short"
                  pageId={null}
                />
              ) : (
                <InvestorsButton productType={panel.productType} />
              )}
            </div>
            <p className="invest-products__note" 
              dangerouslySetInnerHTML={{
              __html: data.note,
              }}
            />
          </StyledInfo>
        </StyledPanel>
      ))}
    </StyledWrapper>
  ) : null;
};

const StyledWrapper = styled.div``;

const StyledPanel = styled.div`
  padding: 40px 0;
  border-top: 1px solid ${COLORS.lines};

  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
    display: flex;
  }

  &:last-child {
    border-bottom: 1px solid ${COLORS.lines};
  }
`;

const StyledHeading = styled.div`
  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
    width: calc(100% / 3);
    padding: 0 ${LAYOUT.gap_tablet / 2 + "px"} 0 0;

    flex-grow: 0;
    flex-shrink: 0;
  }

  @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
    width: 50%;
    padding: 0 ${LAYOUT.gap_desktop / 2 + "px"} 0 0;
  }
`;

const StyledInfo = styled.div`
  padding: ${LAYOUT.gap + "px"} 0 0;

  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
    width: calc(100% / 3 * 2);
    padding: 0 0 0 ${LAYOUT.gap_tablet / 2 + "px"};

    flex-grow: 0;
    flex-shrink: 0;
  }

  @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
    width: 50%;
    padding: 0 0 0 ${LAYOUT.gap_desktop / 2 + "px"};
  }

  .product_bottom {
    > .button {
      margin: 20px 20px 0 0;
    }
  }

  .invest-products__note {
    margin-top: 10px;
    font-size: 10px;
    font-weight: 400;
    line-height: 1.5;
    color: #898989;

    @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
      margin-top: 20px;
    }
  }
`;

const StyledSubtitle = styled.div`
  display: inline-block;
  margin: 15px 0 0;

  @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
    margin: 20px 0 0;
  }
`;

export const query = graphql`
  fragment ProductsExtendedBlock on WordPressAcf_products_extended_block {
    id
    loan_investment_ext {
      title
      subtitle
      link
      info {
        data
        description
      }
      description
    }
    bonds_investment_ext {
      title
      link
      regions {
        region
        info {
          data
          description
        }
      }
      description
    }
    note
  }
`;
