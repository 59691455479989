import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { updateModalState } from "../../store/actions/base";
import { IconArrow } from "../Icons/IconArrow";
import { IconSendTrue } from "../Icons/IconSendTrue";
import { translator } from "../../helpers/lang";
import { getCookie } from "../../helpers/cookies";
import { calcTypeData } from "../../helpers/calcTypeData";
import { API } from "../../settings";

const sendEvents = (type, pageId) => {
  switch (type) {
    case "loans":
      if (window.ym) {
        window.ym(67027090, "reachGoal", "start_invest");
        window.ym(67027090, "reachGoal", "all_open_form");

        window.ym(57564463, "reachGoal", "loan_calc_leadform_сlick");
      }
      if (window.dataLayer) {
        window.dataLayer.push({ event: "loan_calc_leadform_сlick" });
      }
      break;
    case "bonds":
      if (window.ym) {
        window.ym(57564463, "reachGoal", "bond_calc_leadform_сlick");
      }
      if (window.dataLayer) {
        window.dataLayer.push({ event: "bond_calc_leadform_сlick" });
      }
      break;
    default:
      break;
  }
};

const btnTranslater = {
  fillApp: {
    ru: "Заполнить заявку",
    en: "Fill in the application",
    kz: "Өтінімді толтыру",
  },
  contactManager: {
    ru: "Связаться с менеджером",
    en: "Contact the manager",
    kz: "Менеджерге хабарласыңыз",
  },
  wait: {
    ru: "Подождите, идет отправка",
    en: "Wait, sending is in progress",
    kz: "Күтіңіз, жеткізу жүріп жатыр",
  },
  accepted: {
    ru: "Спасибо! Ваша заявка принята",
    en: "Thanks! Your request is accepted",
    kz: "Рақмет! Сіздің өтініміңіз қабылданды",
  },
};

export const ButtonManager = ({ type, dataType, pageId }) => {
  const dataBase = useSelector(state => state.base);
  const dataCalcLoans = useSelector(state => state.calcLoans);
  const dataCalcBonds = useSelector(state => state.calcBonds);
  const langCurrent = dataBase.lang.current;

  const newCqPageId = window.location.href.toString();

  const lastSessionReferrer =
      dataBase.oldUserData.data?.props?.$last_session_referrer, //Последняя сессия
    oldUserName = dataBase.oldUserData.data?.props?.$name, //$name
    oldUserPhone = dataBase.oldUserData.data?.props?.$phone, //$phone
    oldUserEmail = dataBase.oldUserData.data?.props?.$email, //$email
    oldVisitorIp = dataBase.oldUserData.data?.props_custom["visitor ip"]; //visitor ip

  const [
    currentVal,
    currentTerm,
    currentPercent,
    currentCurrency,
    currentDescription,
    currentProduct,
    currentCqFormId,
    currentCqButtonId,
    currentCqPageId,
  ] = calcTypeData(type, dataType, dataCalcLoans, dataCalcBonds, langCurrent);

  const [isDisabled, setDisabled] = useState(false);
  const [sendCorrect, setsendCorrect] = useState(false);

  const sendManager = () => {
    const requestTo =
      dataType === "short"
        ? API.SEND_SECOND_SHORT_REQUEST
        : API.SEND_MANAGER_REQUEST;

    sendEvents(type, pageId);
    let data = {
      cqUser:
        typeof window !== `undefined` &&
        window.document &&
        getCookie("carrotquest_uid"),
      oldVisitorIp: oldVisitorIp,
      oldUserName: oldUserName,
      oldUserPhone: oldUserPhone,
      oldUserEmail: oldUserEmail,
      lastSessionReferrer: lastSessionReferrer,
      newCqPageId: newCqPageId,
      newcCqFormId: currentCqFormId,
      newcCqButtonId: currentCqButtonId,
      newProduct: currentProduct,
      newCurrentVal: currentVal,
      newCurrentTerm: currentTerm,
      newCurrentCurrency: currentCurrency && currentCurrency.toUpperCase(),
      newCurrentDescription: currentDescription,
      NewCurrentCqPageId: currentCqPageId,
      percent: currentPercent,
      partner:
        typeof window !== `undefined` &&
        window.document &&
        (getCookie("partner") || "-"),
      utms:
        typeof window !== `undefined` &&
        window.document &&
        (getCookie("utms") || ""),
      wmid:
        typeof window !== `undefined` &&
        window.document &&
        (getCookie("wmid") || ""),
      clickid:
        typeof window !== `undefined` &&
        window.document &&
        (getCookie("clickid") || ""),
      lang: dataBase.lang.current.toUpperCase(),
      ip: dataBase.ip,
      url: typeof window !== `undefined` && window.location.href,
      type: type,
    };

    fetch(requestTo, {
      ...API.HEADERS.POST,
      body: JSON.stringify({ ...data }),
    })
      .then(setDisabled(true))
      .then(res => {
        if (res.ok) {
          setsendCorrect(true);
          // setDisabled(false);
          setTimeout(() => {
            setDisabled(false);
            setsendCorrect(false);
          }, 10000);
        }
      });
  };

  const generateTranslateContent = () => {
    if (!isDisabled && !sendCorrect) {
      return translator(langCurrent, btnTranslater.contactManager);
    }
    if (isDisabled && !sendCorrect) {
      return translator(langCurrent, btnTranslater.wait);
    }
    if (isDisabled && sendCorrect) {
      return translator(langCurrent, btnTranslater.accepted);
    }
    return translator(langCurrent, btnTranslater.contactManager);
  };

  return (
    <StyledButtonManager
      onClick={() => sendManager()}
      className="button button__with-arrow"
      disabled={isDisabled}
    >
      <span>
        {generateTranslateContent()}
        {sendCorrect ? <IconSendTrue size={20} /> : <IconArrow size={20} />}
      </span>
    </StyledButtonManager>
  );
};

export const StyledButtonManager = styled.button`
  flex-shrink: 0;
  &[disabled] {
    background-color: #aeaeae;
    border: #aeaeae;
  }
`;
