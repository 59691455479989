import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { BlueTitlesList } from "./BlueTitlesList";
import { EcologyHorizontalList } from "./EcologyHorizontalList";
import { CharityList } from "./CharityList";

import { BREAKPOINTS, COLORS } from "../../../settings";
import { translator } from "../../../helpers/lang";
import { useSelector } from "react-redux";

export const ActivityItem = ({ item }) => {
  const [isMobile, setMobile] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const dataBase = useSelector(state => state.base);
  const langCurrent = dataBase.lang.current;

  useEffect(() => {
    setMobile(window.matchMedia("(max-width: 768px)").matches);
  }, []);

  const contentText = item.pac_content.trim().split(`\n`);

  return (
    <>
      {item && (
        <StyledActivityItem>
          <div
            className="head_wrapper"
            style={
              isMobile
                ? {
                    backgroundImage: `linear-gradient(56deg, rgba(5, 2, 22, 0.738) 0%, rgba(7, 3, 30, 0) 68.47%),
                                    url(${
                                      item.pac_image_moby &&
                                      item.pac_image_moby.localFile.publicURL
                                    })`,
                  }
                : {
                    backgroundImage: `linear-gradient(56deg, rgba(5, 2, 22, 0.738) 0%, rgba(7, 3, 30, 0) 68.47%),
                                    url(${
                                      item.pac_image &&
                                      item.pac_image.localFile.publicURL
                                    })`,
                  }
            }
          >
            <div
              className="title"
              dangerouslySetInnerHTML={{
                __html: item.pac_title,
              }}
            />
            <div
              className="subtitle"
              dangerouslySetInnerHTML={{
                __html: item.pac_subtitle,
              }}
            />
          </div>

          <div className="content_wrapper">
            {contentText.length > 1 && !isOpen ? (
              <>
                <div
                  className="the_content"
                  dangerouslySetInnerHTML={{
                    __html: contentText[0],
                  }}
                />
                <button onClick={() => setOpen(!isOpen)}>
                  {translator(langCurrent, {
                    ru: "Развернуть",
                    en: "See more",
                    kz: "Ашып көрсету",
                  })}
                </button>
              </>
            ) : (
              <div
                className="the_content"
                dangerouslySetInnerHTML={{
                  __html: item.pac_content,
                }}
              />
            )}

            {item.is_blue_titles_list && (
              <BlueTitlesList data={item.blue_titles_list} />
            )}
            {item.is_ecology_horizontal_list && (
              <EcologyHorizontalList data={item.ecology_horizontal_list} />
            )}
            {item.is_charity_list && <CharityList data={item.charity_list} />}
          </div>
        </StyledActivityItem>
      )}
    </>
  );
};

const StyledActivityItem = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 80px;
  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
    margin-bottom: 120px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  .head_wrapper {
    width: 100%;
    height: auto;
    min-height: 380px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 155px 20px 20px;
    color: #ffffff;
    margin-bottom: 34px;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 7px;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      margin-bottom: 50px;
      padding: 135px 40px 40px;
      min-height: 350px;
      width: 100%;
    }
    .title {
      width: 100%;
      height: auto;
      font-weight: bold;
      font-size: 25px;
      line-height: 35px;
      margin-bottom: 10px;
      margin-top: auto;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        width: 50%;
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 15px;
      }
    }

    .subtitle {
      width: 100%;
      font-size: 16px;
      line-height: 25px;
      text-align: justify;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        width: 50%;
        font-size: 20px;
        line-height: 30px;
      }
    }
  }

  .content_wrapper {
    width: 100%;
    margin-left: auto;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      width: 75%;
    }
    .the_content {
      font-size: 15px;
      line-height: 25px;
      text-align: justify;
      color: #040404;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        font-size: 20px;
        line-height: 35px;
      }
    }

    button {
      padding: 0;
      border: none;
      outline: none;
      background-color: transparent;
      font-size: 15px;
      line-height: 25px;
      color: ${COLORS.text_accent};
      transition: all 0.3s ease 0s;
      @media (min-width: 768px) {
        font-size: 20px;
        line-height: 35px;

        &:hover {
          color: ${COLORS.text_hover};
        }
        &:active {
          color: ${COLORS.text_active};
        }
      }
    }

    .show_more_line {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      margin-top: 17px;
      span {
        font-size: 16px;
        line-height: 25px;
        color: #0083e5;
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          font-size: 20px;
          line-height: 35px;
        }
      }
      img {
        transform: rotate(0);
        transition: transform 0.4s ease-in-out;
        &.showed {
          transform: rotate(180deg);
        }
      }
    }
  }
`;
