import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const SliderHowBuyBondsBlock = ({ children }) => {
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    setMobile(
      window.matchMedia("(max-width: 1024px)").matches &&
        window.matchMedia("(min-width: 768px)").matches
    );
  }, []);

  const settings = {
    arrows: false,
    focusOnSelect: true,
    infinite: false,
    slidesToScroll: 2,
    slidesToShow: 2.3,
    centerMode: false,
  };

  return isMobile ? (
    <StyledSliderHowBuyBondsBlock className="step_list" {...settings}>
      {children}
    </StyledSliderHowBuyBondsBlock>
  ) : (
    <div className="step_list">{children}</div>
  );
};

const StyledSliderHowBuyBondsBlock = styled(Slider)`
  width: 100%;
  flex-grow: 1;
  .slick-list {
    .slick-track {
      width: 100%;
      display: flex;
      flex-direction: row;
      .slick-slide {
        height: auto;
        margin-right: 40px;
        display: flex;
        & > div {
          width: 100%;
        }
      }
    }
  }
`;
