import React from "react";
import styled from "styled-components";

import { BREAKPOINTS, SETTINGS } from "../../../settings";

export const EventItem = ({ event }) => {
  return (
    <StyledEventItem>
      <div
        className="type"
        dangerouslySetInnerHTML={{
          __html: event.type && event.type,
        }}
      />

      <div
        className="content"
        dangerouslySetInnerHTML={{
          __html: event.content && event.content,
        }}
      />

      {event.image && (
        <div
          className="image"
          style={{
            backgroundImage:
              event.image.source_url &&
              `url(${event.image.source_url.replace(SETTINGS.URL_MASTER, "")})`,
          }}
        />
      )}
    </StyledEventItem>
  );
};

const StyledEventItem = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 0;
  }

  .type {
    font-size: 18px;
    line-height: 32px;
    color: #898989;
    margin-bottom: 10px;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      font-size: 20px;
      line-height: 35px;
    }
  }

  .content {
    font-size: 22px;
    line-height: 32px;
    color: #040404;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      font-size: 25px;
      line-height: 35px;
    }
  }

  .image {
    width: 100%;
    height: 250px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 20px;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      height: 300px;
    }
    @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
      height: 400px;
    }
  }
`;
