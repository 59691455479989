import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import { TitleBlock } from "./TitleBlock";
import { TopButton } from "./TopButton";
import { MainImage } from "./MainImage";
import { BreadcrumbsCustom } from "./BreadcrumbsCustom";

import { BREAKPOINTS } from "../../settings";

import { usePageInfo } from "../../hooks/use-page-info";

export const CrumbsWithTitleBlock = ({ data, id }) => {
  const pageInfo = usePageInfo(id);

  return (
    <StyledCrumbsWithTitleBlock className="block-container custom_container">
      <BreadcrumbsCustom pageInfo={pageInfo} />
      <TitleBlock data={data} />
      {data.is_show_button && <TopButton data={data} pageId={id} />}
      {data.h1_image && (
        <MainImage source_url={data.h1_image.localFile.publicURL} />
      )}
    </StyledCrumbsWithTitleBlock>
  );
};

const StyledCrumbsWithTitleBlock = styled.div`
  &.custom_container {
    padding-top: 120px;
    padding-bottom: 40px;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      padding-top: 180px;
      padding-bottom: 30px;
    }
  }
`;

export const query = graphql`
  fragment CrumbsWithTitleBlock on WordPressAcf_crumbs_with_title_block {
    h1_title
    h1_subtitle
    is_show_button
    button_link
    button_title
    h1_image {
      localFile {
        publicURL
      }
    }
  }
`;
