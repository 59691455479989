// libraries
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/dist/css/splide-core.min.css';
// components
import { SliderControl } from '../UI/SliderControl';
// constants
import { BREAKPOINTS, COLORS } from '../../settings';
// hooks
import { useTeamList } from '../../hooks/use-team-list';
// helpers
import { getTitle } from '../../helpers/base';

export const EmployeeSayBlock = ({ data }) => {
    const { title } = data;
    const dataBase = useSelector((state) => state.base);
    const langCurrent = dataBase.lang.current;
    const teamList = useTeamList();
    const personList = teamList.filter((item) => item.is_add_to_vacancy_page === true);

    const [slideIndex, setSlideIndex] = useState(1);
    const [sliderLenght, setSliderLenght] = useState(0);

    const photoSlider = React.createRef();
    const textSlider = React.createRef();

    const sliderOptions = {
        arrows: true,
        pagination: false,
        type: 'fade',
        perPage: 1,
    };

    const sliderPhotoOptions = {
        arrows: true,
        pagination: false,
        type: 'fade',
        drag: false,
        padding: { right: '15%' },
        mediaQuery: 'min',
        breakpoints: {
            [BREAKPOINTS.desktop_lg]: {
                padding: { right: '40%' },
            },
        },
    };

    useEffect(() => {
        photoSlider.current.sync(textSlider.current.splide);
    }, [photoSlider, textSlider]);

    return (
        <StyledEmployeeSayBlock className="element-container">
            <StyledTitle
                dangerouslySetInnerHTML={{
                    __html: title,
                }}
            />

            <StyledSliderWrapper>
                <StyledPhotoSlider
                    ref={(slider) => (photoSlider.current = slider)}
                    hasTrack={false}
                    options={sliderPhotoOptions}
                >
                    <SplideTrack>
                        {personList.map((slide, index) => (
                            <SplideSlide
                                key={index}
                                style={{
                                    left: `calc(${index * 15}%)`,
                                    scale: `calc(1 - ${index * 0.1})`,
                                }}
                            >
                                <StyledImageWrapper
                                    style={{
                                        backgroundImage: `url(${slide.ot_image.localFile.publicURL})`,
                                    }}
                                />
                            </SplideSlide>
                        ))}
                    </SplideTrack>
                    <SliderControl
                        arrows={sliderPhotoOptions.arrows}
                        slideIndex={slideIndex}
                        sliderLenght={sliderLenght}
                    />
                </StyledPhotoSlider>
                <StyledEmployeeSlider
                    ref={(slider) => (textSlider.current = slider)}
                    hasTrack={false}
                    options={sliderOptions}
                    aria-label="team"
                    onReady={(splide) => {
                        setSliderLenght(splide.length);
                    }}
                    onMoved={(splide) => {
                        setSlideIndex(splide.index + 1);
                    }}
                >
                    <SplideTrack>
                        {personList.map((slide, index) => (
                            <SplideSlide key={index}>
                                <StyledPersonTitle
                                    dangerouslySetInnerHTML={{
                                        __html: getTitle(slide.ot_title, langCurrent),
                                    }}
                                />

                                <StyledDescription
                                    dangerouslySetInnerHTML={{
                                        __html: getTitle(slide.cp_description, langCurrent),
                                    }}
                                />

                                <StyledPersonInfo>
                                    <StyledIconWrapper
                                        style={{
                                            backgroundImage: `url(${slide.ot_image.localFile.publicURL})`,
                                        }}
                                    />

                                    <StyledName
                                        dangerouslySetInnerHTML={{
                                            __html: getTitle(slide.ot_name, langCurrent),
                                        }}
                                    />

                                    <StyledPosition
                                        dangerouslySetInnerHTML={{
                                            __html: getTitle(slide.ot_position, langCurrent),
                                        }}
                                    />
                                </StyledPersonInfo>
                            </SplideSlide>
                        ))}
                    </SplideTrack>
                    <SliderControl arrows={sliderOptions.arrows} slideIndex={slideIndex} sliderLenght={sliderLenght} />
                </StyledEmployeeSlider>
            </StyledSliderWrapper>
        </StyledEmployeeSayBlock>
    );
};

const StyledEmployeeSayBlock = styled.section`
    position: relative;
    z-index: 2;
`;

const StyledTitle = styled.h2`
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 28px;
    line-height: 1.3;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        margin-bottom: 64px;
        font-size: 46px;
        text-align: center;
    }

    span {
        color: ${COLORS.text_accent};
    }
`;

const StyledPhotoSlider = styled(Splide)`
    display: none;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        display: block;
        width: 42%;
    }

    .splide__track {
        margin: 0;
        height: 100%;
    }

    .splide__list {
        position: relative;
    }

    .splide__slide {
        opacity: 0.3 !important;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transform: translateX(0%) !important;
    }

    .splide__slide.is-active {
        height: 100%;
        opacity: 1 !important;
        scale: 1 !important;
        left: 0 !important;
    }

    .splide__arrows {
        margin: 0 auto;
    }
`;

const StyledEmployeeSlider = styled(Splide)`
    width: 100%;
    height: auto;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        width: 50%;
        margin-bottom: 0;
    }

    @media (min-width: ${BREAKPOINTS.desktop_lg + 'px'}) {
        width: 58%;
    }

    .splide__track {
        margin-bottom: 25px;

        @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
            margin-bottom: 0;
        }
    }

    .splide__slide {
        display: flex;
        flex-direction: column;
        height: 0;
    }

    .splide__slide.is-active {
        height: auto;
    }

    .splide__slide__row {
        flex-grow: 1;
        height: initial !important;
    }

    .splide__arrows {
        @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
            display: none;
        }
    }
`;

const StyledSliderWrapper = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        margin-bottom: 0;
    }
`;

const StyledImageWrapper = styled.div`
    position: relative;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        width: 100%;
        height: 380px;
        border-radius: 7px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
    }

    img {
        position: absolute;
        width: 100%;
        height: 100%;
    }
`;

const StyledPersonTitle = styled.h2`
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.25;
    color: #040404;
    opacity: 0.8;

    @media (min-width: ${BREAKPOINTS.desktop + 'px'}) {
        font-size: 25px;
        line-height: 1.4;
    }
`;

const StyledDescription = styled.div`
    font-size: 16px;
    line-height: 1.4;
    color: #101010;
    margin-bottom: 25px;

    @media (min-width: ${BREAKPOINTS.desktop + 'px'}) {
        opacity: 0.8;
        font-size: 20px;
        line-height: 1.75;
        text-align: justify;
    }
`;

const StyledPersonInfo = styled.div`
    height: auto;
    position: relative;
    padding-left: 90px;
    min-height: 80px;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        padding-left: 0;
        min-height: auto;
    }
`;

const StyledIconWrapper = styled.div`
    position: absolute;
    left: 0;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        display: none;
    }
`;

const StyledName = styled.div`
    font-weight: bold;
    font-size: 20px;
    line-height: 1.25;
    color: ${COLORS.text};
    margin-bottom: 5px;
    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        font-size: 25px;
        line-height: 1.4;
    }
`;

const StyledPosition = styled.div`
    font-size: 15px;
    line-height: 1.4;
    color: ${COLORS.text_secondary};

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        font-size: 20px;
        line-height: 1.5;
    }
`;

export const query = graphql`
    fragment EmployeeSayBlock on WordPressAcf_employee_say_block {
        title
    }
`;
