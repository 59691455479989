import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { BREAKPOINTS } from "../../../settings";

const clickHandler = pageId => {
  if ([20, 1027, 555].includes(pageId)) {
    if (window.ym) {
      window.ym(57564463, "reachGoal", "loan_button_calcinvest_click");
    }
    if (window.dataLayer) {
      window.dataLayer.push({ event: "loan_button_calcinvest_click" });
    }
  }

  if ([1033, 18, 552].includes(pageId)) {
    if (window.ym) {
      window.ym(57564463, "reachGoal", "bond_button_calcinvest_click");
    }
    if (window.dataLayer) {
      window.dataLayer.push({ event: "bond_button_calcinvest_click" });
    }
  }
};

export const TopButton = ({ data, pageId }) => {
  const { button_link, button_title } = data;

  return (
    <StyledTopButton
      to={button_link}
      onClick={() => clickHandler(pageId)}
      dangerouslySetInnerHTML={{
        __html: button_title,
      }}
    />
  );
};

const StyledTopButton = styled(Link)`
  width: fit-content;
  max-width: 350px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  border: 1px solid #0083e5;
  padding: 0 20px;
  margin: 20px 0 40px;
  &:hover {
    text-decoration: none;
  }
  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
    margin: 40px 0 65px;
  }
  @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
    margin: 40px 0 90px;
  }
`;
