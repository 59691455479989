import React, { useEffect } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { SliderHowBuyBondsBlock } from "./SliderHowBuyBondsBlock";
import { BREAKPOINTS } from "../../settings";
import { updateModalState } from "../../store/actions/base";
import { useDispatch } from "react-redux";
import { BondsButton } from "../ShortFormButtons/BondsButton";

export const HowBuyBondsBlock = ({ data }) => {
  const dispatch = useDispatch();
  let type = null;

  useEffect(() => {
    const pathname = window.location.pathname;
    type = /loans/.test(pathname) ? "loans" : "bonds";
  });

  const buttonClick = e => {
    e.target.hash === "#calc" && updateModalState(dispatch, { type: type });
  };

  return (
    <>
      {data && (
        <StyledHowBuyBondsBlock className="block-container">
          <h2
            dangerouslySetInnerHTML={{
              __html: data.main_title,
            }}
          />
          <SliderHowBuyBondsBlock>
            {data.steps_list &&
              data.steps_list.map((item, index) => (
                <div key={index} className="step_item">
                  <span
                    className="type"
                    dangerouslySetInnerHTML={{
                      __html: item.sl_pretitle,
                    }}
                  />

                  <span
                    className="title"
                    dangerouslySetInnerHTML={{
                      __html: item.sl_title,
                    }}
                    onClick={e => buttonClick(e)}
                  />
                </div>
              ))}
          </SliderHowBuyBondsBlock>
          <div className="bonds-block__note-wrap">
            <p className="bonds-block__note-title"
              dangerouslySetInnerHTML={{
                __html: data.note_title,
              }}
            />
            <p className="bonds-block__note-sublitle" 
              dangerouslySetInnerHTML={{
                __html: data.note_subtitle,
              }}
            />
            <div className="bonds-block__button content_button">
              <BondsButton productType="bonds" />
            </div>
          </div>
        </StyledHowBuyBondsBlock>
      )}
    </>
  );
};

export const StyledHowBuyBondsBlock = styled.div`
  h2 {
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    color: #0f1a20;
    margin-bottom: 30px;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      line-height: 35px;
    }
    @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
      font-size: 30px;
      line-height: 45px;
      margin-bottom: 45px;
    }
  }

  .step_list {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .step_item {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    padding: 30px;
    margin-right: 2%;
    margin-bottom: 20px;
    background-color: #f6f8fa;
    border-radius: 7px;
    color: #0f1a20;
    fill: #0f1a20;
    transition: all 0.3s ease-in-out;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      min-height: 290px;
      width: 32%;
    }
    @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
      height: auto;
      padding: 40px;
      margin-bottom: 0;
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    p {
      margin-bottom: 0;
      font-weight: bold;
      font-size: 20px;
      line-height: 25px;
      color: #0f1a20;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        font-size: 20px;
        line-height: 30px;
      }
      @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
        font-size: 25px;
        line-height: 35px;
      }
    }
    .type {
      font-size: 15px;
      line-height: 20px;
      display: block;
      width: 100%;
      height: auto;
      margin-bottom: 5px;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        font-size: 16px;
        line-height: 25px;
      }
    }
    .title {
      font-weight: bold;
      font-size: 20px;
      line-height: 25px;
      display: block;
      width: 100%;
      height: auto;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }

  .bonds-block__note-wrap {
    padding-top: 25px;

  }

  .bonds-block__note-title {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.25;
    color: #040404;

    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      margin-bottom: 10px;
    }
  }

  .bonds-block__note-subtitle {
    margin-bottom: 20px;
    font-size: 15px;
    line-height: 1.35;
    color: #040404;

    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      margin-bottom: 10px;
      font-size: 20px;
    }
  }
`;

export const query = graphql`
  fragment HowBuyBondsBlock on WordPressAcf_how_to_buy_bonds_block {
    main_title
    steps_list {
      sl_pretitle
      sl_title
    }
    note_title
    note_subtitle
  }
`;
