// libraries
import React from 'react';
import styled from 'styled-components';
// components
import { SliderArrow } from './SliderArrow';
import { SlidesCounter } from './SlidesCounter';
// static
import arrow_svg from '../../../images/slider_arrow.svg';
import { BREAKPOINTS } from '../../../settings';

export const SliderControl = ({ arrows, slideIndex, sliderLenght }) => {
    return (
        <StyledSliderControls className="splide__arrows">
            {arrows && (
                <SliderArrow classNames="splide__arrow splide__arrow--prev arrow left">
                    <img src={arrow_svg} alt="naw arrow" />
                </SliderArrow>
            )}

            <SlidesCounter slideIndex={slideIndex} sliderLenght={sliderLenght} />

            {arrows && (
                <SliderArrow classNames="splide__arrow splide__arrow--next arrow right">
                    <img src={arrow_svg} alt="naw arrow" />
                </SliderArrow>
            )}
        </StyledSliderControls>
    );
};

const StyledSliderControls = styled.div`
    display: flex;
    margin: auto;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        max-width: 130px;
    }

    @media (min-width: ${BREAKPOINTS.desktop_lg + 'px'}) {
        margin: 36px auto 0;
    }
`;
