import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { BREAKPOINTS } from "../../settings";

import { AdvantagesSimplePart } from "../BlocksParts/AdvantagesSimplePart";

export const AdvantagesSimpleBlock = ({ data }) => {
  const { advantages, title } = data;

  return (
    <StyledProjectAboutBlock className="block-container">
      {title && (
      <h3 dangerouslySetInnerHTML={{
            __html: title,
        }}
      />
      )}
      <AdvantagesSimplePart items={advantages} />
    </StyledProjectAboutBlock>
  );
};

const StyledProjectAboutBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px !important;
  overflow: auto;

  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
    flex-direction: row;
  }

  h3 {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.75;
    text-align: left;

    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      width: 25%;
      margin-bottom: 0;
      margin-right: 7px;
      font-size: 25px;
      line-height: 1.4;
    }
  }
`;

export const query = graphql`
  fragment AdvantagesSimpleBlock on WordPressAcf_advantages_simple_block {
    id
    title
    advantages {
      data
      description
    }
  }
`;
