import React from "react";
import { graphql } from "gatsby";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { translator } from "../../helpers/lang";

import { BREAKPOINTS } from "../../settings";
export const TechnologyListBlock = ({ data }) => {
  const dataBase = useSelector(state => state.base);
  const langCurrent = dataBase.lang.current;
  const tecnology_list = data.techology_list;

  return (
    <StyledTechnologyListBlock className="block-container">
      <div className="block_title">
        <h2
          dangerouslySetInnerHTML={{
            __html: translator(langCurrent, {
              ru: "Используемые технологии",
              en: "Used Technologies",
              kz: "Пайдаланылатын технологиялар",
            }),
          }}
        />
      </div>

      <div className="technology_list">
        {tecnology_list.map((item, index) => (
          <div key={index} className="item">
            <img
              src={item.image.localFile.publicURL}
              alt={item.image.alt_text}
            />
          </div>
        ))}
      </div>
    </StyledTechnologyListBlock>
  );
};

const StyledTechnologyListBlock = styled.div`
  .block_title {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #b6bec0;
    margin-bottom: 15px;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
    }
    h2 {
      font-weight: bold;
      font-size: 25px;
      line-height: 30px;
      color: #040404;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        font-size: 30px;
        line-height: 45px;
      }
    }
  }

  .technology_list {
    width: 100%;
    height: auto;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      width: 75%;
    }
    .item {
      width: 33.33%;
      height: 16vw;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        height: 180px;
      }
      &:after {
        content: "";
        display: block;
        width: 1px;
        height: 86%;
        position: absolute;
        right: -1px;
        top: 7%;
        background-color: #b6bec0;
      }
      &:before {
        content: "";
        display: block;
        width: 86%;
        height: 1px;
        position: absolute;
        bottom: -1px;
        left: 7%;
        background-color: #b6bec0;
      }
      &:nth-child(3n) {
        &:after {
          display: none;
        }
      }
      &:nth-last-child(-n + 3) {
        &:before {
          display: none;
        }
      }
      img {
        width: auto;
        height: auto;
        max-width: 60%;
        max-height: 40%;
      }
    }
  }
`;

export const query = graphql`
  fragment TechnologyListBlock on WordPressAcf_technology_list_block {
    techology_list {
      image {
        localFile {
          publicURL
        }
        alt_text
      }
    }
  }
`;
