import { useStaticQuery, graphql } from "gatsby";

export const useGraphValues = () => {
  const { wordpressAcfOptions } = useStaticQuery(
    graphql`
      query GraphValues {
        wordpressAcfOptions {
          options {
            graph_data {
              graph_id
              graph_min_value
              graph_max_value
              graph_values {
                graph_values_year
                graph_values_value
              }
            }
          }
        }
      }
    `
  );

  return wordpressAcfOptions.options;
};
