import React from "react";
import styled from "styled-components";
import { COLORS } from "../../../settings";
import { IconDownload } from "../../Icons/IconDownload";

export const ButtonDownload = ({ title, link }) => {
  return (
    <StyledLink href={link} target="_blank">
      <IconDownload />
      {title}
    </StyledLink>
  );
};

const StyledLink = styled.a`
  color: ${COLORS.text_accent};
  font-size: 16px;
  line-height: 25px;
  text-decoration: none;

  &:hover,
  &:focus {
    color: #0062ac;
    text-decoration: none;
  }

  &:active {
    color: #104f7e;
  }

  &:not(:any-link) {
    color: #96d2ff;
  }

  .icon {
    margin: 0 5px 0 0;
    vertical-align: text-bottom;
  }
`;
