import React from "react";
import styled from "styled-components";

import { COLORS, BREAKPOINTS } from "../../../settings";

export const ProductsExtendedItemSubtitle = ({ subtitle }) => {
  return <StyledSubtitle dangerouslySetInnerHTML={{ __html: subtitle }} />;
};

const StyledSubtitle = styled.p`
  background: ${COLORS.bg_light};
  padding: 10px 15px;
  margin: 0;
  border-radius: 9px;
  font-size: 15px;
  line-height: 20px;

  @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
    padding: 15px 20px;
    font-size: 16px;
    line-height: 25px;
  }
`;
