import React from 'react';

export const IconWatch = ({ size }) => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.69389 20C9.06225 20 8.46259 19.8566 7.93489 19.5698C6.68761 18.8906 6 17.5094 6 15.6906V10.3094C6 8.48302 6.68761 7.10944 7.93489 6.43019C9.18218 5.75095 10.7893 5.87925 12.4683 6.79246L17.4015 9.47925C19.0725 10.3925 20 11.6453 20 13.0038C20 14.3623 19.0805 15.6151 17.4015 16.5283L12.4683 19.2151C11.5009 19.7358 10.5574 20 9.69389 20ZM9.69389 7.12453C9.26214 7.12453 8.87836 7.2151 8.53455 7.40378C7.67105 7.8717 7.19931 8.90567 7.19931 10.3094V15.683C7.19931 17.0868 7.67105 18.1132 8.53455 18.5887C9.39806 19.0642 10.5814 18.9283 11.8686 18.2264L16.8018 15.5396C18.0891 14.8377 18.8007 13.9396 18.8007 12.9962C18.8007 12.0528 18.0891 11.1547 16.8018 10.4528L11.8686 7.76604C11.0851 7.3434 10.3495 7.12453 9.69389 7.12453Z"
                fill="#0083E5"
            />
        </svg>
    );
};
