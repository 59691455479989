import React from "react";
import { useSelector } from "react-redux";
import { Link } from "gatsby";
import styled from "styled-components";

import { BREAKPOINTS, TRANSLATIONS } from "../../../settings";
import { IconArrow } from "../../Icons/IconArrow";

export const ProductsExtendedItemLink = ({ link }) => {
  const dataBase = useSelector(state => state.base);
  const langCurrent = dataBase.lang.current;
  const { buttons } = TRANSLATIONS;

  return (
    <StyledLink
      to={link}
      className="button button__secondary button__with-arrow"
    >
      <span>
        {buttons.more[langCurrent]}
        <IconArrow size={20} />
      </span>
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  margin: 20px 0 0;

  @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
    margin: 30px 0 0;
  }
`;
