import { graphql, useStaticQuery } from 'gatsby';

export const useVacanciesList = (lang) => {
    const data = useStaticQuery(
        graphql`
            query Vacancy {
                ru: allWordpressWpPosition(filter: { polylang_current_lang: { eq: "ru_RU" } }) {
                    edges {
                        node {
                            acf {
                                content
                                department
                                expereance
                                salary
                                title
                                email
                                employment_mode
                                city
                            }
                        }
                    }
                }

                en: allWordpressWpPosition(filter: { polylang_current_lang: { eq: "en_US" } }) {
                    edges {
                        node {
                            acf {
                                content
                                department
                                expereance
                                salary
                                title
                                email
                                employment_mode
                                city
                            }
                        }
                    }
                }
            }
        `
    );

    return lang ? data[lang].edges : data.ru.edges;
};
