import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import { BREAKPOINTS } from "../../settings";

export const ProjectTechnologyBlock = ({ data }) => {
  return (
    <StyledProjectTechnologyBlock className="block-container">
      <ul>
        {data.technology_list &&
          data.technology_list.map((item, index) => (
            <li key={index}>
              <div
                className="title"
                dangerouslySetInnerHTML={{
                  __html: item.title,
                }}
              />

              <div
                className="desc"
                dangerouslySetInnerHTML={{
                  __html: item.description,
                }}
              />
            </li>
          ))}
      </ul>
    </StyledProjectTechnologyBlock>
  );
};

const StyledProjectTechnologyBlock = styled.div`
  padding-top: 0;
  padding-bottom: 10px;
  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
    padding-top: 10px;
  }
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 0 auto;
    padding: 0;
    @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
      width: calc(75% - 17.5px);
    }
    li {
      padding: 0;
      margin: 0;
      width: 100%;
      height: auto;
      list-style-type: none;
      margin-bottom: 35px;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        width: 45%;
        margin-bottom: 50px;
      }
      .title {
        font-weight: bold;
        font-size: 20px;
        line-height: 25px;
        color: #0f1a20;
        margin-bottom: 5px;
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          font-size: 25px;
          line-height: 35px;
          margin-bottom: 15px;
        }
      }
      .desc {
        font-size: 15px;
        line-height: 20px;
        color: #101010;
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          font-size: 16px;
          line-height: 25px;
          opacity: 0.8;
        }
      }
    }
  }
`;

export const query = graphql`
  fragment ProjectTechnologyBlock on WordPressAcf_project_technology_block {
    technology_list {
      title
      description
    }
  }
`;
