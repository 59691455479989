import React, { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { translator } from "../../../helpers/lang";
import { BondsGraph } from "./BondsGraph";
import { BREAKPOINTS } from "../../../settings";
import cross_svg from "../../../images/cross.svg";
import nav_svg from "../../../images/subnav_open_dark.svg";
import { ButtonManager } from "../../ButtonManager";
import { InvestorsButton } from "../../ShortFormButtons/InvestorsButton";

export const BondsCirculationItem = ({ list, pageId }) => {
  const dataBase = useSelector(state => state.base);
  const langCurrent = dataBase.lang.current;
  const isOldUser = dataBase.managerData;

  const [isOpened, setOpened] = useState(false);

  return (
    <>
      {list && (
        <StyledBondsCirculationItem>
          <div
            className={`title_block ${isOpened ? "opened" : ""}`}
            onClick={() => setOpened(!isOpened)}
          >
            <div className="left_wrapper">
              <h3
                dangerouslySetInnerHTML={{
                  __html: list.bc_title,
                }}
              />
              <span
                className="subtitle"
                dangerouslySetInnerHTML={{
                  __html: list.bc_subtitle,
                }}
              />
            </div>

            {list.main_line && (
              <div className="main_line_list">
                {list.main_line[0].left_side && (
                  <div className="main_line_list_item">
                    <div
                      className="main_line_list_item_title"
                      dangerouslySetInnerHTML={{
                        __html: list.main_line[0].left_side.ls_title,
                      }}
                    />
                    <div
                      className="main_line_list_item_subtitle"
                      dangerouslySetInnerHTML={{
                        __html: list.main_line[0].left_side.ls_subtitle,
                      }}
                    />
                  </div>
                )}

                {list.main_line[0].right_side && (
                  <div className="main_line_list_item">
                    <div
                      className="main_line_list_item_title"
                      dangerouslySetInnerHTML={{
                        __html: list.main_line[0].right_side.ls_title,
                      }}
                    />
                    <div
                      className="main_line_list_item_subtitle"
                      dangerouslySetInnerHTML={{
                        __html: list.main_line[0].right_side.ls_subtitle,
                      }}
                    />
                  </div>
                )}
              </div>
            )}

            <img
              className={`toggle_tip_img ${isOpened ? "opened" : ""}`}
              src={cross_svg}
              alt="cross"
            />

            <button
              className={`more_mobile ${isOpened ? "opened" : ""}`}
              onClick={() => setOpened(!isOpened)}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: translator(langCurrent, {
                    ru: "Подробнее",
                    en: "More",
                    kz: "Толығырақ",
                  }),
                }}
              />

              <img src={nav_svg} alt="Nav" />
            </button>
          </div>

          <div className={`content_block ${isOpened ? "" : "d_none"}`}>
            <div className="content_button">
              {isOldUser ? (
                <ButtonManager type="bonds" dataType="short" pageId={pageId} />
              ) : (
                <InvestorsButton productType="bonds" />
              )}
            </div>

            {list.is_graph_add && (
              <BondsGraph symbol={list.g_symbal} graph_list={list.graph_list} />
            )}

            {list.bottom_line && (
              <div className="bottom_line_list">
                {list.bottom_line.map((item, index) => (
                  <div key={index} className="bottom_line_item">
                    <div
                      className="bottom_line_item_title"
                      dangerouslySetInnerHTML={{
                        __html: item.bl_title,
                      }}
                    />

                    <div
                      className="bottom_line_item_subtitle"
                      dangerouslySetInnerHTML={{
                        __html: item.bl_subtitle,
                      }}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </StyledBondsCirculationItem>
      )}
    </>
  );
};

const StyledBondsCirculationItem = styled.div`
  width: 100%;
  height: auto;
  border-bottom: 1px solid #b6bec0;
  .title_block {
    width: 100%;
    height: auto;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    padding: 30px 0;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      padding: 20px 45px 20px 0;
    }
    @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
      padding: 45px 45px 45px 0;
    }

    .left_wrapper {
      width: 100%;
      height: auto;
      padding-right: 20px;
      margin-bottom: 20px;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        width: 50%;
        margin-bottom: 0;
      }
      h3 {
        width: 100%;
        height: auto;
        font-weight: bold;
        font-size: 20px;
        line-height: 25px;
        color: #0f1a20;
        margin: 0;
        padding: 0;
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          font-size: 20px;
          line-height: 30px;
        }
        @media (min-width: ${BREAKPOINTS.desktop_lg + "px"}) {
          font-size: 25px;
          line-height: 35px;
        }
      }

      span {
        font-size: 15px;
        line-height: 20px;
        color: #898989;
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          font-size: 16px;
          line-height: 25px;
        }
        @media (min-width: ${BREAKPOINTS.desktop_lg + "px"}) {
          font-size: 20px;
          line-height: 30px;
        }
      }
    }

    .main_line_list {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      margin-left: auto;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        width: calc(50% - 23px);
      }
      .main_line_list_item {
        width: auto;
        height: auto;
        min-width: 48%;
        margin-right: 2%;
        &:nth-child(2n) {
          margin-right: 0;
        }
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          font-size: 16px;
          line-height: 25px;
        }
        @media (min-width: ${BREAKPOINTS.desctop + "px"}) {
          min-width: 30%;
          margin-right: 3.3%;
          &:nth-child(3n) {
            margin-right: 0;
          }
        }

        .main_line_list_item_title {
          font-weight: bold;
          font-size: 20px;
          line-height: 25px;
          color: #0083e5;
          margin-bottom: 15px;
          @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 5px;
          }
          @media (min-width: ${BREAKPOINTS.desktop_lg + "px"}) {
            font-size: 30px;
            line-height: 30px;
          }
        }

        .main_line_list_item_subtitle {
          font-size: 15px;
          line-height: 20px;
          color: #898989;
          @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
            font-size: 16px;
            line-height: 25px;
          }
        }
      }
    }

    .toggle_tip_img {
      width: 20px;
      height: 20px;
      position: absolute;
      display: none;
      right: 10px;
      top: calc(50% - 10px);
      transform: rotate(-45deg);
      transition: transform 0.5s ease-in-out;
      &.opened {
        transform: rotate(178deg);
      }
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        display: block;
      }
    }

    .more_mobile {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background-color: transparent;
      border: none;
      padding: 0;
      outline: none;
      margin-top: 20px;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        display: none;
      }
      &.opened {
        margin-bottom: 30px;
        img {
          transform: rotate(180deg);
        }
      }
      span {
        font-size: 16px;
        line-height: 25px;
        color: #0f1a20;
      }

      img {
        width: 18px;
        height: 18px;
        transform: rotate(0deg);
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .content_block {
    width: 100%;
    margin-left: auto;
    display: block;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      width: 50%;
    }
    &.d_none {
      display: none;
    }

    .content_button {
      position: relative;
      left: 0;
      margin-bottom: 60px;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        left: -100%;
      }
    }

    .bottom_line_list {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .bottom_line_item {
        width: 48%;
        height: auto;
        margin-right: 4%;
        margin-bottom: 20px;

        &:nth-child(2n) {
          margin-right: 0;
          @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
            margin-right: 3.3%;
          }
        }

        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          width: 30%;
          margin-right: 3.3%;
          &:nth-child(3n) {
            margin-right: 0;
          }
        }

        .bottom_line_item_title {
          font-size: 15px;
          line-height: 20px;
          color: #0f1a20;
          @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
            font-size: 16px;
            line-height: 25px;
          }
          @media (min-width: ${BREAKPOINTS.desktop_lg + "px"}) {
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 5px;
          }
        }

        .bottom_line_item_subtitle {
          font-size: 15px;
          line-height: 20px;
          color: #898989;
          @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
            font-size: 16px;
            line-height: 25px;
          }
        }
      }
    }
  }
`;
