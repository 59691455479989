import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";

import { BREAKPOINTS, COLORS, LAYOUT } from "../../settings";
import { IconArrow } from "../Icons/IconArrow";

export const InfoBlock = ({ data }) => {
  const { infopanels } = data;

  return (
    <StyledInfoBlock className="infopanels">
      <div className="block-container">
        {infopanels && (
          <ul className="infopanels_list">
            {infopanels.map((item, i) => (
              <StyledItem
                key={i}
                className="infopanels_item"
                size={item.panel_size}
                textColor={item.panel_text}
              >
                {item.background && item.background.localFile && (
                  <StyledLink
                    to={item.link}
                    background={item.background.localFile.publicURL}
                  >
                    <h3 className="infopanels_title">{item.title}</h3>
                    <div
                      className="infopanels_description"
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                    <span className="infopanels_arrow" aria-hidden="true">
                      <IconArrow />
                    </span>
                  </StyledLink>
                )}
              </StyledItem>
            ))}
          </ul>
        )}
      </div>
    </StyledInfoBlock>
  );
};

const StyledInfoBlock = styled.div`
  .infopanels_list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: ${"-" + LAYOUT.gap_desktop / 2 + "px"};
    list-style: none;
  }
`;

const StyledItem = styled.li`
  width: 100%;
  padding: ${LAYOUT.gap / 2 + "px"};
  color: ${props =>
    props.textColor === "light" ? COLORS.text_inverted : COLORS.text};

  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
    width: 50%;
    padding: ${LAYOUT.gap_tablet / 2 + "px"};
  }

  @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
    width: ${props => (props.size === "large" ? "60%" : "40%")};
    padding: ${LAYOUT.gap_desktop / 2 + "px"};
  }
`;

const StyledLink = styled(Link)`
  position: relative;
  overflow: hidden;
  display: flex;
  height: 100%;
  padding: 40px;
  border-radius: 7px;
  color: currentColor;

  flex-direction: column;

  &:hover,
  &:focus {
    color: currentColor;
    text-decoration: none;

    &::before {
      transform: scale(1.05);
    }
  }

  &::before {
    content: "";
    z-index: 1;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: url(${props => props.background}) center/cover no-repeat;

    transition: transform 0.3s ease-out;
  }

  .infopanels_title {
    z-index: 3;

    position: relative;
    margin: 0 0 10px;
    font-size: 25px;
    line-height: 35px;
    font-weight: 700;
  }

  .infopanels_description,
  .infopanels_arrow {
    z-index: 3;

    position: relative;
  }

  .infopanels_description {
    margin: 0 0 auto 0;
  }

  .infopanels_arrow {
    margin: 10px 0 0;

    .icon__arrow {
      path {
        stroke: currentColor;
      }
    }
  }
`;

export const query = graphql`
  fragment InfoBlock on WordPressAcf_info_block {
    id
    infopanels {
      title
      description
      background {
        localFile {
          publicURL
        }
      }
      panel_size
      panel_text
      link
    }
  }
`;
