// libraries
import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/dist/css/splide-core.min.css';
// constants
import { BREAKPOINTS, COLORS } from '../../settings';

export const OfficeBlock = ({ data }) => {
    const { title, imagesGroup, description } = data;

    const sliderOptions = {
        drag: 'free',
        autoWidth: true,
        arrows: false,
        pagination: false,
    };

    return (
        <StyledOfficeBlock>
            <StyledTitle dangerouslySetInnerHTML={{ __html: title }} className="element-container" />
            <Splide hasTrack={false} options={sliderOptions}>
                <StyledImagesBlock>
                    {imagesGroup.map(({ image }, index) => (
                        <SplideSlide key={index}>
                            <img src={image.localFile.publicURL} alt="" aria-hidden />
                        </SplideSlide>
                    ))}
                </StyledImagesBlock>
            </Splide>
            <div className="element-container">
                <StyledDescription dangerouslySetInnerHTML={{ __html: description }}  />
            </div>
        </StyledOfficeBlock>
    );
};

const StyledOfficeBlock = styled.section`
    position: relative;
    z-index: 2;
    padding: 60px 0 80px;

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        padding: 95px 0 75px;
    }
`;

const StyledTitle = styled.h2`
    font-weight: 700;
    font-size: 28px;
    text-align: left;
    line-height: 1.3;
    
    &.element-container {
        margin: 0 0 30px;
        max-width: 325px;
    }

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        font-size: 46px;
        text-align: center;
        
        &.element-container {
            margin: 0 auto 64px;
            max-width: 980px;
        }
    }

    span {
        color: ${COLORS.text_accent};
    }
`;

const StyledImagesBlock = styled(SplideTrack)`
    height: 100%;
    margin-bottom: 64px;

    .splide__list {
        position: relative;
        min-height: 515px;

        .splide__slide {
            position: absolute;
            height: auto;
            width: auto;

            img {
                border-radius: 7px;
            }

            &:nth-child(1) {
                top: 0;
                left: 58px;
            }

            &:nth-child(2) {
                top: 302px;
                left: 229px;
            }

            &:nth-child(3) {
                top: 130px;
                left: 674px;
            }

            &:nth-child(4) {
                top: 47px;
                left: 1205px;
            }

            &:nth-child(5) {
                top: 345px;
                left: 1367px;
            }

            &:nth-child(6) {
                top: 20px;
                left: 1688px;
            }

            &:nth-child(7) {
                top: 322px;
                left: 1911px;
            }
        }
    }
`;

const StyledDescription = styled.p`
    display: block;
    max-width: 700px;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.6;
    color: ${COLORS.text};

    @media (min-width: ${BREAKPOINTS.tablet + 'px'}) {
        padding: 0;
        margin-left: auto;
        font-size: 20px;
        line-height: 1.5;
        text-align: justify;
    }
`;

export const query = graphql`
    fragment OfficeBlock on WordPressAcf_office_block {
        title
        description
        imagesGroup {
            image {
                localFile {
                    publicURL
                }
            }
        }
    }
`;
