import { useStaticQuery, graphql } from "gatsby";

export const useBonds = () => {
  const { wordpressAcfOptions } = useStaticQuery(
    graphql`
      query Bonds {
        wordpressAcfOptions {
          options {
            regions {
              region_name
              bonds {
                bond_name {
                  bond_name_ru {
                    url
                    title
                  }
                  bond_name_en {
                    url
                    title
                  }
                  bond_name_kz {
                    url
                    title
                  }
                }
                bond_isin
                bond_rate
              }
            }
          }
        }
      }
    `
  );

  return wordpressAcfOptions.options;
};
