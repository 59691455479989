import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { BREAKPOINTS, TRANSLATIONS } from "../../../settings";
import {
  updateBondsCalcProduct,
  updateBondsCalcCurrentActiveBond,
} from "../../../store/actions/calc_bonds";

export const BondsCalculatorPeriodSwitcher = ({ data }) => {
  const dispatch = useDispatch();
  const dataBase = useSelector(state => state.base);
  const [active, setActive] = useState(data.currentProduct);

  const langCurrent = dataBase.lang.current;
  const { countries } = TRANSLATIONS;

  const clickHandler = product => {
    if (window.ym) {
      window.ym(57564463, "reachGoal", "bond_calc_interact");
    }
    if (window.dataLayer) {
      window.dataLayer.push({ event: "bond_calc_interact" });
    }

    updateBondsCalcProduct(dispatch, product);
    setActive(product);
  };

  return (
    <StyledBondsCalculatorPeriodSwitcher>
      {Object.keys(data.products).map(function (key) {
        return (
          <StyledBondsCalculatorPeriodSwitcherButton
            key={`currency_${key}`}
            className={key === active ? "active" : ""}
            onClick={() => clickHandler(key)}
          >
            {countries[key][langCurrent]}
          </StyledBondsCalculatorPeriodSwitcherButton>
        );
      })}
    </StyledBondsCalculatorPeriodSwitcher>
  );
};

const StyledBondsCalculatorPeriodSwitcher = styled.div`
  overflow: hidden;
  display: flex;
  background: #efeff0;
  padding: 2px;
  border-radius: 7px;
  width: min-content;
  margin: 15px 0;
  @media (min-width: ${BREAKPOINTS.desktop}px) {
    margin: 0;
  }
`;

const StyledBondsCalculatorPeriodSwitcherButton = styled.button`
  display: block;
  background: hsla(0, 0%, 100%, 0);
  padding: 4px 16px;
  border: 0.5px solid hsla(0, 0%, 0%, 0);
  border-radius: 6px;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  @media (min-width: ${BREAKPOINTS.desktop}px) {
    font-size: 16px;
    line-height: 25px;
  }
  &.active {
    background: hsl(0, 0%, 100%);
    border: 0.5px solid hsla(0, 0%, 0%, 0.04);
    box-shadow: 0 3px 8px hsla(0, 0%, 0%, 0.12), 0 3px 1px hsla(0, 0%, 0%, 0.04);
  }
`;
