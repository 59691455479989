import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { langGetAwardsItems, translator } from "../../helpers/lang";

import { BREAKPOINTS } from "../../settings";

export const OurAwardsBlock = ({ data }) => {
  const dataBase = useSelector(state => state.base);
  const langCurrent = dataBase.lang.current;

  return (
    <StyledOurAwardsBlock className="block-container">
      <div className="tb_row bold">
        <h2
          className="cl title"
          dangerouslySetInnerHTML={{
            __html: translator(langCurrent, {
              ru: "Награды",
              en: "Awards",
              kz: "Марапат",
            }),
          }}
        />

        <div
          className="cl country"
          dangerouslySetInnerHTML={{
            __html: translator(langCurrent, {
              ru: "Страна",
              en: "Country",
              kz: "Мемлекет",
            }),
          }}
        />

        <div
          className="cl year"
          dangerouslySetInnerHTML={{
            __html: translator(langCurrent, {
              ru: "Год",
              en: "Year",
              kz: "Жылы",
            }),
          }}
        />
      </div>

      {data.awards_list &&
        data.awards_list.map((award, index) => (
          <div key={index} className="tb_row">
            <div
              className="cl title"
              dangerouslySetInnerHTML={{
                __html: award.title && award.title,
              }}
            />

            <div
              className="cl country"
              dangerouslySetInnerHTML={{
                __html: award.country && award.country,
              }}
            />

            <div
              className="cl year"
              dangerouslySetInnerHTML={{
                __html: award.year && award.year,
              }}
            />
          </div>
        ))}
    </StyledOurAwardsBlock>
  );
};

const StyledOurAwardsBlock = styled.div`
  .tb_row {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #b6bec0;
    padding: 20px 0;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      min-height: 96px;
      display: flex;
      flex-direction: row;
      padding: 30px 0;
    }
    &.bold {
      padding: 15px 0;
      min-height: auto;
      .cl.title {
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        color: #0f1a20;
        margin: 0;
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          font-size: 30px;
          line-height: 40px;
        }
      }
      .cl.country,
      .cl.year {
        color: #0f1a20;
        display: none;
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          display: flex;
          align-items: center;
        }
      }
    }
    .cl {
      height: auto;
      display: flex;
      align-items: center;
      &.title {
        width: 100%;
        font-size: 18px;
        line-height: 25px;
        color: #040404;
        margin-bottom: 5px;
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          width: 74%;
          font-size: 20px;
          line-height: 30px;
          padding-right: 20px;
          margin-bottom: 0;
        }
        @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
          width: 84%;
          font-size: 25px;
          line-height: 35px;
        }
      }
      &.country {
        width: fit-content;
        font-size: 16px;
        line-height: 25px;
        color: #898989;
        display: inline-block;
        margin-right: 15px;
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          width: 16%;
        }
        @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
          width: 10%;
        }
      }
      &.year {
        width: fit-content;
        font-size: 16px;
        line-height: 25px;
        color: #898989;
        display: inline-block;
        @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
          width: 10%;
        }
        @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
          width: 6%;
        }
      }
    }
  }
`;

export const query = graphql`
  fragment OurAwardsBlock on WordPressAcf_our_awards {
    awards_list {
      country
      title
      year
    }
  }
`;
