import React from "react";
import styled from "styled-components";
import { usePageInfo } from "../../hooks/use-page-info";

import { BREAKPOINTS } from "../../settings";

export const ContentBlock = ({ id }) => {
  const content = usePageInfo(id);

  return (
    <>
      {content && (
        <StyledContentBlock className="block-container custom_container">
          <div className="title_block">
            <h1
              dangerouslySetInnerHTML={{
                __html: content.title,
              }}
            />
          </div>

          <div
            className="the_content"
            dangerouslySetInnerHTML={{
              __html: content.content,
            }}
          />
        </StyledContentBlock>
      )}
    </>
  );
};

const StyledContentBlock = styled.div`
  &.custom_container {
    padding-top: 80px;
    padding-bottom: 0;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      padding-top: 180px;
      padding-bottom: 30px;
    }
  }

  .title_block {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      margin-bottom: 50px;
    }
    h1 {
      color: #0f1a20;
      margin-bottom: 10px;
      font-size: 26px;
      line-height: 34px;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        font-size: 36px;
        line-height: 44px;
      }
    }
  }

  .the_content {
    h2 {
      color: #0088f6;
      font-size: 20px;
      line-height: 29px;
      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        font-size: 32px;
        line-height: 41px;
      }
    }
  }
`;
