import React from "react";
import { useSelector } from "react-redux";

import { BondsCalculatorSlider } from "./BondsCalculatorSlider";
import { translator } from "../../../helpers/lang";

export const BondsCalculatorSliders = () => {
  const dataBase = useSelector(state => state.base);
  const dataCalcBonds = useSelector(state => state.calcBonds);

  const langCurrent = dataBase.lang.current;

  return (
    <BondsCalculatorSlider
      name="amount"
      label={translator(langCurrent, {
        ru: "Сумма инвестиций",
        en: "Investment amount",
        kz: "Инвестициялар сомасы",
      })}
      value={dataCalcBonds.currentVal}
      min={dataCalcBonds.min}
      max={dataCalcBonds.max}
      step={dataCalcBonds.step}
    />
  );
};
