// libraries
import React from 'react';
import styled from 'styled-components';

export const SliderArrow = ({ classNames, children }) => {
    return <StyldeSliderArrow className={classNames}>{children}</StyldeSliderArrow>;
};

const StyldeSliderArrow = styled.button`
    &.splide__arrow {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        background: transparent;
        align-items: center;
        cursor: pointer;

        &--next,
        &--prev {
            border: none;
            outline: none;
        }

        &--prev {
            margin-right: auto;
            transform: rotate(180deg);
        }

        &--next {
            margin-left: auto;
        }

        &:hover {
            opacity: 0.6;
        }

        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }
`;
