import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { BREAKPOINTS } from "../../../settings";

import link_arrow from "../../../images/link_arrow.svg";

export const ProjectsItem = ({ project }) => {
  return (
    <StyledProjectsItem className="project_item">
      <div
        className="title"
        dangerouslySetInnerHTML={{
          __html: project.acf.project_name,
        }}
      />

      {project.acf.short_intro && (
        <div
          className="subtitle"
          dangerouslySetInnerHTML={{
            __html: project.acf.short_intro,
          }}
        />
      )}

      <Link
        to={project.acf.project_websites[0].project_website.url}
        target="_blank"
        dangerouslySetInnerHTML={{
          __html: `<span>${project.acf.project_websites[0].project_website.title}</span><img src="${link_arrow}" alt="Arrow"/>`,
        }}
      />
    </StyledProjectsItem>
  );
};

const StyledProjectsItem = styled.div`
  width: 100%;
  height: auto;
  padding: 20px 20px 60px;
  background-color: #f6f8fa;
  position: relative;
  margin-bottom: 20px;

  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
    width: calc(50% - 20px);
    margin: 0 10px;
  }

  @media (min-width: ${BREAKPOINTS.desktop_lg + "px"}) {
    padding: 30px 30px 70px;
    margin: 0 10px;
  }

  .title {
    font-weight: bold;
    font-size: 25px;
    line-height: 35px;
    color: #101010;
    margin-bottom: 5px;
  }

  .subtitle {
    font-size: 16px;
    line-height: 25px;
  }

  a {
    position: absolute;
    left: 20px;
    bottom: 20px;
    @media (min-width: ${BREAKPOINTS.desktop_lg + "px"}) {
      left: 30px;
      bottom: 30px;
    }
    span {
      display: inline-block;
      margin-right: 6px;
    }
  }
`;
